import { Stack, Typography, useTheme, ClickAwayListener } from "@mui/material";
import { useEditSummariesMutation } from "api/api-compliance";
import VariableInput from "components/Common/VariableInput";
import { Formik } from "formik";
import { MapContext } from "pages/maps/map";
import { useContext, useState } from "react";
import { toast } from "react-toastify"; // Import toast for notifications

const Summary = ({
  title,
  subject,
  data,
  readonly = true,
}: {
  title: string;
  subject: string;
  data: any;
  readonly?: boolean;
}) => {
  const mapName = useContext(MapContext);
  const [editSummaries] = useEditSummariesMutation();
  const [currentValue, setCurrentValue] = useState(data); // Store the current value of the input

  const handleSave = async (value: string) => {
    if (mapName && currentValue !== value) {
      try {
        await editSummaries({
          map_name: mapName,
          data: { summary: value, subject },
        }).unwrap();
        setCurrentValue(value); // Update the local state after a successful API call
        toast.success("Summary Updated");
      } catch (err) {
        console.error("Error in editSummaries:", err);
        toast.error(`Error: ${err?.message}`);
      }
    }
  };

  return (
    <Formik
      initialValues={{ summary: data }}
      onSubmit={(values) => {
        handleSave(values.summary);
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        const theme = useTheme();
        const inputModeStyles = {
          p: 2,
          bgcolor: theme.palette.primary.main,
          borderRadius: 1,
          color: theme.palette.background.default,
        };

        return (
          <Stack spacing={1}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "1em",
                ...(!readonly ? inputModeStyles : {}),
              }}
            >
              {title}
            </Typography>
            <ClickAwayListener
              onClickAway={() => {
                if (values.summary !== currentValue) {
                  handleSubmit(); // Ensure API is called when clicking outside
                }
              }}
            >
              <div>
                <VariableInput
                  sx={{
                    ".MuiInputBase-root": {
                      height: "15vh",
                      backgroundClip: "border-box",
                      borderRadius: "10px",
                      textarea: {
                        height: "13vh !important",
                        overflow: "scroll !important",
                      },
                    },
                  }}
                  disabled={readonly}
                  noHover={readonly}
                  name="summary"
                  placeholder={readonly ? "Not described" : "Enter summary..."}
                  label=""
                  value={values.summary}
                  multiline
                  style={{ width: "100%" }}
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("summary", e.target.value);
                  }}
                />
              </div>
            </ClickAwayListener>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default Summary;

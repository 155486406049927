import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import {
  useGetAccountRelationshipQuery,
  useGetBuyerCreditLimitQuery,
} from "api/api-accounts";
import { useCreateLeadPartnerCreditLimitMutation } from "api/api-origination";
import { BUYER, CL_DEFAULT } from "codes";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";

import { Formik, useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import valueCleaner from "helpers/valueCleaner";
import { useEffect } from "react";
import { toast } from "react-toastify";
import type { Account, RegisterLeadPartnerCreditLimit } from "types";
import AvailableCreditLimit from "./AvailableCreditLimit";
import { LeadSections } from "./LeadSectionTabs";

const LeadPartnerCreditLimit = ({
  seller,
  buyer,
  onEnd,
  navigateTab,
}: {
  seller: Account;
  buyer: Account;
  onEnd: () => void;
  navigateTab: (index: number) => void;
}) => {
  const formikProps = useFormikContext<LeadSections>();
  const { setFieldValue } = formikProps;
  const { profile } = buyer;
  const {
    data: availableCreditLimit,
    isFetching: isFetchingCreditLimit,
    status,
    refetch: refetchCL,
  } = useGetBuyerCreditLimitQuery(buyer?.id ?? "", {
    skip: !seller || profile !== BUYER.toLowerCase(),
  });

  const CURRENCY = "USD";
  const [
    createLeadPartnerCreditLimit,
    { isLoading: isLoadingCreateLeadPartnerCreditLimit },
  ] = useCreateLeadPartnerCreditLimitMutation();

  const { data: relationship, refetch } = useGetAccountRelationshipQuery(
    {
      companyId: seller?.id,
      partnerId: buyer?.id ?? "",
    },
    { skip: !seller || !buyer?.id }
  );
  const IS_BUYER = profile === BUYER.toLowerCase();
  const disabled =
    !relationship?.average_invoice_amount ||
    !relationship?.average_payment_terms ||
    relationship?.number_of_past_paid_transaction < 0;

  const createLeadCLActions = (requested_amount: string) => {
    const createLeadCLRequestBody: RegisterLeadPartnerCreditLimit = {
      requested_amount: valueCleaner(requested_amount),
      lead_id: buyer?.id ?? "",
    };
    createLeadPartnerCreditLimit(createLeadCLRequestBody)
      .unwrap()
      .then((res) => {
        toast(`Credit Limit Submitted for  ${buyer?.name}`, {
          type: "success",
        });
        // refetch();
        refetchCL();
        if (onEnd) onEnd();
      })
      .catch((e) => {
        toast(e?.message ?? "An error occured", {
          type: "error",
        });
      });
  };
  useEffect(() => {
    setFieldValue(
      "average_invoice_amount",
      relationship?.average_invoice_amount
    );
    setFieldValue("average_payment_terms", relationship?.average_payment_terms);
    setFieldValue(
      "number_of_past_paid_transaction",
      relationship?.number_of_past_paid_transaction
    );
    setFieldValue("any_credit_note", relationship?.any_credit_note);
    setFieldValue(
      "reason_for_credit_note",
      relationship?.reason_for_credit_note
    );
    setFieldValue(
      "any_delays_of_payment_with_buyer",
      relationship?.any_delays_of_payment_with_buyer
    );
    setFieldValue(
      "reason_of_any_delays_of_payment_with_buyer",
      relationship?.reason_of_any_delays_of_payment_with_buyer
    );
  }, [relationship, IS_BUYER]);

  return (
    <Grid
      container
      sx={{ pt: 3, pb: 3 }}
      justifyContent="center"
    >
      {isFetchingCreditLimit && IS_BUYER && <SkeletonLoad bars={3} />}
      {!isFetchingCreditLimit &&
        !availableCreditLimit &&
        status === "rejected" && (
          <Formik
            initialValues={{
              requested_amount: currencyFormatter({ amount: CL_DEFAULT }),
            }}
            onSubmit={console.log}
          >
            {(formikProps) => (
              <Grid item xs={12}>
                <Alert severity={disabled ? "error" : "info"}>
                  <Stack spacing={1}>
                    <AlertTitle sx={{ fontSize: "1.5em", fontWeight: "bold" }}>
                      Request a Credit Limit{" "}
                      {disabled
                        ? "(Please complete the Details to proceed)"
                        : ""}
                    </AlertTitle>
                    <Divider />
                    <Typography fontSize="1.5em">
                      Please ensure that the
                      <Button onClick={() => navigateTab(1)}>
                        Trade Details
                      </Button>{" "}
                      &
                      <Button onClick={() => navigateTab(0)}>
                        Additional Details
                      </Button>{" "}
                      regarding <strong>{buyer?.name}</strong> are completed to
                      request a Credit Limit as per the Business Requirements.
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="end">
                      <Grid item xs={6}>
                        <Input
                          name="requested_amount"
                          label="Credit Limit"
                          type="money"
                          textfieldProps={{
                            InputProps: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  {CURRENCY}
                                </InputAdornment>
                              ),
                            },
                          }}
                          style={{ width: "100%" }}
                          disabled={
                            disabled || !formikProps.values.requested_amount
                          }
                          {...formikProps}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <LoadingButton
                          fullWidth
                          onClick={() =>
                            createLeadCLActions(
                              formikProps.values.requested_amount
                            )
                          }
                          variant="contained"
                          type="button"
                          loading={isLoadingCreateLeadPartnerCreditLimit}
                          disabled={
                            disabled || !formikProps.values.requested_amount
                          }
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Stack>
                  </Stack>
                </Alert>
              </Grid>
            )}
          </Formik>
        )}
      <AvailableCreditLimit buyer={buyer} />
    </Grid>
  );
};
export default LeadPartnerCreditLimit;

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { ResultsStrip } from "./sofrReducer.d";

const initialState: { value: ResultsStrip[] } = {
  value: [],
};

export const sofrSlice = createSlice({
  name: "sofr",

  initialState,

  reducers: {
    addSofrRates: (state, action: PayloadAction<ResultsStrip[]>) => {
      state.value = [...action.payload];
    },

    clearAll: () => initialState,
  },
});

export const { addSofrRates, clearAll } = sofrSlice.actions;

export default sofrSlice.reducer;

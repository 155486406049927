import { Divider, Link, Stack, Typography, useTheme } from "@mui/material";
import { useGetAccountQuery } from "api/api-accounts";
import LogoDesktop from "assets/logo.svg?react";
import SFIcon from "assets/salesforce.svg?react";
import { BUYER, INCOMLEND_INTERNAL_LINK, LEADS } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { pxToRem } from "helpers/pxToRem";
import React from "react";
import { isMobile } from "react-device-detect";
import type { CLDetails } from "types";

const LeadCustomerObject: React.FC = () => {
  const formikProps = useFormikContext<CLDetails>();

  const { values } = formikProps;

  const { buyerInternalName, leadCreatedBy, buyerId } = values;

  const theme = useTheme();
  const LINK = `${INCOMLEND_INTERNAL_LINK}${LEADS}/${buyerInternalName}`;

  const { data: account } = useGetAccountQuery(buyerId ?? "", {
    skip: !buyerId,
  });

  // const { source, sf_lead_id } = account ?? {};
  const source = values.source;

  return (
    <MainCard
      sx={{
        minWidth: pxToRem(330),
        width: "100%",
      }}
      headerSx={{
        px: 2,
        py: 2,
      }}
      title={
        <Typography
          fontWeight="bold"
          fontSize={isMobile ? "1.1em" : "1.3em"}
          variant="h3"
        >
          Lead Customer
        </Typography>
      }
      footerSx={{
        mt: "auto",
      }}
    >
      <Stack spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Link
              href={LINK}
              target="_blank"
              fontWeight="bold"
              fontSize="1.5em"
            >
              {formikProps.values.buyerName}
            </Link>

            <Divider orientation="vertical" flexItem />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Source
              </Typography>

              {source === "Salesforce" && account?.sf_lead_id ? (
                <SFIcon width={50} height="auto" />
              ) : (
                <LogoDesktop width={100} height="auto" />
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold">Account Type:</Typography>
            <Typography>{BUYER}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold">Created By:</Typography>
            <Typography>{formikProps.values.creator}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold">Reg.No:</Typography>
            <Typography>{formikProps.values.buyerRegNo}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold">Address:</Typography>
            <Typography>{formikProps.values.buyerAddress}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold">Country:</Typography>
            <Typography>{formikProps.values.buyerCountry}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold">Buyer's Annual Revenue:</Typography>
            <Typography>
              {`USD ${currencyFormatter({
                amount: formikProps.values.buyerAnnualRevenue,
              })}`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default LeadCustomerObject;

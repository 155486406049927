import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type {
  AdminRoles,
  // InvitedContact,
  IUserIT,
  UserDetails,
} from "./api-it.d";

const formatUsers = (
  data: {
    user_id: string;
    contact_id: string;
    first_name: string;
    last_name: string;
    username: string;
    last_login: Date;
    contact_created_at: string;
    is_active: boolean;
    departments: string[];
    groups: string[];
  }[]
): IUserIT[] => {
  return data?.map((item) => ({
    first_name: item.first_name,
    last_name: item.last_name,
    username: item.username,
    last_login: item.last_login,
    departments: item.departments,
    groups: item.groups,
    id: item.user_id,
    is_active: item.is_active ? "True" : "False",
    contact_id: item.contact_id,
  }));
};

export const it = createApi({
  reducerPath: "it",
  tagTypes: [
    "USERS",
    "USERS_INTERNAL",
    "USERS_EXTERNAL",
    "USERS_GROUPS",
    "USERS_GROUP_PERMISSIONS",
    "PERMISSIONS",
    "GROUP_PERMISSIONS",
    "GROUPS",
    "GROUP_MENUS",
    "GROUP_ACCESS",
    "ACCOUNTS",
    "DEPARTMENTS",
    "INVITED_CONTACTS",
    "PERMISSION_SETS",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/it`,
  }),
  endpoints: (builder) => ({
    getInternalUsers: builder.query<any, { usermanagparams: any }>({
      query: ({ usermanagparams }) => ({
        url: `/user_type/${usermanagparams}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["USERS_INTERNAL"],
    }),

    getExternalUsers: builder.query<any, { usermanagparams: any }>({
      query: ({ usermanagparams }) => ({
        url: `/users/external${usermanagparams}`,
        method: "get",
      }),
      transformResponse: (response) => formatUsers(response?.data),
      providesTags: ["USERS_EXTERNAL"],
    }),
    getUserById: builder.query<UserDetails, string>({
      query: (id) => ({ url: `/users/${id}`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["USERS"],
    }),
    updateUserById: builder.mutation<any, Partial<UserDetails>>({
      query: ({ id, ...payload }) => {
        return {
          url: `/users/contact/${id}`,
          method: "PUT",
          data: payload,
        };
      },
      invalidatesTags: ["USERS_INTERNAL", "USERS"],
      transformResponse: (response) => response?.data,
    }),

    getInvitedContacts: builder.query<any, { inviteduserparams: any }>({
      query: ({ inviteduserparams }) => ({
        url: `/invited-contacts${inviteduserparams}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["INVITED_CONTACTS"],
    }),
    inviteUser: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `/invite-contact`,
          method: "POST",
          data: data,
        };
      },
      invalidatesTags: ["USERS_EXTERNAL", "INVITED_CONTACTS"],
      transformResponse: (response) => response?.data,
    }),
    inviteInternalUser: builder.mutation<any, any>({
      query: (payload) => {
        return {
          url: `/invite-contact/internal`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["USERS_INTERNAL", "INVITED_CONTACTS"],
      transformResponse: (response) => response?.data,
    }),
    resendInvite: builder.mutation<
      any,
      {
        contact_id: string;
        type: "internal" | "external" | "Invistor" | "Sales_Partner";
      }
    >({
      query: ({ contact_id, type }) => {
        return {
          url: `/invite-contact/${contact_id}/resend/${type}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["INVITED_CONTACTS"],
      transformResponse: (response) => response?.data,
    }),

    // Groups
    getAllGroups: builder.query<AdminRoles, null>({
      query: () => ({
        url: `/groups`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GROUPS"],
    }),
    getAllGroupMenus: builder.query<Record<string, string>[], null>({
      query: () => ({
        url: `/groups/utils/menus`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GROUP_MENUS"],
    }),
    getAllGroupAccesses: builder.query<Record<string, string>[], null>({
      query: () => ({
        url: `/groups/utils/access`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GROUP_ACCESS"],
    }),
    getUserGroups: builder.query<{ id: string; name: string }[], string>({
      query: (user_id) => ({
        url: `/user/${user_id}/groups`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["USERS_GROUPS"],
    }),
    addRemoveUserGroups: builder.mutation<
      { id: string; name: string }[],
      { groups_to_add: string[]; groups_to_remove: string[]; user_id: string }
    >({
      query: (data) => {
        const { user_id, ...payload } = data;
        return {
          url: `/user/${user_id}/groups`,
          method: "PUT",
          data: payload,
        };
      },
      invalidatesTags: ["USERS_GROUPS", "USERS"],
      transformResponse: (response) => response?.data,
    }),
    addRemoveGroupPermissions: builder.mutation<
      any,
      {
        group_name: string;
        data: {
          permission_name: string;
          is_active: boolean;
        }[];
      }
    >({
      query: (payload) => {
        const { group_name, data } = payload;
        return {
          url: `/groups/${group_name}/permissions`,
          method: "PUT",
          data: [...data],
        };
      },
      invalidatesTags: [
        "USERS_GROUPS",
        "USERS_INTERNAL",
        "GROUP_PERMISSIONS",
        "USERS_GROUP_PERMISSIONS",
        "GROUPS",
      ],
      transformResponse: (response) => response?.data,
    }),
    addNewPermission: builder.mutation<any, string[]>({
      query: (permissions) => {
        return {
          url: `/permissions`,
          method: "POST",
          data: {
            permissions,
          },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["GROUP_PERMISSIONS", "GROUPS", "PERMISSIONS"],
    }),
    // Departments
    getAllDepartments: builder.query<{ id: string; name: string }[], null>({
      query: () => ({
        url: `/departments`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["DEPARTMENTS"],
    }),
    // Permissions
    getPermissionsPerGroup: builder.query<any[], string>({
      query: (group_name) => ({
        url: `/groups/${group_name}/permissions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GROUP_PERMISSIONS"],
    }),
    getAllUserPermissionsWithGroups: builder.query<AdminRoles, string>({
      query: (user_id) => ({
        url: `/user/${user_id}/groups-with-permissions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["USERS_GROUP_PERMISSIONS", "USERS_GROUPS"],
    }),
    //Set Permission Per User
    getAllPermissions: builder.query<any[], string>({
      query: () => ({
        url: `/permissions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["PERMISSIONS"],
    }),
    getAllUserPermissions: builder.query<any[], string>({
      query: (user_id) => ({
        url: `/user_permissions/${user_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["PERMISSION_SETS", "PERMISSIONS"],
    }),
    addUserPermission: builder.mutation<any, { user_id: string; permission_ids: string[] }>({
      query: ({ user_id, permission_ids }) => {
        return {
          url: `/user_permissions/${user_id}`,
          method: "POST",
          data: {
            permission_ids,
          },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["PERMISSION_SETS", "PERMISSIONS"],
    }),
    removeUserPermission: builder.mutation<
      any,
      { permission_set_ids: string[] }
    >({
      query: (data) => ({
        url: `/user_permissions/delete`,
        method: "DELETE",
        data,
      }),
      invalidatesTags: ["PERMISSION_SETS", "PERMISSIONS"],
      transformResponse: (response) => response?.data,
    }),
    // Accounts
    getAllAccounts: builder.query<
      { id: string; name: string; reg_no: string }[],
      null
    >({
      query: () => ({
        url: `/accounts`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["ACCOUNTS"],
    }),
    addAccountToUser: builder.mutation<
      any,
      { user_id: string; account_id: string }
    >({
      query: (data) => {
        return {
          url: `/contact-permission`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["USERS"],
      transformResponse: (response) => response?.data,
    }),
    removeAccountFromUser: builder.mutation<
      any,
      { user_id: string; account_id: string }
    >({
      query: (data) => {
        return {
          url: `/remove-contact-permission`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["USERS"],
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetInternalUsersQuery,
  useGetExternalUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserByIdMutation,
  useGetPermissionsPerGroupQuery,
  useGetAllUserPermissionsWithGroupsQuery,
  useGetAllPermissionsQuery,
  useGetAllUserPermissionsQuery,
  useAddUserPermissionMutation,
  useRemoveUserPermissionMutation,
  useGetUserGroupsQuery,
  useGetAllGroupsQuery,
  useGetAllGroupMenusQuery,
  useGetAllGroupAccessesQuery,
  useAddRemoveUserGroupsMutation,
  useGetAllAccountsQuery,
  useInviteUserMutation,
  useInviteInternalUserMutation,
  useGetAllDepartmentsQuery,
  useAddAccountToUserMutation,
  useRemoveAccountFromUserMutation,
  useAddRemoveGroupPermissionsMutation,
  useAddNewPermissionMutation,
  useGetInvitedContactsQuery,
  useResendInviteMutation,
} = it;

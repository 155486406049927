import { CL_APPROVED } from "codes";
import { useEffect, useState } from "react";
import type { Account, AccountRelationship } from "types";

const additionalInformationFilled = (partner?: Account) => {
  return partner?.industry !== "" &&
    partner?.goods_category !== "" &&
    partner?.annual_revenue !== null &&
    partner?.annual_revenue !== undefined
}

const tradeDetailsFilled = (relation?: AccountRelationship) => {
  return relation?.average_invoice_amount !== null &&
    relation?.average_invoice_amount !== undefined &&
    relation?.average_payment_terms !== "" &&
    relation?.number_of_past_paid_transaction !== null &&
    relation?.number_of_past_paid_transaction !== undefined
}

const creditLimitApproved = (partner?: Account) => {
  return partner?.credit_limit !== null &&
    partner?.credit_limit !== undefined &&
    partner?.credit_limit.approved_amount !== null &&
    partner?.credit_limit.approved_amount !== undefined &&
    partner?.credit_limit.status === CL_APPROVED
}

const usePartnerStatus = (data: { partner?: Account, relation?: AccountRelationship }) => {
  const [
    isPartnerAdditionalInformationFilledUp,
    setisPartnerAdditionalInformationFilledUp,
  ] = useState(additionalInformationFilled(data.partner));
  const [
    isPartnerTradeDetailsInformationFilledUp,
    setisPartnerTradeDetailsInformationFilledUp,
  ] = useState(tradeDetailsFilled(data.relation));
  const [
    isPartnerCreditLimitApproved,
    setisPartnerCreditLimitApproved,
  ] = useState(creditLimitApproved(data.partner));

  useEffect(() => {
    setisPartnerAdditionalInformationFilledUp(additionalInformationFilled(data.partner));
    setisPartnerTradeDetailsInformationFilledUp(tradeDetailsFilled(data.relation));
    setisPartnerCreditLimitApproved(creditLimitApproved(data.partner))
  }, [data.partner, data.relation]);
  return {
    isPartnerAdditionalInformationFilledUp,
    isPartnerTradeDetailsInformationFilledUp,
    isPartnerCreditLimitApproved
  };
};

export default usePartnerStatus;

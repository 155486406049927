import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Modal from "components/Common/Modal";
import { Form, Formik } from "formik";

import {
  useAddAccountToUserMutation,
  useAddRemoveUserGroupsMutation,
  useGetAllAccountsQuery,
  useGetAllGroupsQuery,
  useGetAllUserPermissionsWithGroupsQuery,
  useGetUserByIdQuery,
  useRemoveAccountFromUserMutation,
  useUpdateUserByIdMutation,
} from "api/api-it";
import { useDisableUserMutation } from "api/api-users-v2";
import {
  ACCOUNT_ADMIN,
  ACCOUNT_MEMBER,
  INCOMLEND_INTERNAL_LINK,
  IT_LINK,
} from "codes";
import AutoComplete from "components/Common/AutoComplete";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Switch from "components/Common/Switch";
import VariableInput from "components/Common/VariableInput";
import useIsMobile from "hooks/useIsMobile";
import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Add, Sync } from "@mui/icons-material";
import Breadcrumbs from "components/Common/Breadcrumbs";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import styles from "../usermanagement.module.scss";
import UserPermissionSet from "./userPermisionSet";

interface Account {
  id: string;
  name: string;
  permission: string;
  role: string;
}
interface UserDetails {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  id_no: null | any;
  title: string;
  last_login: string;
  sign_up: string;
  opted_marketing_updates: boolean;
  settings: null | any;
  accounts: Account[];
  departments: string[];
  groups: string[];
}

const ManageUserAccount = () => {
  const initialValues: UserDetails = {
    departments: [],
    email: "",
    first_name: "",
    accounts: [],
    groups: [],
    id: "",
    id_no: null,
    last_login: "",
    last_name: "",
    mobile: "",
    opted_marketing_updates: false,
    settings: null,
    sign_up: "",
    title: "",
  };
  const { id } = useParams();
  const {
    data: user,
    isFetching,
    refetch,
  } = useGetUserByIdQuery(id ?? "", {
    skip: !Boolean(id),
  });
  const theme = useTheme();

  const { data: userPermissionWithGroups } =
    useGetAllUserPermissionsWithGroupsQuery(id ?? "", { skip: !Boolean(id) });

  const { data: groups } = useGetAllGroupsQuery(null);
  const [updateUser, { isUninitialized }] = useUpdateUserByIdMutation();

  const { data: accounts, isFetching: isFetchingAllAccounts } =
    useGetAllAccountsQuery(null);

  const [showChangeGroupModal, setShowChangeGroupModal] = useState<{
    visible: boolean;
    changeGroups: { [groupName: string]: boolean } | undefined;
  }>({ visible: false, changeGroups: undefined });
  const [showAddAccountModal, setShowAddAccountModal] =
    useState<boolean>(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] =
    useState<boolean>(false);
  const [addRemoveGroups, { isLoading: isLoadingAddRemoveGroups }] =
    useAddRemoveUserGroupsMutation();
  const [addAccountToUser, { isLoading: isLoadingAddAcc }] =
    useAddAccountToUserMutation();
  const [removeAccountFromUser, { isLoading: isLoadingRemoveAcc }] =
    useRemoveAccountFromUserMutation();

  useEffect(() => {}, [isFetching]);

  const [disableUser, { isLoading: isLoadingUserActivation }] =
    useDisableUserMutation();

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };
  const isMobile = useIsMobile();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        ...user,
        allGroups: groups,
        userPermissionWithGroups,
        company: {
          id: "",
          name: "",
          reg_no: "",
        },
      }}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        return (
          <Form>
            <Grid item xs={8} margin="auto" maxWidth="70vw">
              <Grid
                container
                spacing={{ lg: 2, xs: 1 }}
                justifyContent="space-between"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={{ lg: 2, xs: 1 }}
                    justifyContent="space-between"
                    alignContent="center"
                  >
                    <Grid item xs={6}>
                      <Typography fontWeight="bold" fontSize="1.7em">
                        {`${formikProps.values.first_name} ${formikProps.values.last_name}`}
                        <IconButton onClick={refetch}>
                          <Sync />
                        </IconButton>
                      </Typography>
                      <Breadcrumbs
                        data={[
                          {
                            name: "Users",
                            link: `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/user-management`,
                          },
                          {
                            name: `${formikProps.values.first_name} ${formikProps.values.last_name}`,
                            link: `${window.location.pathname}`,
                            active: true,
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item mt={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            value={formikProps.values.is_active}
                            disabled={isLoadingUserActivation} //click-protection
                            onChange={(e: any) =>
                              disableUser({
                                user_id: id ?? "",
                                is_active: e.target.checked,
                              })
                                .unwrap()
                                .then(() =>
                                  toast("Action Successful", {
                                    type: "success",
                                  })
                                )
                                .catch((err) =>
                                  toast(`Error : ${err?.message}`, {
                                    type: "error",
                                  })
                                )
                            }
                          />
                        }
                        label="Active"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          background: theme.palette.error.main,
                          padding: "1ch 1ch",
                          borderRadius: "10px",
                          color: "white",
                          fontWeight: "600",
                          width: "fit-content",
                          fontSize: "1em",
                          maxWidth: "100%",
                        }}
                        sx={{
                          [theme.breakpoints.down("sm")]: {
                            fontSize: "1em",
                          },
                        }}
                      >
                        {formikProps.values.departments.join(" | ")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={{ lg: 2, xs: 1 }}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1em",
                          p: 2,
                          bgcolor: theme.palette.primary.main,
                          borderRadius: 1,
                          color: "#ffffff",
                        }}
                      >
                        Details
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={{ lg: 2, xs: 1 }}
                        justifyContent="space-between"
                      >
                        <Grid item xs={12} lg={5.5}>
                          <VariableInput
                            labelClassName={styles.labelClass}
                            name="first_name"
                            label="First Name"
                            style={{ width: "100%" }}
                            value={formikProps.values.first_name}
                            handleSave={(value) =>
                              updateUser({
                                id: formikProps.values.id,
                                first_name:
                                  value as typeof formikProps.values.first_name,
                              })
                            }
                            {...formikProps}
                          />
                        </Grid>
                        <Grid item xs={12} lg={5.5}>
                          <VariableInput
                            name="last_name"
                            label="Last Name"
                            labelClassName={styles.labelClass}
                            value={formikProps.values.last_name}
                            handleSave={(value) =>
                              updateUser({
                                id: formikProps.values.id,
                                last_name:
                                  value as typeof formikProps.values.last_name,
                              })
                            }
                            {...formikProps}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid item xs={12} lg={5.5}>
                          <VariableInput
                            labelClassName={styles.labelClass}
                            name="title"
                            label={t("job-position")}
                            style={{ width: "100%" }}
                            value={formikProps.values.title}
                            handleSave={(value) =>
                              updateUser({
                                id: formikProps.values.id,
                                title: value as typeof formikProps.values.title,
                              })
                            }
                            {...formikProps}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={5.5}
                          justifyContent="space-between"
                        >
                          <VariableInput
                            labelClassName={styles.labelClass}
                            tabIndex={5}
                            name="mobile"
                            label={`${t("phone-number")}`}
                            placeholder={`${t("phone-number")}`}
                            fullWidth
                            type="phone"
                            value={formikProps.values.mobile}
                            handleSave={(value) =>
                              updateUser({
                                id: formikProps.values.id,
                                mobile:
                                  value as typeof formikProps.values.mobile,
                              })
                            }
                            {...formikProps}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs={12} lg={5.5}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <LabelViewOnly
                                label="Email"
                                labelClassName={styles.labelClass}
                              />
                            </Grid>
                            <Grid item>
                              <LabelViewOnly
                                label={formikProps.values.email}
                                sx={{
                                  fontSize: "1em",
                                  textTransform: "inherit",
                                  background: theme.palette.error.main,
                                  color: theme.palette.background.default,
                                  padding: "1ch 1ch",
                                  borderRadius: "10px",
                                  fontWeight: "600",
                                  width: "fit-content",
                                  maxWidth: "100%",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {Boolean(formikProps.values.accounts?.length) && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1em",
                            p: 2,
                            bgcolor: theme.palette.primary.main,
                            borderRadius: 1,
                            color: "#ffffff",
                          }}
                        >
                          Accounts
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="space-between"
                          spacing={2}
                        >
                          {Boolean(formikProps.values.accounts?.length) &&
                            formikProps.values.accounts?.map((item, i) => (
                              <Fragment key={item.name}>
                                <Grid item xs={12} lg={8}>
                                  <Grid container spacing={2}>
                                    <Grid item>{`${i + 1}.`}</Grid>
                                    <Grid item xs={4}>
                                      {item.name}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                  <Button
                                    fullWidth
                                    color="error"
                                    variant="contained"
                                    onClick={() => {
                                      setShowRemoveAccountModal(true);
                                      formikProps.setFieldValue("company", {
                                        id: item.id,
                                        name: item.name,
                                      });
                                    }}
                                  >
                                    Disconnect
                                  </Button>
                                </Grid>
                              </Fragment>
                            ))}
                          {Boolean(formikProps.values.accounts?.length) && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12} lg={3}>
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => setShowAddAccountModal(true)}
                              startIcon={<Add />}
                            >
                              Connect an Account
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1em",
                          p: 2,
                          bgcolor: theme.palette.primary.main,
                          borderRadius: 1,
                          color: "#ffffff",
                        }}
                      >
                        Groups
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={{ lg: 2, xs: 1 }}>
                        {formikProps.values.allGroups &&
                          Object.entries(formikProps.values.allGroups)?.map(
                            ([key, permissions]: [
                              key: string,
                              permissions: { [name: string]: boolean },
                            ]) => (
                              <Grid item xs={12} lg={4} key={key}>
                                <Grid container sx={{ pl: 2 }}>
                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={`groups.${key}`}
                                          value={formikProps.values.groups.includes(
                                            key
                                          )}
                                          checked={formikProps.values.groups.includes(
                                            key
                                          )}
                                          onChange={(e, checked) => {
                                            if (
                                              (key === ACCOUNT_ADMIN ||
                                                key === ACCOUNT_MEMBER) &&
                                              !formikProps.values.groups.includes(
                                                key
                                              )
                                            ) {
                                              setShowAddAccountModal(true);
                                            } else
                                              setShowChangeGroupModal({
                                                visible: true,
                                                changeGroups: {
                                                  [key]: checked,
                                                },
                                              });
                                          }}
                                          //if the user is already admin of a group, they wouldnt need it for member privileges as admin is always a superset.
                                          disabled={
                                            formikProps.values.groups?.includes(
                                              `${key?.split("_")[0]}_ADMIN`
                                            ) && key?.includes("MEMBER")
                                          }
                                        />
                                      }
                                      label={key}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1em",
                          p: 2,
                          bgcolor: theme.palette.primary.main,
                          borderRadius: 1,
                          color: "#ffffff",
                        }}
                      >
                        Permission Set
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                      <UserPermissionSet />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Modal
              message={
                <Grid item margin="auto">
                  {showChangeGroupModal?.changeGroups &&
                    Object.entries(showChangeGroupModal?.changeGroups)?.map(
                      ([key, value]) =>
                        `You are about to change ${
                          formikProps.values.first_name
                        }'s group ${key} to ${value ? "True" : "False"}`
                    )}
                </Grid>
              }
              sx={modalSx}
              width={isMobile ? "90%" : "40%"}
              height="auto"
              modalContentSx={{ height: "auto" }}
              open={showChangeGroupModal.visible}
              modalOverlaySx={modalOverlaySx}
              modalFooterSx={{ maxWidth: "100%" }}
              onClose={() =>
                setShowChangeGroupModal({
                  visible: false,
                  changeGroups: undefined,
                })
              }
              primary={{
                variant: "contained",
                loading: isLoadingAddRemoveGroups,
                onClick: () => {
                  for (let group in showChangeGroupModal.changeGroups) {
                    formikProps.setFieldValue(
                      `groups`,
                      showChangeGroupModal?.changeGroups[group]
                        ? [group, ...formikProps.values.groups]
                        : formikProps.values.groups.filter(
                          (item) => item !== group
                        ),
                      true
                    );
                    const groups_to_add = showChangeGroupModal?.changeGroups[
                      group
                    ]
                      ? [group]
                      : [];
                    const groups_to_remove = !showChangeGroupModal
                      ?.changeGroups[group]
                      ? [group]
                      : [];
                    addRemoveGroups({
                      groups_to_add,
                      groups_to_remove,
                      user_id: id ?? "",
                    })
                      .unwrap()
                      .then(() =>
                        toast(
                          `Groups ${
                            groups_to_add?.length ? "added" : "removed"
                          } successfully!`,
                          { type: "success" }
                        )
                      )
                      .catch((err) =>
                        toast(`Something went wrong: ${err?.message}`, {
                          type: "error",
                        })
                      )
                      .finally(() =>
                        setShowChangeGroupModal({
                          visible: false,
                          changeGroups: undefined,
                        })
                      );
                  }
                },
                children: "Confirm",
              }}
              closeButton
              secondary={{
                variant: "outlined",
                onClick: () =>
                  setShowChangeGroupModal({
                    visible: false,
                    changeGroups: undefined,
                  }),
                children: `${t("cancel")}`,
              }}
            />
            {/* Add an account to a user */}
            <Modal
              Header={
                <>
                  <Grid item xs={12}>
                    <Typography fontWeight="bold" sx={{ pl: 1 }}>
                      Associate an Account
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              }
              message={
                <Grid item xs={12} lg={12} margin="auto">
                  <LabelViewOnly
                    label="Select Company"
                    labelClassName={styles.labelClass}
                  />
                  {isFetchingAllAccounts && <SkeletonLoad bars={1} />}
                  {!isFetchingAllAccounts && accounts && (
                    <AutoComplete
                      labelKey="name"
                      data={accounts}
                      name="company"
                      fullWidth
                    />
                  )}
                </Grid>
              }
              sx={modalSx}
              height="auto"
              modalContentSx={{ height: "auto" }}
              open={showAddAccountModal}
              modalOverlaySx={modalOverlaySx}
              modalFooterSx={{ maxWidth: "100%" }}
              onClose={() => setShowAddAccountModal(false)}
              primary={{
                variant: "contained",
                disabled: !Boolean(formikProps.values?.company?.id),
                loading: isLoadingAddAcc,
                onClick: () => {
                  if (formikProps.values?.company?.id !== "") {
                    addAccountToUser({
                      account_id: formikProps.values?.company?.id,
                      user_id: id ?? "",
                    })
                      .unwrap()
                      .then(() =>
                        toast(
                          `Account ${formikProps.values?.company?.name} successfully associated with user!`,
                          { type: "success" }
                        )
                      )
                      .catch((err) => {
                        toast(`Something went wrong: ${err?.message}`, {
                          type: "error",
                        });
                      })
                      .finally(() => setShowAddAccountModal(false));
                  }
                },
                children: "Associate",
              }}
              closeButton
              secondary={{
                variant: "outlined",
                onClick: () => setShowAddAccountModal(false),
                children: `${t("cancel")}`,
              }}
            />
            {/* Remove an account from user */}
            <Modal
              message={
                <Grid item margin="auto">
                  {`You are about to disconnect ${formikProps.values?.company?.name} from this user.`}
                </Grid>
              }
              sx={modalSx}
              width={isMobile ? "90%" : "40%"}
              height="auto"
              modalContentSx={{ height: "auto" }}
              open={showRemoveAccountModal}
              modalOverlaySx={modalOverlaySx}
              modalFooterSx={{ maxWidth: "100%" }}
              onClose={() => setShowRemoveAccountModal(false)}
              primary={{
                variant: "contained",
                loading: isLoadingRemoveAcc,
                onClick: () => {
                  if (formikProps.values?.company?.id !== "") {
                    removeAccountFromUser({
                      account_id: formikProps.values?.company?.id,
                      user_id: id ?? "",
                    })
                      .unwrap()
                      .then(() =>
                        toast(
                          `Account ${formikProps.values?.company?.name} successfully disconnected from user!`,
                          { type: "success" }
                        )
                      )
                      .catch((err) => {
                        toast(`Something went wrong: ${err?.message}`, {
                          type: "error",
                        });
                      })
                      .finally(() => setShowRemoveAccountModal(false));
                  }
                },
                children: "Confirm",
              }}
              closeButton
              secondary={{
                variant: "outlined",
                onClick: () => setShowRemoveAccountModal(false),
                children: `${t("cancel")}`,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default ManageUserAccount;

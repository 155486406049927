import SyncIcon from "@mui/icons-material/Sync";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import {
  useGetDocumentContentQuery,
  useGetInvoiceProofOfPaymentDocsQuery,
  useGetInvoiceProofOfPaymentDocumentUrlQuery,
  useGetInvoiceRequiredDocuemntsQuery,
  useUploadInvoiceProofOfpaymentMutation
} from "api/api-accounts";
import Modal from "components/Common/Modal";

import DocView from "components/Common/DocView";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import { getFileTypeFromMagicNumber } from "helpers/getFileTypeFromMagicNumber";
import { useState } from "react";

import { useGetCurrentUserQuery } from "api/api-users-v2";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { PERMISSIONS } from "permissions";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";

const ProofOfPaymentDocumentList = ({
  initialValues,
  entity_id,
  disabled = false
}: {
  initialValues: Record<string, any>;
  entity_id: string;
  disabled: boolean;
}) => {
  const [document_id, setDocumentId] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const USER_PERMISSION = useAppSelector((state) => state.appState.user_permission);

  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  const { data: curr_user } = useGetCurrentUserQuery();

  const isInternal = curr_user?.is_internal;
  const canUpload = !isInternal || USER_PERMISSION.includes(PERMISSIONS.termsheet_financing)

  const { data: invoice_docs, isFetching: isFetchingInvoiceDocs, refetch } = useGetInvoiceProofOfPaymentDocsQuery(entity_id, { skip: !Boolean(entity_id), });
  const { data: docs, isFetching: isFetchingRequiredDocuments, refetch: refetchRequired } = useGetInvoiceRequiredDocuemntsQuery(entity_id, { skip: !Boolean(entity_id) });

  const { data: documentUrl, refetch: refetchDocUrl } = useGetInvoiceProofOfPaymentDocumentUrlQuery({
    invoice_id: entity_id,
    document_id: document_id ?? "",
  }, { skip: !Boolean(document_id) });

  const { data: documentContent } = useGetDocumentContentQuery({
    account_id: entity_id,
    document_id: document_id ?? "",
  }, { skip: !Boolean(document_id) });

  const [uploadProof, { isLoading: isLoadingProofUpload }] = useUploadInvoiceProofOfpaymentMutation()

  const docDictionary: any =
    docs?.map((doc) => ({
      ...doc,
      name: toCamelCase(doc.display_name),
      label: doc.display_name,
      value: doc.display_name,
      items: doc.items.map((sub) => ({
        ...sub,
        name: toCamelCase(sub.display_name),
        label: sub.display_name,
        value: sub.display_name,
      })),
    })) ?? [];

  const DOCUMENT_TYPES = docs?.map((doc) => ({
    name: doc.display_name,
    description: doc.description,
  }));

  function base64ToBlob(base64String: string, mimeType: string) {
    const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, "");
    const decodedData = atob(base64WithoutPrefix);

    const arrayBuffer = new ArrayBuffer(decodedData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < decodedData.length; i += 1) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeType });
  }
  const downloadDocument = () => {
    const binaryData = documentContent ?? "";
    const filetype = getFileTypeFromMagicNumber(binaryData);
    const blob = base64ToBlob(binaryData, filetype ?? "");
    const extension = (): string => {
      switch (filetype) {
        case "image/jpeg": return "jpg";
        case "image/png": return "png";
        case "application/pdf": return "pdf";
        default: return "jpg"
      }
    };
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `download.${extension()}`);
    document.body.appendChild(link);
    link.click();
  };
  const onUpload = (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    idx: number
  ) => {
    uploadProof({
      invoiceId: entity_id,
      body: {
        file: data?.file,
        document_type,
        document_category: "lead_invoice"
      }
    })
      .unwrap()
      .then(() => {
        const documentName =
          DOCUMENT_TYPES?.find(({ name }) => toCamelCase(name) === label)
            ?.name ?? "";
        toast(`Uploaded ${documentName} succesfully!`, {
          type: "success",
        });
      })
      .catch((err) => toast.error(err.message))
  }

  const fetchDocument = (id: string) => {
    setDocumentId(id);
    onOpen();
    refetchDocUrl();
  };

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={() => { }}
      >
        {(muFormikProps) => {
          return (
            <Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography variant="h4">Documents</Typography>
                <IconButton onClick={() => {
                  refetchRequired();
                  refetch();
                }}>
                  <SyncIcon />
                </IconButton>
              </Stack>
              {(isFetchingInvoiceDocs || isFetchingRequiredDocuments) && (<SkeletonLoad bars={5} />)}
              {!isFetchingInvoiceDocs && !isFetchingRequiredDocuments && (
                <DocumentUploadController
                  data={docDictionary}
                  formikProps={muFormikProps}
                  initialData={invoice_docs ?? []}
                  onUpload={onUpload}
                  onView={(fileName, id, idx) => fetchDocument(id)}
                  enableCount={false}
                  deleteDisabled
                  uploadDisabled={!canUpload}
                  isLoading={isLoadingProofUpload}
                  acceptFileTypes="image/jpeg,image/png,application/pdf"
                  disabled={disabled}
                />
              )}
              <Modal
                modalOverlaySx={{ borderRadius: "2ch" }}
                width={documentUrl?.url?.includes(".pdf?") ? "60%" : "30%"}
                height={documentUrl?.url?.includes(".pdf?") ? "80vh" : "auto"}
                closeButton
                message={
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                      {documentUrl?.url?.includes(".pdf?") ? (
                        <Grid item xs={12} id="modal-docview" style={{ height: "100%" }}>
                          <DocView
                            uri={documentUrl?.url}
                            staticView
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <img
                            src={documentUrl?.url}
                            alt={documentUrl?.url}
                            width="100%"
                            height="auto"
                            style={{
                              borderRadius: "2ch",
                              margin: "auto",
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                }
                open={show}
                primary={{
                  children: "Download",
                  onClick: () => documentUrl && downloadDocument(),
                }}
                onClose={onClose}
              />
            </Stack>
          );
        }}
      </Formik>
    </Grid>
  );
};
export default ProofOfPaymentDocumentList;

import { Divider, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import type { CLDetails } from "types";
import { useGetSalesListQuery } from "api/api-origination";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { PERMISSIONS } from "permissions";
import { Fragment, useState } from "react";
import { useAppSelector } from "redux/hooks";
import styles from "../cl.module.scss";
import CLDecisionObject from "./CLDecisionObject";
import CLRequestInfoObject from "./CLRequestInfoObject";
import InsurerObject from "./InsurerObject";
import LeadCustomerObject from "./LeadCustomerObject";
import SalesDropDown from "./SalesDropDown";
import Timeline from "./Timeline";
import useIsMobile from "hooks/useIsMobile";
const CLDetailsComponent = () => {
  const formikProps = useFormikContext<CLDetails>();
  const APP_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const isMobile = useIsMobile();
  const disabled = !APP_PERMISSIONS.includes(PERMISSIONS.approve_credit_limit);
  const { data: salesReps, isFetching } = useGetSalesListQuery(null);
  const { dirty, values } = formikProps;
  const { salesLead, leadCreatedBy, buyerInternalName, reqId } = values;
  const [salesId, setSalesID] = useState(salesLead?.id);
  const setter = (value: string, prev?: string, index?: number) => {
    const newSL = salesReps?.find(
      (rep) => `${rep.first_name} ${rep.last_name}` === value
    );
    setSalesID(newSL?.id ?? "");
  };
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  return (
    <Stack spacing={1}>
      {isMobile ? (
        <Stack direction="column" spacing={1}>
          <LeadCustomerObject />
          {USER_PERMISSIONS.includes(PERMISSIONS.get_insurer) && (
            <InsurerObject />
          )}
        </Stack>
      ) : (
        <>
          <Divider />
          <Stack direction="row" spacing={1}>
            <LeadCustomerObject />
            {USER_PERMISSIONS.includes(PERMISSIONS.get_insurer) && (
              <InsurerObject />
            )}
          </Stack>
        </>
      )}
      <Stack direction="row" spacing={2} width="100%" alignItems="center">
        <Stack width="50%">
          {isFetching && <SkeletonLoad bars={1} />}
          {!isFetching && salesReps && (
            <SalesDropDown
              multilevel={false}
              disabled={disabled}
              data={salesReps}
              name="salesName"
              value={`${formikProps.values.salesLead.first_name} ${formikProps.values.salesLead.last_name}`}
              keyValue="name"
              label="Sales Owner"
              placeholder="Select"
              setValue={setter}
              {...formikProps}
            />
          )}
        </Stack>
        <Stack width="50%">
          <Stack direction="row" alignItems="center" spacing={2} width="100%">
            <label htmlFor="sourceName" className={styles.labelClass}>
              Source Name
            </label>
            <Input
              label=""
              value={formikProps.values?.sales_source ?? ""}
              name="sales_source"
              placeholder="Source Name"
              disabled
              sx={{ flex: 1 }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Timeline />
      <Divider />
      <Stack direction={isMobile ? "column" : "row"} spacing={1}>
        <CLRequestInfoObject salesId={salesId} disabled={disabled} />
        <CLDecisionObject salesId={salesId} disabled={disabled} />
      </Stack>
    </Stack>
  );
};
export default CLDetailsComponent;

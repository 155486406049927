import SyncIcon from "@mui/icons-material/Sync";
import {
  Button,
  Chip,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useCustomerApproveMutation,
  useCustomerRejectMutation,
  useGetTermsheetByIdQuery,
  useGetTermsheetByInternalNameQuery,
} from "api/api-accounts";
import {
  INCOMLEND_INTERNAL,
  INCOMLEND_INTERNAL_LINK,
  SUPPLIER,
  TERMSHEET_STATUS,
  TERMSHEETS_LINK,
} from "codes";
import BreadcrumbsComponent from "components/Common/Breadcrumbs";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CountdownSettingsDrawer from "components/Company/Lead/CountdownSettings";
import TermSheetPreview from "components/Company/Lead/LeadTermSheetPreview";
import TermsheetModal from "components/Company/Lead/TermSheetModal";
import DayCounter from "components/DayCounter/DayCounter";
import StatusDisplay from "components/Termsheets/StatusDisplay";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";

const CustomerTermSheet = () => {
  const theme = useTheme();
  const id = useParams<{ id: string }>()?.id;
  const isMobile = useIsMobile();
  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  const isInternal = APP_PERMISSION === INCOMLEND_INTERNAL;
  const {
    data: termsheet,
    isFetching: isFetchingTermsheet,
    refetch: refetchTermsheet,
  } = isInternal
    ? useGetTermsheetByInternalNameQuery(id ?? "", { skip: !Boolean(id) })
    : useGetTermsheetByIdQuery(id ?? "", { skip: !Boolean(id) });

  const isFetching = isFetchingTermsheet;
  const partner = termsheet?.partner;
  const BASE_URL = isInternal
    ? `${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}`
    : `${TERMSHEETS_LINK}`;

  const [customerApprove, { isLoading: customerApproveLoading }] =
    useCustomerApproveMutation();
  const [customerReject, { isLoading: customerRejectLoading }] =
    useCustomerRejectMutation();

  const [showApprove, setShowApprove] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [showExpirySettings, setShowExpirySettings] = useState<boolean>(false);

  const profile = termsheet?.lead?.profile;
  const getLabel = (profile?: string) => {
    switch (profile?.toLowerCase()) {
      case "seller":
        return SUPPLIER;
      case "buyer":
        return "buyer";
      default:
        "No profile Set";
    }
  };

  const USER_PERMISSION = useAppSelector(
    (state) => state.appState.user_permission
  );

  const showCounter =
    termsheet?.termsheet_status === TERMSHEET_STATUS.PENDING_EXTERNAL ||
    termsheet?.termsheet_status === TERMSHEET_STATUS.CANCELLED;
  const canApprove = USER_PERMISSION.includes(PERMISSIONS.termsheet_approver);
  const canEditTimer =
    canApprove &&
    isInternal &&
    (termsheet?.termsheet_status === TERMSHEET_STATUS.PENDING_EXTERNAL ||
      termsheet?.termsheet_status === TERMSHEET_STATUS.CANCELLED);
  const getExpiry = () => {
    if (!termsheet?.expiry_info?.expiry_date) {
      return new Date();
    }
    return moment(termsheet?.expiry_info?.expiry_date).toDate();
  };

  return (
    <>
      {(isFetchingTermsheet || termsheet === undefined) && (
        <SkeletonLoad bars={10} />
      )}
      {!isFetchingTermsheet && termsheet !== undefined && (
        <Layout
          sx={{ padding: "0px" }}
          title={
            <Stack direction={"row"} alignItems={"center"}>
              <Stack spacing={1}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  whiteSpace={"nowrap"}
                >
                  {isInternal ? (
                    <Link
                      fontSize={"1.2em"}
                      href={`${INCOMLEND_INTERNAL_LINK}/leads/${termsheet?.lead?.internal_name}?partner=${partner?.internal_name}`}
                    >
                      {" "}
                      {termsheet?.lead?.name}{" "}
                    </Link>
                  ) : (
                    <Typography fontSize={"1.2em"}>
                      {termsheet?.lead?.name}
                    </Typography>
                  )}
                  <Chip
                    label={getLabel(profile)}
                    sx={{ height: "2em", ml: 2 }}
                    variant="outlined"
                  />
                  <IconButton onClick={() => refetchTermsheet()}>
                    <SyncIcon />
                  </IconButton>
                </Stack>
                <BreadcrumbsComponent
                  data={[
                    {
                      name: `Term sheets`,
                      link: `${BASE_URL}`,
                    },
                    {
                      name: `${termsheet?.lead?.name ?? ""}`,
                      link: `${BASE_URL}/${id}`,
                      active: true,
                    },
                  ].filter((item) => item.name !== "")}
                />
              </Stack>
              <StatusDisplay
                status={termsheet?.termsheet_status ?? ""}
                style={{ width: "fit-content" }}
              />
              {showCounter && (
                <DayCounter
                  minDay={5}
                  date={getExpiry()}
                  isDrawer={canEditTimer}
                  drawerOpen={showExpirySettings}
                  setDrawerOpen={setShowExpirySettings}
                  disabled={!(termsheet?.expiry_info?.is_active ?? true)}
                />
              )}
              {!showCounter && canEditTimer && (
                <Button
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "auto",
                  }}
                  variant="outlined"
                  onClick={() => setShowExpirySettings(!showExpirySettings)}
                >
                  Reset expiry
                </Button>
              )}
              {termsheet?.expiry_info && canEditTimer && (
                <CountdownSettingsDrawer
                  id={termsheet?.expiry_info?.id}
                  drawerOpen={showExpirySettings}
                  setDrawerOpen={setShowExpirySettings}
                  date={getExpiry()}
                  defaultDays={15}
                />
              )}
            </Stack>
          }
          textHeadingOptions={{
            fontSize: "1.2em",
          }}
          headerConfig={{
            left: 5,
            mid: 4,
            right: 3,
          }}
          mode="default"
          alignItems="center"
          primary={
            isInternal
              ? undefined
              : {
                  children: "Approve",
                  fullWidth: true,
                  variant: "contained",
                  color: "success",
                  isLoading: customerApproveLoading,
                  disabled:
                    customerRejectLoading ||
                    termsheet?.termsheet_status !==
                      TERMSHEET_STATUS.PENDING_EXTERNAL ||
                    termsheet === undefined,
                  onClick: () => {
                    setShowApprove(true);
                  },
                }
          }
          secondary={
            isInternal
              ? undefined
              : {
                  children: "Reject",
                  fullWidth: true,
                  color: "error",
                  variant: "outlined",
                  isLoading: customerRejectLoading,
                  disabled:
                    customerApproveLoading ||
                    termsheet?.termsheet_status !==
                      TERMSHEET_STATUS.PENDING_EXTERNAL ||
                    termsheet === undefined,
                  onClick: () => {
                    setShowReject(true);
                  },
                }
          }
        >
          <Modal
            message={
              <Typography>{`You are approving the term sheet of ${termsheet?.lead?.name}`}</Typography>
            }
            open={showApprove}
            modalHeaderSx={{ background: "transparent" }}
            onClose={() => setShowApprove(false)}
            width={isMobile ? "90%" : "25%"}
            modalOverlaySx={{
              backgroundColor: `${theme.palette.background.default} !important`,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4ch",
              padding: "2ch 3ch",
              fontSize: `${theme.typography.fontSize}px !important`,
              fontFamily: `${theme.typography.fontFamily} !important`,
              fontWeight: "inherit !important",
            }}
            height="auto"
            closeButton
            primary={{
              children: "Approve",
              color: "primary",
              onClick: () => {
                customerApprove(partner?.id ?? "")
                  .unwrap()
                  .then(() =>
                    toast.success("Successfully approved the term sheet")
                  )
                  .catch((err) => toast.error(`${err.message}`));
                setShowApprove(false);
              },
            }}
            secondary={{
              children: "Cancel",
              color: "secondary",
              onClick: () => {
                setShowApprove(false);
              },
            }}
          />
          <TermsheetModal
            open={showReject}
            setOpen={setShowReject}
            title={`You are rejecting the term sheet of ${termsheet?.lead?.name}.`}
            primary={{
              children: "Reject",
              color: "error",
              onClick: ({ comments }) => {
                customerReject({
                  partner_id: partner?.id ?? "",
                  body: {
                    comment: comments ?? "",
                  },
                })
                  .unwrap()
                  .then(() =>
                    toast.success("Successfully rejected the term sheet")
                  )
                  .catch((err) => toast.error(`${err.message}`));
              },
            }}
          />
          {isFetching && <SkeletonLoad bars={10} />}
          {!isFetching && termsheet && partner && (
            <TermSheetPreview termsheet={termsheet} partner={partner} />
          )}
        </Layout>
      )}
    </>
  );
};

export default CustomerTermSheet;

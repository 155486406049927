import { Box, Button, Divider, Stack, Tab, Tabs } from "@mui/material";
import {
  useEditScreeningCompanyBatchMutation,
  useEditScreeningDirectorBatchMutation,
  useEditScreeningShareholderBatchMutation,
  useGetComplianceTemplatesQuery,
  useGetScreeningDirectorsQuery,
  useGetScreeningQuery,
  useGetScreeningShareholdersQuery,
} from "api/api-compliance";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import useScreenByProviderGenerator from "hooks/useScreenByProviderGenerator";
import { MapContext } from "pages/maps/map";
import React, { useState } from "react";
import BaseScreening from "./BaseScreening";
import DirectorScreening from "./DirectorScreening";
import ShareholderScreening from "./ShareholderScreening";

interface ScreenByProviderProps {
  accountType: string;
  provider: string;
  isLocked: boolean;
}

const ScreenByProvider: React.FC<ScreenByProviderProps> = (props) => {
  const mapName = React.useContext(MapContext);
  const { accountType, provider, isLocked } = props;

  const { data: screeningTemplate } =
    useGetComplianceTemplatesQuery("screening");
  const { data: screeningValues, isLoading: isLoadingScreeningValues } =
    useGetScreeningQuery(
      {
        map_name: mapName ?? "",
        account_type: accountType as "supplier" | "buyer",
      },
      { skip: !Boolean(mapName) }
    );
  const { data: screeningShareholders } = useGetScreeningShareholdersQuery(
    {
      map_name: mapName ?? "",
      account_type: accountType as "supplier" | "buyer",
    },
    { skip: !Boolean(mapName) }
  );
  const { data: screeningDirectors } = useGetScreeningDirectorsQuery(
    {
      map_name: mapName ?? "",
      account_type: accountType as "supplier" | "buyer",
    },
    { skip: !Boolean(mapName) }
  );

  const companyVariations = Object.keys(screeningTemplate ?? {});

  const shareholderVariation = Array.from(
    { length: screeningShareholders?.length ?? 0 },
    (_, i) => `Shareholder ${i + 1}`
  );
  const directorVariation = Array.from(
    { length: screeningDirectors?.length ?? 0 },
    (_, i) => `Director ${i + 1}`
  );

  const {
    initialValuesCompany,
    initialValuesShareholders,
    initialValuesDirectors,
  } = useScreenByProviderGenerator(
    screeningTemplate,
    screeningValues,
    screeningShareholders,
    screeningDirectors,
    provider
  );

  const [editCompanyBatchScreening] = useEditScreeningCompanyBatchMutation();
  const [editShareholderBatchScreening] =
    useEditScreeningShareholderBatchMutation();
  const [editDirectorBatchScreening] = useEditScreeningDirectorBatchMutation();

  const [checkboxValue, setCheckboxValue] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleSubmit = (values: any) => {};

  const handleBatchPatch = () => {
    if (activeTab === 0) {
      let data: { map_metadata_id: string; source: string; value: any }[] = [];
      companyVariations.map((variation) => {
        data.push({
          map_metadata_id:
            initialValuesCompany[toCamelCase(variation)]?.map_metadata_id ?? "",
          source: provider,
          value: {
            status: "No Hits",
            remarks: "Done",
          },
        });
      });
      editCompanyBatchScreening({
        map_name: mapName ?? "",
        account_type: accountType as "supplier" | "buyer",
        data: data,
      });
    } else if (activeTab === 1) {
      const data: {
        status: string;
        remarks: any;
        shareholder_id: string;
        source: string;
      }[] = [];
      shareholderVariation.map((variation) => {
        data.push({
          shareholder_id:
            initialValuesShareholders[toCamelCase(variation)]?.id ?? "",
          source: provider,
          status: "No Hits",
          remarks: "Done",
        });
      });
      editShareholderBatchScreening({
        map_name: mapName ?? "",
        account_type: accountType as "supplier" | "buyer",
        data: data,
      });
    } else if (activeTab === 2) {
      const data: {
        status: string;
        remarks: any;
        director_id: string;
        source: string;
      }[] = [];
      directorVariation.map((variation) => {
        data.push({
          status: "No Hits",
          remarks: "Done",
          director_id: initialValuesDirectors[toCamelCase(variation)]?.id ?? "",
          source: provider,
        });
      });
      editDirectorBatchScreening({
        map_name: mapName ?? "",
        account_type: accountType as "supplier" | "buyer",
        data: data,
      });
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Formik
      initialValues={{
        initialValuesCompany,
        initialValuesShareholders,
        initialValuesDirectors,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form>
            <Stack spacing={2}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      setCheckboxValue(!checkboxValue);
                      handleBatchPatch();
                    }}
                    disabled={isLocked}
                  >
                    Mark as No Hits
                  </Button>
                </Box>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Company Name Variations" />
                <Tab label="List of Shareholders" />
                <Tab label="List of Directors" />
              </Tabs>
              <Divider />
              {activeTab === 0 && (
                <>
                  {isLoadingScreeningValues && <SkeletonLoad bars={10} />}
                  {!isLoadingScreeningValues &&
                    companyVariations &&
                    companyVariations.map((variation) => (
                      <BaseScreening
                        variation={variation}
                        accountType={accountType as "supplier" | "buyer"}
                        source={provider}
                        isLocked={isLocked}
                      />
                    ))}
                </>
              )}
              {activeTab === 1 && (
                <>
                  {shareholderVariation &&
                    shareholderVariation.map((variation) => (
                      <ShareholderScreening
                        variation={variation}
                        accountType={accountType}
                        source={provider}
                        isLocked={isLocked}
                      />
                    ))}
                </>
              )}
              {activeTab === 2 && (
                <>
                  {directorVariation &&
                    directorVariation.map((variation) => (
                      <DirectorScreening
                        variation={variation}
                        accountType={accountType}
                        source={provider}
                        isLocked={isLocked}
                      />
                    ))}
                </>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ScreenByProvider;

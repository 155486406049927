import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";

import type { IPLAttendee, Interviewee, CompanyDoc } from "./api-risk.d";

export interface CallSchedule {
  created_at?: Date;
  call_scheduled_date?: Date;
  call_implementation_date?: Date;
  ipl_attendees: IPLAttendee[];
  interviewees: Interviewee[];
  reference: string;
}

/**
 * Creates an API instance for handling risk-related operations.
 *
 * @returns The risk API instance.
 */
export const risk = createApi({
  reducerPath: "risk",
  tagTypes: ["CALL_SCHEDULE"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/maps`,
  }),
  endpoints: (builder) => ({
    getCallSchedule: builder.query<
      CallSchedule,
      {
        map_id: string;
        account_type: string;
      }
    >({
      query: ({ map_id, account_type }) => ({
        url: `/${map_id}/${account_type}/call-schedule`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CALL_SCHEDULE"],
    }),

    /** call_scheduled_date?: string;
          call_implementation_date?: string;**/
    editCallSchedule: builder.mutation<
      void,
      {
        map_id: string;
        account_type: string;
        data: {
          call_scheduled_date?: string;
          call_implementation_date?: string;
          reference?: string;
        };
      }
    >({
      query({ map_id, account_type, data }) {
        return {
          url: `/${map_id}/${account_type}/call-schedule`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    addCallAttendee: builder.mutation<
      void,
      {
        map_id: string;
        account_type: string;
        data: {
          /** contact_id of the internal_user**/
          contact_id: string;
        }[];
      }
    >({
      query({ map_id, account_type, data }) {
        return {
          url: `/${map_id}/${account_type}/ipl-attendee`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    addInterViewee: builder.mutation<
      void,
      {
        map_id: string;
        account_type: string;
        data: {
          interviewee_name: string;
          interviewee_title: string;
        };
      }
    >({
      query({ map_id, account_type, data }) {
        return {
          url: `/${map_id}/${account_type}/interviewee`,
          method: "post",
          data,
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    editInterviewee: builder.mutation<
      void,
      {
        participant_id: string;
        data: {
          interviewee_name?: string;
          interviewee_title?: string;
        };
      }
    >({
      query({ participant_id, data }) {
        return {
          url: `/interviewee/${participant_id}`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    deleteParticipant: builder.mutation<void, string>({
      query(participant_id) {
        return {
          url: `/participants/${participant_id}`,
          method: "delete",
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    addRiskDocument: builder.mutation<
      any,
      { file: File; mapId: string; accountType: string }
    >({
      query: ({ file, mapId, accountType }) => {
        const accountTypeString = accountType;
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/${mapId}/${accountTypeString}/risk-other-doc/risk/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },

      transformResponse: (response) => response?.data,
      invalidatesTags: ["CALL_SCHEDULE"],
    }),
    deleteDocument: builder.mutation<
      any,
      { document_id: string; mapId: string }
    >({
      query: ({ document_id, mapId }) => {
        return {
          url: `/${mapId}/risk/documents/${document_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["CALL_SCHEDULE"],
    }),
    getRiskDocuments: builder.query<
      CompanyDoc[],
      { mapId: string; account_type: string }
    >({
      query: ({ mapId, account_type }) => ({
        url: `/${mapId}/${account_type}/risk-other-doc/risk/documents`,
        method: "GET",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CALL_SCHEDULE"],
    }),

    getRiskDocumentUrl: builder.query<
      any,
      { document_id: string; mapId: string }
    >({
      query: ({ document_id, mapId }) => ({
        url: `/${mapId}/risk/documents/${document_id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetCallScheduleQuery,
  useEditCallScheduleMutation,
  useAddCallAttendeeMutation,
  useAddInterVieweeMutation,
  useDeleteParticipantMutation,
  useEditIntervieweeMutation,
  useAddRiskDocumentMutation,
  useDeleteDocumentMutation,
  useGetRiskDocumentsQuery,
  useGetRiskDocumentUrlQuery,
} = risk;

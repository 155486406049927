import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CLDetailsComponent from ".";
import CreditLimitPartners from "./CreditLimitPartners";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lead-tabpanel-${index}`}
      aria-labelledby={`lead-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `lead-tab-${index}`,
    "aria-controls": `lead-tabpanel-${index}`,
  };
}

export default function CLSectionTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} id="lead-tabs-box">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="lead section tabs"
          variant="fullWidth"
          centered
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Partner(s)" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CLDetailsComponent />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CreditLimitPartners />
      </CustomTabPanel>
    </Box>
  );
}

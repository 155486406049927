import React from 'react'
import { Grid, Typography, List, ListItem } from '@mui/material';

interface SellerBuisnessOverviewProps {
    sellerName?: string;
    supplierTextData?: string[];
  }

const SellerBuisnessOverview : React.FC<SellerBuisnessOverviewProps> = ({
    sellerName,
    supplierTextData = [],
  }) =>{
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" color="primary">
            {sellerName} Business Overview:
          </Typography>
          {supplierTextData.map((line, index) => {
            if (line.startsWith("# ")) {
              return (
                <Typography key={line} variant="h4" sx={{ fontWeight: "bold" }}>
                  {line.replace("# ", "")}
                </Typography>
              );
            }
            if (line.startsWith("## ")) {
              return (
                <Typography
                  key={line}
                  variant="h6"
                  sx={{ mt: 2, fontWeight: "bold" }}
                >
                  {line.replace("## ", "")}
                </Typography>
              );
            }
            if (line.startsWith("- ")) {
              return (
                <List key={line}>
                  <ListItem>• {line.replace("- ", "")}</ListItem>
                </List>
              );
            }
            if (/^\d+\./.test(line)) {
              return (
                <List key={line}>
                  <ListItem>{line}</ListItem>
                </List>
              );
            }
            return (
              <Typography key={line} variant="body1" sx={{ mt: 1 }}>
                {line}
              </Typography>
            );
          })}
        </Grid>
      </Grid>
    );
  };

export default SellerBuisnessOverview;

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface InputData {
  value: string;
}

const initialState: InputData = {
  value: "",
};

export const searchSlice = createSlice({
  name: "search",

  initialState,

  reducers: {
    setSearchData: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },

    clearAll: () => initialState,
  },
});

export const { setSearchData, clearAll } = searchSlice.actions;

export default searchSlice.reducer;

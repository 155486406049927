import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import {
  useGetBuyerCreditLimitQuery,
  useUpdateTermsheetInfoMutation
} from "api/api-accounts";
import MainCard from "components/Common/Cards/MainCard";
import DateInput from "components/Common/DateInput";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import valueCleaner from "helpers/valueCleaner";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TermSheetInfoKeyMappings } from "../Details/CompanyDetailsKeyMappings";
import type { CompanyDetails } from "../Details/validationSchema";
import { DEFAULT_FACILITY_FEE_TYPE, FACILITY_FEE_TYPES } from "codes";

const LeadTermSheetInfo = () => {
  const { t } = useTranslation();
  const formikProps = useFormikContext<CompanyDetails>();

  const [updateTermsheetInfo, { isLoading }] = useUpdateTermsheetInfoMutation();

  // Change the condition once BE bug regarding primary key for(reverse_factoring) is fixed
  const isReverseFactoring = Boolean(
    formikProps.values.account_product &&
      ["reverse_factoring", "reverse factoring"].includes(
        formikProps.values.account_product?.toLowerCase()
      )
  );
  const {
    data: availableCreditLimit,
    isFetching: isFetchingCreditLimit,
    status,
  } = useGetBuyerCreditLimitQuery(formikProps.values.id ?? "");

  const accounts = [
    {
      name: "Incomlend Account",
    },
    {
      name: "My Account",
    },
  ];

  const advanceAmounts = [
    {
      name: "97%",
      value: 97,
    },
    {
      name: "95%",
      value: 95,
    },
    {
      name: "90%",
      value: 90,
    },
    {
      name: "80%",
      value: 80,
    },
  ];

  const setterAccount = (value: string) => {
    formikProps.setTouched({ repayment_account: true }, true);
    formikProps.setFieldValue("repayment_account", value, true);
  };

  const setterAdvanceAmount = (value: string) => {
    formikProps.setTouched({ advanced_amount: true }, true);
    formikProps.setFieldValue("advanced_amount", value, true);
  };

  const setFacilityFeeType = (value: string) => {
    formikProps.setTouched({ facility_fee_type: true }, true);
    formikProps.setFieldValue(
      "facility_fee_type",
      value && value !== ""
        ? FACILITY_FEE_TYPES.find((val) => val.name === value)?.value
        : DEFAULT_FACILITY_FEE_TYPE
    );
  }

  const termsheetInfokeyMappings = TermSheetInfoKeyMappings(isReverseFactoring);
  const hasMissingFields = Boolean(
    Object.keys(termsheetInfokeyMappings).filter(
      (item) => (formikProps.values as Record<string, any>)[item] === undefined || (formikProps.values as Record<string, any>)[item] === null
    ).length
  );

  const { dirty, values } = formikProps;

  const SUCCESFUL_CL_CALL =
    !isFetchingCreditLimit && availableCreditLimit && status !== "rejected";
  const NO_CL = !isFetchingCreditLimit && status === "rejected";

  return (
    <MainCard
      border={false}
      content
      boxShadow={false}
      sx={{ boxShadow: "none !important", p: 0 }}
      footer={
        <Grid item xs={12} lg={2} ml="auto" my={1} justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            fullWidth
            data-testid="save-termsheet-info"
            loading={isLoading}
            disabled={!dirty}
            onClick={() => {
              const {
                setup_fee,
                facility_fee,
                facility_fee_type,
                customer_maintenance_fee,
                partner_maintenance_fee,
                repayment_account,
                advanced_amount,
                annualized_discount_rate,
                first_invoice_amount,
                first_invoice_expected_date,
                discount_rate_type,
              } = formikProps.values;
              let payload: Record<string, any> = {
                setup_fee: valueCleaner(`${setup_fee}`) ?? 0,
                facility_fee: facility_fee ?? 0,
                facility_fee_type,
                customer_maintenance_fee: valueCleaner(
                  `${customer_maintenance_fee}`
                ) ?? 0,
                partner_maintenance_fee: valueCleaner(
                  `${partner_maintenance_fee}`
                ) ?? 0,
              };
              if (isReverseFactoring) {
                payload = {
                  ...payload,
                  annualized_discount_rate,
                  first_invoice_amount: valueCleaner(`${first_invoice_amount}`),
                  first_invoice_expected_date: moment(
                    first_invoice_expected_date
                  ).format("YYYY-MM-DD") as unknown as Date,
                  discount_rate_type,
                };
              } else {
                payload = {
                  ...payload,
                  repayment_account,
                  advanced_amount: advanceAmounts.find((amt) => amt.name === advanced_amount)?.value,
                };
              }

              for (const key in payload) {
                if (
                  payload[key] === "" ||
                  payload[key] === null ||
                  payload[key] === undefined ||
                  Number.isNaN(payload[key])
                ) {
                  delete payload[key];
                }
              }
              if (!formikProps.values.id) {
                return
              }
              updateTermsheetInfo({
                accountId: formikProps.values.id,
                body: payload
              })
                .unwrap()
                .then(() => {
                  formikProps.resetForm({ values });
                  toast(
                    `Termsheet Info of ${formikProps?.values?.name} saved successfully`,
                    {
                      type: "success",
                    }
                  );
                })
                .catch((error) => {
                  const errorFile = error?.message;
                  toast(`Something went wrong : ${errorFile}`, {
                    type: "error",
                  });
                });
            }}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      }
    >
      <Stack spacing={1}>
        {hasMissingFields && (
          <Alert severity="error">
            <AlertTitle>Missing Fields</AlertTitle>
            <Stack
              spacing={1}
              direction="row"
              flexWrap="wrap"
              display="flex"
              rowGap="1ch"
              data-testid="missing-fields-term-sheet-info-client"
            >
              {Object.keys(termsheetInfokeyMappings)
                .filter(
                  (item) => !(formikProps.values as Record<string, any>)[item]
                )
                .map((error) => (
                  <Chip label={`${termsheetInfokeyMappings[error]}`} />
                ))}
            </Stack>
          </Alert>
        )}
        <Grid container spacing={1}>
          {isReverseFactoring ? (
            <React.Fragment>
              <Grid item lg={6} xs={12}>
                <React.Fragment>
                  <Typography
                    fontWeight={600}
                    fontSize="1.1em"
                    textAlign="left"
                  >
                    CREDIT LIMIT
                  </Typography>

                  {isFetchingCreditLimit && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="66px"
                    />
                  )}

                  {SUCCESFUL_CL_CALL && (
                    <Input
                      name="credit_limit_amount"
                      label="Credit Limit"
                      value={currencyFormatter({
                        amount: availableCreditLimit?.requested_amount || 0,
                        currency: "USD",
                      })}
                      disabled
                      type="money"
                      textfieldProps={{
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              USD
                            </InputAdornment>
                          ),
                        },
                      }}
                      style={{ width: "100%" }}
                      {...formikProps}
                    />
                  )}

                  {NO_CL && (
                    <Typography
                      fontWeight={500}
                      fontSize="1.1em"
                      textAlign="left"
                      bgcolor="#F3F3F3"
                    >
                      No credit limit available
                    </Typography>
                  )}
                </React.Fragment>
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={600} fontSize="1.1em" textAlign="left">
                  FIRST INVOICE
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Input
                  name="first_invoice_amount"
                  label="First Invoice"
                  type="money"
                  textfieldProps={{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">USD</InputAdornment>
                      ),
                    },
                  }}
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <DateInput
                  name="first_invoice_expected_date"
                  label="First Invoice Expected Date"
                  required
                  value={
                    formikProps.values.first_invoice_expected_date !==
                      undefined &&
                    formikProps.values.first_invoice_expected_date !== null
                      ? new Date(
                          formikProps.values.first_invoice_expected_date as Date
                        )
                      : undefined
                  }
                  error={
                    formikProps.errors.first_invoice_expected_date as string
                  }
                  onChangeCommitted={(date) => {
                    formikProps.setFieldTouched(
                      "first_invoice_expected_date",
                      true
                    );
                    formikProps.validateField("first_invoice_expected_date");
                  }}
                  {...formikProps}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography fontWeight={600} fontSize="1.1em" textAlign="left">
                  PAYMENT DETAILS
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <React.Fragment>
                  <Drop
                    label="Repayment Account"
                    required
                    name="repayment_account"
                    style={{ width: "100%" }}
                    keyValue="name"
                    data={accounts}
                    setValue={setterAccount}
                    placeholder="Select Repayment Account"
                    {...formikProps}
                  />
                </React.Fragment>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Drop
                  label="Advanced Amount"
                  required
                  name="advanced_amount"
                  style={{ width: "100%" }}
                  keyValue="name"
                  data={advanceAmounts}
                  setValue={setterAdvanceAmount}
                  placeholder="Select Adavanced Amount"
                  {...formikProps}
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize="1.1em" textAlign="left">
              COST DETAILS
            </Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              name="setup_fee"
              label="Setup Fee"
              required
              type="money"
              textfieldProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">USD</InputAdornment>
                  ),
                },
              }}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Input
              type="number"
              name="facility_fee"
              label="Facility Fee"
              required
              style={{ width: "100%" }}
              textfieldProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }
              }}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Drop
              required
              style={{ width: "100%" }}
              name="facility_fee_type"
              label="Facility Fee To Be Paid"
              keyValue="name"
              data={FACILITY_FEE_TYPES}
              placeholder="Select facility fee type"
              value={
                FACILITY_FEE_TYPES
                  .find((val) => val.value === (
                    formikProps.values.facility_fee_type ?? DEFAULT_FACILITY_FEE_TYPE
                  ))?.name
              }
              setValue={setFacilityFeeType}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              name="customer_maintenance_fee"
              label="Customer Annual Maintenance Fee"
              required
              type="money"
              textfieldProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">USD</InputAdornment>
                  ),
                },
              }}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              name="partner_maintenance_fee"
              label="Partner Annual Maintenance Fee"
              required
              type="money"
              textfieldProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">USD</InputAdornment>
                  ),
                },
              }}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          {isReverseFactoring && (
            <Grid item lg={6} xs={12}>
              <Input
                name="annualized_discount_rate"
                label="Annualized Discount Rate"
                required
                type="number"
                textfieldProps={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  },
                }}
                style={{ width: "100%" }}
                {...formikProps}
              />
              <FormControl sx={{ mt: 1, mb: 0.5, p: 0.5 }}>
                <RadioGroup
                  aria-labelledby="discount_rate_type"
                  name="discount_rate_type"
                  value={formikProps.values.discount_rate_type}
                  onChange={(e) => {
                    formikProps.setFieldValue(
                      "discount_rate_type",
                      e.target.value === "true"
                    );
                  }}
                  sx={{ flexWrap: "nowrap" }}
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Fixed"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Floating"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Stack>
    </MainCard>
  );
};

export default LeadTermSheetInfo;

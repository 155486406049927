import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import DateInput from "components/Common/DateInput";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import valueCleaner from "helpers/valueCleaner";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import { RegistrationDetailsKeyMappings } from "../Details/CompanyDetailsKeyMappings";
import ShippingAddress from "../Details/ShippingAddress";

import { useUpdateAccountMutation } from "api/api-accounts";
import { useGetCountriesQuery } from "api/api-misc";
import { NEW_USER, PRESALES, REGISTERED } from "codes";
import AutoComplete from "components/Common/AutoComplete";
import moment from "moment";
import type { Country } from "types";
import { type CompanyDetails } from "../Details/validationSchema";

const LeadRegistrationDetails = () => {
  const theme = useTheme();
  const formikProps = useFormikContext<CompanyDetails>();
  const { t } = useTranslation();
  const [updateCompany, { isLoading }] = useUpdateAccountMutation();
  const { country, shipping_address, iso3 = "" } = formikProps.values;

  const APP_STATE = useAppSelector((state) => state.appState.value);
  const isShippingAddressCompleted =
    shipping_address?.address_line1 !== "" &&
    shipping_address?.postcode !== "" &&
    shipping_address?.city !== "" &&
    shipping_address?.country !== "" &&
    shipping_address?.country !== "";
  const registrationDetailsKeyMappings = RegistrationDetailsKeyMappings(t);

  const missing_fields = Object.keys(registrationDetailsKeyMappings).filter(
    (item) => {
      if (item === "shipping_address" && !isShippingAddressCompleted)
        return true;
      return !(formikProps.values as Record<string, any>)[item];
    }
  );
  const IS_POST_SALES = (state: string) =>
    ![NEW_USER, REGISTERED, PRESALES].includes(state);
  const hasMissingFields =
    Boolean(missing_fields.length) && IS_POST_SALES(APP_STATE);
  useEffect(() => {
    formikProps.setTouched({});
  }, []);

  const { values, dirty } = formikProps;
  const { data: countries } = useGetCountriesQuery();

  const getIso3 = (value: string) => {
    const selected = countries?.find((item: any) => item.name === value);
    return selected?.id;
  };
  const setter = (value: string) => {
    const selected = countries?.find((item: any) => item.name === value);
    formikProps.setFieldValue("country", selected?.name, true);
    formikProps.setFieldValue("iso3", selected?.id, true);
  };

  return (
    <MainCard
      border={false}
      content
      boxShadow={false}
      sx={{ boxShadow: "none !important", p: 0 }}
      footer={
        <Grid item xs={12} lg={2} ml="auto" my={1} justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            fullWidth
            data-testid="save-registration-details"
            loading={isLoading}
            disabled={!dirty}
            onClick={() => {
              const {
                id,
                name,
                postcode,
                reg_no,
                shipping_address,
                website,
                address_line1,
                address_line2,
                city,
                goods_category,
                industry,
              } = formikProps.values;
              const updatedValues = {
                id,
                address_line1,
                address_line2,
                city,
                goods_category,
                industry,
                name,
                postcode,
                reg_no,
                shipping_address: {
                  ...shipping_address,
                  country:
                    shipping_address?.iso3 ??
                    getIso3(shipping_address?.country as string),
                },
                website,
                incorporation_date: moment(
                  formikProps.values.incorporation_date
                ).format("YYYY-MM-DD") as unknown as Date,
                annual_revenue: valueCleaner(
                  `${formikProps.values.annual_revenue}`
                ),
                country: iso3 ?? getIso3(country as string),
                type: "LEAD",
              };
              if (!formikProps.values.shipping_address?.country) {
                updatedValues.shipping_address = undefined as unknown as {
                  country: string | undefined;
                  address_line1?: string;
                  address_line2?: string;
                  city?: string;
                  postcode?: string;
                  iso3?: string;
                };
              }
              const filteredUpdateCompanyPayload = Object.fromEntries(
                Object.entries(updatedValues).filter(
                  ([_, value]) =>
                    value !== undefined &&
                    value !== "" &&
                    value !== null &&
                    value !== "Invalid date"
                )
              );
              updateCompany(filteredUpdateCompanyPayload)
                .unwrap()
                .then(() => {
                  toast(`Company details saved successfully`, {
                    type: "success",
                  });
                  formikProps.resetForm({ values });
                })
                .catch((error) => {
                  const errorFile = error?.message;
                  toast(`Something went wrong : ${errorFile}`, {
                    type: "error",
                  });
                });
            }}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      }
    >
      <Stack spacing={1}>
        {hasMissingFields && (
          <Alert severity="error">
            <AlertTitle>Missing Fields</AlertTitle>
            <Stack
              spacing={1}
              direction="row"
              flexWrap="wrap"
              display="flex"
              rowGap="1ch"
            >
              {missing_fields.map((error) => (
                <Chip label={`${registrationDetailsKeyMappings[error]}`} />
              ))}
            </Stack>
          </Alert>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Input
              name="name"
              required
              label={`${t("company-full-legal-name")}`}
              placeholder={`${t("company-full-legal-name")}`}
              fullWidth
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              name="reg_no"
              required
              label={`${t("company-registration-number")}`}
              placeholder={`${t("company-registration-number")}`}
              fullWidth
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <DateInput
              name="incorporation_date"
              label={`${t("company-incorporation-date")}`}
              required
              value={
                formikProps.values.incorporation_date !== undefined &&
                formikProps.values.incorporation_date !== null
                  ? new Date(formikProps.values.incorporation_date as Date)
                  : undefined
              }
              error={formikProps.errors.incorporation_date as string}
              onChangeCommitted={(date) => {
                formikProps.setFieldTouched("incorporation_date", true);
                formikProps.validateField("incorporation_date");
              }}
              {...formikProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bolder",
              }}
            >
              {t("company-registered-address")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              name="address_line1"
              required
              label={`${t("address")} 1`}
              placeholder={`${t("street")} 1`}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              name="address_line2"
              label={`${t("address")} 2`}
              placeholder={`${t("street")} 2`}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              name="city"
              label={`${t("city")}`}
              required
              placeholder={`${t("city")}`}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <AutoComplete
              data={countries as Country[]}
              name="country"
              label="Country"
              labelKey={"name" as any}
              value={formikProps.values.country}
              placeholder="Select Country"
              onChange={(
                event: any,
                newValue:
                  | NonNullable<string | Country>
                  | (string | Country)[]
                  | null
              ) => {
                setter((newValue as Country)?.name);
              }}
              fullWidth
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              name="postcode"
              label={`${t("postcode")}`}
              placeholder={`${t("postcode")}`}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <ShippingAddress isDisabled={false} />
        </Grid>
      </Stack>
    </MainCard>
  );
};
export default LeadRegistrationDetails;

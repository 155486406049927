import React, {useState} from "react";
import {
  Typography,
  Grid,
  Divider,
  Paper,
  useTheme,
  List,
  ListItem,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetAccountCommentsQuery,
  useGetMapInsurersQuery,
  useGetMapRiskParametersQuery,
  useGetMapSummaryQuery,
  useGetRiskMapApprovalsQuery,
} from "api/api-maps";
import { useGetCallScheduleQuery } from "api/api-risk";
import { format } from "date-fns";
import { useGetSummariesQuery } from "api/api-compliance";
import parse from "html-react-parser";
import BuyerFinancialTable from "./FinancialTableOverview/BuyerFinancialTable";
import SellerFinancialTable from "./FinancialTableOverview/SellerFinancialTable";
import SellerBuisnessOverview from "./BusinessOverview/SellerBusinessOverview";
import BuyerBusinessOverview from "./BusinessOverview/BuyerBusinessOverview";
import SellerBuyerTradeRelationShip from "./TradeRelationship/SellerBuyerTradeRelationShip";
import Layout from "components/Common/Layout";
import { PERMISSIONS } from "permissions";
import { useAppSelector } from "redux/hooks";
import { VITE_API_V2_URL } from "codes";

const CC_Memo = () => {
  const { id = "" } = useParams();
  const theme = useTheme();

  const {
    data: mapData,
    error: mapDataError,
    refetch: refetchMapSummary, // This is the refetch function
  } = useGetMapSummaryQuery(id ?? "", { skip: !id });
  
  // Call this function to fetch the latest data
  const fetchLatestData = async () => {
    try {
      await refetchMapSummary(); // This will fetch fresh data from the server
    } catch (error) {
      console.error("Error fetching latest data:", error);
    }
  };
  
  // Example: Call fetchLatestData when needed
  React.useEffect(() => {
    if (id) {
      fetchLatestData();
    }
  }, [id]);
  const { data: mapInsurers, error: mapInsurersError } = useGetMapInsurersQuery(
    id ?? "",
    { skip: !id }
  );
  const { data: callSchedule, error: callScheduleError } = useGetCallScheduleQuery(
    { map_id: id ?? "", account_type: "supplier" },
    { skip: !id }
  );
  const {
    data: buyerCallSchedule,
    error: buyerCallScheduleError,
  } = useGetCallScheduleQuery(
    { map_id: id ?? "", account_type: "buyer" },
    { skip: !id }
  );
  const {
    data: financialsData,
    error: financialsError,
  } = useGetMapRiskParametersQuery(
    { map_name: id ?? "", account_type: "supplier", item_type: "financial" },
    { skip: !id }
  );
  const {
    data: buyerFinancialData,
    error: buyerFinancialError,
  } = useGetMapRiskParametersQuery(
    { map_name: id ?? "", account_type: "buyer", item_type: "financial" },
    { skip: !id }
  );
  const { data: summariesData, error: summariesError } = useGetSummariesQuery(
    id ?? "",
    { skip: !id }
  );
  const {
    data: supplierComments,
    error: supplierCommentsError,
  } = useGetAccountCommentsQuery(
    { map_name: id ?? "", account_type: "supplier" },
    { skip: !id }
  );
  const { data: buyerComments, error: buyerCommentsError } = useGetAccountCommentsQuery(
    { map_name: id ?? "", account_type: "buyer" },
    { skip: !id }
  );
  const { data: riskApproval } = useGetRiskMapApprovalsQuery(id ?? "", {
    skip: !id,
  });

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  // Permissions and approval status
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const READ_ONLY = USER_PERMISSIONS?.includes(PERMISSIONS.map_view_risk);
  const isMapApproved = riskApproval?.approval_status === "Approved";
  const isMapRejected = riskApproval?.approval_status === "Rejected";
  const isFieldDisabled = READ_ONLY || isMapApproved || isMapRejected;

  // Generate PDF function
  const generatePdf = () => {
    setIsGeneratingPdf(true);

    const getToken = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found in localStorage");
      }
      return JSON.parse(token).value;
    };

    const url = `${VITE_API_V2_URL}/maps/${id}/score-card/export`;
    const link = document.createElement("a");

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to generate PDF");
        }
        return response.blob();
      })
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob);
        link.href = objectURL;
        link.download = `${id}_Risk_Score_Card.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        alert("Failed to generate PDF. Please try again.");
      })
      .finally(() => {
        setIsGeneratingPdf(false);
      });
  };

  // Extract and format data
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
  ];

  const years = financialsData
    ? Object.keys(financialsData).sort((a, b) => b.localeCompare(a))
    : [];

  const formattedYears = years?.map((year) => {
    const [month, yearPart] = year.split("/");
    const formattedMonth = monthNames[parseInt(month, 10) - 1];
    return `${formattedMonth}-${yearPart?.split("-")[0]}`;
  });

  const metricMapping = {
    Revenue: "Revenue",
    "Gross Profit": "Gross Profit",
    "GP Margin": "GP Margin",
    "Net Profit": "Net Profit",
    "NP Margin": "NP Margin",
    "EBIT Margin": "EBIT Margin",
    "Current Assets": "Current Assets",
    "Current Liabilities": "Current Liabilities",
    "Current Ratio": "Current Ratio",
    "Total Assets": "Total Assets",
    "Total Liabilities": "Total Liabilities",
    Equity: "Equity",
    "Total Debt": "Total Debt",
    "D/E": "Debt/Equity",
    Cash: "Cash & equivalent",
    CCC: "Cash Conversion",
    Receivables: "Trade Receivables",
    Payables: "Trade Payables",
    Inventory: "Inventory",
  };

  const periods = financialsData ? Object.keys(financialsData) : [];
  const commentsTxt = buyerComments?.financial_comments ?? "";
  const comments = supplierComments?.financial_comments ?? "";
  const supllierTextData = summariesData?.supplier?.split("\n").filter(Boolean) ?? [];
  const buyerTextData = summariesData?.buyer?.split("\n").filter(Boolean) ?? [];
  const dealSummaryTextData = summariesData?.deal?.split("\n").filter(Boolean) ?? [];

  const renderFinancialComments = (comments: string = "") => {    
    if (!comments) return null;
    return parse(comments);
  };

  const renderFormattedComments = (comments?: string) => {
    if (!comments || comments.trim() === "") return null;
  
    const commentLines = comments.split("\n").map((line) => {
        const uniqueKey = `${line}-${Math.random().toString(36).substr(2, 9)}`;
        if (line.startsWith("# ")) {
          return (
            <Typography key={uniqueKey} variant="h4" sx={{ fontWeight: "bold" }}>
              {line.replace("# ", "")}
            </Typography>
          );
        }
        if (line.startsWith("## ")) {
          return (
            <Typography key={uniqueKey} variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              {line.replace("## ", "")}
            </Typography>
          );
        }
        if (line.startsWith("- ")) {
          return (
            <List key={uniqueKey}>
              <ListItem>• {line.replace("- ", "")}</ListItem>
            </List>
          );
        }
        if (/^\d+\./.test(line)) {
          return (
            <List key={uniqueKey}>
              <ListItem>{line}</ListItem>
            </List>
          );
        }
        return (
          <Typography key={uniqueKey} variant="body1" sx={{ mt: 1 }}>
            {line}
          </Typography>
        );
      });
  
    return <>{commentLines}</>;
  };  

  return (
    <Layout
      title="Credit Committee Memo"
      textHeadingOptions={{ fontWeight: "bold", level: 4 }}
      headerConfig={{
        syncAction: refetchMapSummary, // Use the refetch function from the query
        left: 10,
        mid: 0,
        right: 2,
      }}
      primary={{
        children: "Export",
        onClick: generatePdf,
        isLoading: isGeneratingPdf,
        fullWidth: true,
        disabled: isFieldDisabled,
      }}
    >
      {/* Content inside the Layout component */}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h5" color="primary">
            Product Type:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {mapData?.ipl_product}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" color="primary">
            Map Details:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {id} - {mapData?.seller_name} Selling to {mapData?.buyer_name}. (USD{" "}
            {mapInsurers?.credit_committee_amount ?? ""})
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" color="primary">
            Prepared By:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {callSchedule?.ipl_attendees
              ?.map((attendee) => attendee.name)
              .join(", ") || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" color="primary">
            Date:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {callSchedule?.call_implementation_date
              ? format(
                  new Date(callSchedule.call_implementation_date),
                  "dd MMMM yyyy"
                )
              : ""}
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {/* Supplier Interview Details */}
          <Grid item xs={6}>
            <Paper
              elevation={3}
              sx={{
                mt: 2,
                padding: 2,
                backgroundColor: theme.palette.background.paper,
                height: "100px", // Set a fixed height
                overflowY: "auto", // Add vertical scrollbar when content overflows
              }}
            >
              <Typography variant="h5" color="primary" gutterBottom>
                Supplier Interview Details:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Interviewed:{" "}
                {callSchedule?.interviewees?.length > 0 ? (
                  <ul
                    style={{
                      listStyleType: "disc",
                      paddingLeft: "20px",
                      margin: 0,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {callSchedule.interviewees.map((interviewee) => (
                      <li key={interviewee.interviewee_name}>
                        <Typography variant="body1" color="textSecondary">
                          {interviewee.interviewee_name} (
                          {interviewee.interviewee_title})
                        </Typography>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    N/A
                  </Typography>
                )}
              </Typography>
            </Paper>
          </Grid>

          {/* Buyer's Interview Details */}
          <Grid item xs={6}>
            <Paper
              elevation={3}
              sx={{
                mt: 2,
                padding: 2,
                backgroundColor: theme.palette.background.paper,
                height: "100px", // Set a fixed height
                overflowY: "auto", // Add vertical scrollbar when content overflows
              }}
            >
              <Typography variant="h5" color="primary" gutterBottom>
                Buyer's Interview Details:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Interviewed:{" "}
                {buyerCallSchedule?.interviewees?.length > 0 ? (
                  <ul
                    style={{
                      listStyleType: "disc",
                      paddingLeft: "20px",
                      margin: 0,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {buyerCallSchedule.interviewees.map(
                      (interviewee) => (
                        <li key={interviewee.interviewee_name}>
                          <Typography variant="body1" color="textSecondary">
                            {interviewee.interviewee_name} (
                            {interviewee.interviewee_title})
                          </Typography>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    N/A
                  </Typography>
                )}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <SellerBuisnessOverview
        sellerName={mapData?.seller_name}
        supplierTextData={supllierTextData}
      />
      <Divider sx={{ my: 2 }} />
      <BuyerBusinessOverview
        buyerName={mapData?.buyer_name}
        buyerTextData={buyerTextData}
      />
      <Divider sx={{ my: 2 }} />
      <SellerBuyerTradeRelationShip
        sellerName={mapData?.seller_name}
        buyerName={mapData?.buyer_name}
        dealSummaryTextData={dealSummaryTextData}
      />
      <Divider sx={{ my: 2 }} />

      <SellerFinancialTable
        mapData={mapData}
        formattedYears={formattedYears}
        metricMapping={metricMapping}
        periods={periods}
        financialsData={financialsData}
      />

      <Typography variant="h3" color="primary" sx={{ mt: 4 }}>
        Financial Analysis for supplier:
      </Typography>
      {renderFinancialComments(comments)}
      <Divider sx={{ my: 2 }} />
      <Typography variant="h3" color="primary">
        Financial {mapData?.buyer_name}:
      </Typography>
      <BuyerFinancialTable
        buyerFinancialData={buyerFinancialData}
        error={
          buyerFinancialData &&
          buyerFinancialData?.err_message === "no ipl product"
        }
      />
      <Typography variant="h3" color="primary" sx={{ mt: 2 }}>
        Financial Analysis for buyer:
      </Typography>
      {renderFinancialComments(commentsTxt)}
      <Typography variant="h4" color="primary" sx={{ mt: 2 }}>
        Key Risks:
      </Typography>
      {renderFormattedComments(mapData?.score_card_comments)}
      <Divider sx={{ my: 2 }} />
      <Typography variant="h4">Risk Recommendations</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {mapData?.risk_recommendation || "No risk recommendations provided."}
      </Typography>
      <Divider sx={{ my: 2 }} />
    </Layout>
  );
};

export default CC_Memo;

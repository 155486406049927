import SyncIcon from "@mui/icons-material/Sync";
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs, { type TabsProps } from "@mui/material/Tabs";
import {
  useEditAccountCommentsMutation,
  useGetAccountCommentsQuery,
  useGetMapRiskParametersQuery,
  useGetMapSummaryQuery,
  useGetRiskMapApprovalsQuery,
  useGetTemplatesV2Query,
  type MAP_PARTY,
} from "api/api-maps";
import { useGetCallScheduleQuery, type CallSchedule } from "api/api-risk";
import { INCOMLEND_PRODUCTS, SUPPLIER } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { MapContext, MapCurrentVersion } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import Financials from "../CC/Financials";
import { defaultClientCallValues } from "../defaults";
import styles from "../maps.module.scss";
import CallDetails from "./CallDetails";
import CompanyBackground from "./CompanyBackground";
import RiskDocUpload from "./RiskCocumentsUpload/Index";

export interface MeetingDetails extends CallSchedule {
  financialNotes: any;
  mode_of_call: string;
  productType: string;
}

interface AdditionalComments {
  "Business history": string;
  "Group structure": string;
  "Plant capacity": string;
  Others: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface StyledTabsProps extends TabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const ClientCall = ({ accountType }: { accountType: string }) => {
  const theme = useTheme();
  const id = React.useContext(MapContext);
  const [value, setValue] = useState(0);

  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const READ_ONLY = USER_PERMISSIONS.includes(PERMISSIONS.map_view_risk);
  const { data: riskApproval } = useGetRiskMapApprovalsQuery(id ?? "", {
    skip: id === undefined,
  });

  const isMapApproved = riskApproval?.approval_status === "Approved";
  const isMapRejected = riskApproval?.approval_status === "Rejected";

  const isFieldDisabled = READ_ONLY || isMapApproved || isMapRejected;

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { data: accountComments } = useGetAccountCommentsQuery(
    { map_name: id ?? "", account_type: accountType },
    { skip: !id }
  );
  const [editComments] = useEditAccountCommentsMutation();

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 150,
      width: "100%",
      backgroundColor: theme.palette.error.main,
    },
  });
  interface StyledTabProps {
    label: string | React.ReactNode;
    disabled?: boolean;
  }

  const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
  ))(({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    "&.Mui-selected": {
      color: theme.palette.error.main,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  }));
  const {
    data: callSchedule,
    isLoading: isLoadingCallSchedule,
    refetch,
  } = useGetCallScheduleQuery(
    { map_id: id ?? "", account_type: accountType },
    { skip: !id }
  );

  const { data: mapData } = useGetMapSummaryQuery(id ?? "", { skip: !id });
  const currentMapVersion = React.useContext(MapCurrentVersion);

  const { refetch: refetchTemplate } = useGetTemplatesV2Query(
    {
      party: accountType as "supplier" | "buyer",
      use_case: "background",
      ipl_product: mapData?.ipl_product_id ?? "",
      map_version: currentMapVersion,
    },
    {
      skip: !mapData?.ipl_product_id,
    }
  );
  const { refetch: refetchValues } = useGetMapRiskParametersQuery(
    {
      map_name: id ?? "",
      account_type: accountType as "supplier" | "buyer",
      item_type: "financial",
    },
    { skip: !id }
  );
  const { refetch: refetchFinancialsValues } = useGetTemplatesV2Query(
    {
      party: accountType?.toLowerCase() as MAP_PARTY,
      use_case: "financial",
      ipl_product: mapData?.ipl_product_id ?? "",
      map_version: currentMapVersion,
    },
    {
      skip: !mapData?.ipl_product_id,
    }
  );
  const refetchFinancials = () => {
    refetchValues();
    refetchFinancialsValues();
  };

  const showFinancials = (category: string, account_type: string) => {
    switch (category) {
      case INCOMLEND_PRODUCTS?.standard_factoring:
        return true;
      case INCOMLEND_PRODUCTS?.standard_reverse_factoring:
        if (account_type?.toLowerCase() === SUPPLIER?.toLowerCase())
          return false;
        return true;
      case INCOMLEND_PRODUCTS?.enhanced_factoring:
        if (account_type?.toLowerCase() === SUPPLIER?.toLowerCase())
          return true;
        return false;
      case INCOMLEND_PRODUCTS?.big_buyer:
        return true;
      default:
        return false;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...defaultClientCallValues,
        ...callSchedule,
        mode_of_call: "Teams Call",
        financialNotes: accountComments?.financial_comments ?? "",
        background_comments: accountComments?.background_comments ?? "",
        productType: mapData?.score_card_product ?? "",
      }}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        return (
          <Form>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="calls-tabs"
                  variant="fullWidth"
                  centered
                >
                  <StyledTab
                    label={
                      <Stack
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography>Details</Typography>
                        <IconButton onClick={() => refetch()}>
                          <SyncIcon />
                        </IconButton>
                      </Stack>
                    }
                    {...a11yProps(0)}
                  />

                  {mapData && (
                    <StyledTab
                      label={
                        <Stack>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography>Financials </Typography>
                            {showFinancials(
                              mapData?.ipl_product_id,
                              accountType
                            ) && (
                              <IconButton onClick={() => refetchFinancials()}>
                                <SyncIcon />
                              </IconButton>
                            )}
                          </Stack>
                          {!showFinancials(
                            mapData?.ipl_product_id,
                            accountType
                          ) && (
                            <FormHelperText error>
                              {mapData?.ipl_product_id
                                ? "Financials are not available for this product category"
                                : "Please select a product category to view financials"}
                            </FormHelperText>
                          )}
                        </Stack>
                      }
                      {...a11yProps(1)}
                      disabled={
                        !showFinancials(mapData?.ipl_product_id, accountType) ||
                        !mapData?.ipl_product_id
                      }
                    />
                  )}
                </StyledTabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid item xs={12} margin="auto">
                  <Grid
                    container
                    spacing={{
                      xs: 1,
                      lg: 2,
                    }}
                    justifyContent="space-between"
                  >
                    {isLoadingCallSchedule && <SkeletonLoad bars={10} />}
                    {callSchedule && !isLoadingCallSchedule && (
                      <CallDetails
                        accountType={accountType}
                        disabled={isFieldDisabled}
                      />
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.3em",
                          }}
                        >
                          {`About ${mapData?.[`${accountType === "supplier" ? "seller" : accountType}_name`] as "supplier" | "buyer"}`}
                        </Typography>
                        <IconButton onClick={refetchTemplate}>
                          <SyncIcon />
                        </IconButton>
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <CompanyBackground
                        accountType={accountType}
                        disabled={isFieldDisabled}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1em",
                          p: 2,
                          bgcolor: theme.palette.primary.main,
                          borderRadius: 1,
                          color: theme.palette.background.default,
                        }}
                      >
                        Additional Comments
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <VariableInput
                        labelClassName={styles.labelClass}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "15vh",
                            backgroundClip: "border-box",
                            borderRadius: "10px",
                            textarea: {
                              height: "13vh !important",
                              overflow: "scroll !important",
                            },
                          },
                        }}
                        richText
                        name="background_comments"
                        placeholder="Enter your observations here..."
                        label=""
                        disabled={isFieldDisabled}
                        noHover={isFieldDisabled}
                        className={styles.formInput}
                        value={formikProps.values.background_comments ?? ""}
                        multiline
                        style={{ width: "100%" }}
                        handleSave={(value) => {
                          id &&
                            editComments({
                              map_name: id,
                              account_type: accountType,
                              data: { background_comments: value as string },
                            })
                              .unwrap()
                              .then(() => toast.success("Comments Updated"))
                              .catch((err) =>
                                toast.error(`Error: ${err?.message}`)
                              );
                        }}
                        {...formikProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  <Financials
                    accountType={accountType}
                    disabled={isFieldDisabled}
                  />
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "1em",
                        p: 2,
                        bgcolor: theme.palette.primary.main,
                        borderRadius: 1,
                        color: theme.palette.background.default,
                      }}
                    >
                      Notes on Financials 
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <RiskDocUpload accountType={accountType} />
                  </Grid>
                  <Grid item xs={12}>
                    <VariableInput
                      richText
                      labelClassName={styles.labelClass}
                      sx={{
                        ".MuiInputBase-root": {
                          height: "15vh",
                          backgroundClip: "border-box",
                          borderRadius: "10px",
                          textarea: {
                            height: "13vh !important",
                            overflow: "scroll !important",
                          },
                        },
                      }}
                      name="financialNotes"
                      placeholder="Enter your observations here..."
                      label=""
                      className={styles.formInput}
                      value={formikProps.values.financialNotes ?? ""}
                      multiline
                      style={{ width: "100%" }}
                      disabled={isFieldDisabled}
                      noHover={isFieldDisabled}
                      handleSave={(value) => {
                        id &&
                          editComments({
                            map_name: id,
                            account_type: accountType,
                            data: { financial_comments: value as string },
                          })
                            .unwrap()
                            .then(() => toast.success("Comments Updated"))
                            .catch((err) =>
                              toast.error(`Error: ${err?.message}`)
                            );
                      }}
                      {...formikProps}
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ClientCall;

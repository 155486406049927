import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";

import { useGetComplianceTemplatesQuery } from "api/api-compliance";
import { useGetEvaluationValuesQuery } from "api/api-maps";

import MainCard from "components/Common/Cards/MainCard";
import DocView from "components/Common/DocView";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Formik } from "formik";
import { MapContext } from "pages/maps/map";
import React, { useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Item } from "components/Map/Sections/Compliance/CreditReport/types";
import {
  useAddRiskDocumentMutation,
  useDeleteDocumentMutation,
  useGetRiskDocumentsQuery,
  useGetRiskDocumentUrlQuery,
} from "api/api-risk";
import RiskDocumetnsUpload from "components/Documents/RiskDocumentsUploads/RiskDocumetnsUpload";

const RiskDocUpload = ({ accountType }: { accountType: string }) => {
  const mapName = React.useContext(MapContext);
  const { data: evalutationTemplateValues } =
    useGetComplianceTemplatesQuery("evaluation");

  const labels = useMemo(
    () => Object.values(evalutationTemplateValues ?? {}),
    [evalutationTemplateValues]
  );

  const [addDocument, { isLoading: isLoadingSubmission }] =
    useAddRiskDocumentMutation();

  const [deleteDocument] = useDeleteDocumentMutation();

  const { data: evaluationValues, isLoading } = useGetEvaluationValuesQuery(
    { map_name: mapName ?? "", account_type: accountType },
    { skip: !Boolean(mapName) }
  );
  const mapId = useContext(MapContext);

  const {
    data: eddDocuments,
    isLoading: isEddLoading,
    refetch: refetchDocuments,
  } = useGetRiskDocumentsQuery(
    { account_type: accountType, mapId: mapId ?? "" },
    {
      skip: !Boolean(mapId),
    }
  );

  const to_upload: Item[] =
    eddDocuments && eddDocuments.length > 0
      ? eddDocuments.map((doc) => ({
          id: doc.id,
          name: doc.name,
          label: "Risk Document Upload",
          value: "",
          description: "Risk Document Upload",
          items: [],
          yearly_set_count: 1,
          count_unique_uploaded:
            eddDocuments?.filter((d) => d.document_type === doc.document_type)
              .length ?? 0, // Fix the filtering condition
        }))
      : [
          {
            name: "Risk Document Upload",
            label: "Risk Document Upload",
            value: "Risk Document Upload",
            items: [],
            id: "",
            description: "",
            yearly_set_count: 1,
            count_unique_uploaded: 0,
          },
        ];

  const [document_id, setId] = useState<string>();

  const { data: documentUrl, refetch: refetchDocUrl } =
    useGetRiskDocumentUrlQuery(
      {
        document_id: document_id ?? "",
        mapId: mapId ?? "",
      },
      { skip: !Boolean(document_id) }
    );
  const [show, setShow] = useState<boolean>(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);
  const fetchDocument = (id: string) => {
    setId(id);
    refetchDocUrl();
    onOpen();
  };

  const handleSubmitContactDocument = async (data: {
    file: File;
    year: number | null;
  }) => {
    addDocument({
      file: data.file,
      mapId: mapId ?? "",
      accountType: accountType,
    })
      .unwrap()
      .then((response: { name: any }) => {
        toast(`Uploaded ${response.name} successfully}!`, { type: "success" });
      })
      .catch((error: any) => {
        toast(`Failed to upload document: ${error.message}`, { type: "error" });
      });
  };

  const handleDeleteDocument = async (id: string, mapId: string) => {
    deleteDocument({
      mapId: mapId ?? "",
      document_id: id,
    })
      .unwrap()
      .then(() => {
        refetchDocuments();
        toast("Document deleted successfully!", { type: "success" });
      })
      .catch((error: any) => {
        toast(`Failed to delete document: ${error.message}`, { type: "error" });
      });
  };

  if (isLoading || !evaluationValues) {
    return (
      <Box
        flexGrow={1}
        p={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box flexGrow={1} p={1}>
      <Stack spacing={2}>
        <MainCard
          title={
            <Typography variant="body2" gutterBottom textAlign="center">
              Risk Documents Upload
            </Typography>
          }
        >
          <Stack spacing={1} p={1}>
            {isEddLoading && <SkeletonLoad bars={4} />}
            <Formik initialValues={{}} enableReinitialize onSubmit={() => {}}>
              {(formikProps) => {
                return (
                  <>
                    <RiskDocumetnsUpload
                      data={to_upload}
                      formikProps={formikProps}
                      initialData={eddDocuments ?? []}
                      onUpload={(file) => handleSubmitContactDocument(file)}
                      onView={(fileName, id) => fetchDocument(id)}
                      onDelete={(fileName, id) =>
                        handleDeleteDocument(id, mapId ?? "")
                      }
                      isLoadingSubmission={isLoadingSubmission}
                      isLoading={isLoading}
                      enableCount={false}
                    />
                    <Modal
                      modalOverlaySx={{ borderRadius: "2ch" }}
                      width="30%"
                      height="100%"
                      closeButton
                      message={
                        <Grid item xs={12}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            {documentUrl?.url?.includes(".pdf?") ? (
                              <Grid item xs={12} id="modal-docview">
                                <DocView uri={documentUrl?.url} staticView />
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <img
                                  src={documentUrl?.url}
                                  alt={documentUrl?.url}
                                  width="100%"
                                  height="auto"
                                  style={{
                                    borderRadius: "2ch",
                                    margin: "auto",
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      }
                      open={show}
                      onClose={onClose}
                    />
                  </>
                );
              }}
            </Formik>
          </Stack>
        </MainCard>
      </Stack>
    </Box>
  );
};

export default RiskDocUpload;

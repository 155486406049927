import { Sync } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useEditMapSummaryMutation,
  useGetMapInsurersQuery,
  useGetMapSummaryQuery,
  usePatchMapInsurerMutation,
} from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import Input from "components/Common/Input";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import valueCleaner from "helpers/valueCleaner";
import { MapContext } from "pages/maps/map";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { mapSummaryDefaults } from "../defaults";
import styles from "../maps.module.scss";
import { ADVANCE_RATIOS, PAYMENT_TERMS } from "./constants";

const ScoreCardFooter: React.FC<{ disabled: boolean, parentName: string }> = ({ disabled, parentName}) => {
  const theme = useTheme();

  const id = useContext(MapContext);
  const { data: mapData } = useGetMapSummaryQuery(id ?? "", { skip: !id });
  const { data: insurers, refetch } = useGetMapInsurersQuery(id ?? "", {
    skip: !id,
  });
  const [editMapSummary] = useEditMapSummaryMutation();
  const [patchMapInsurer] = usePatchMapInsurerMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    id &&
      editMapSummary({
        map_name: id,
        data: { [field]: value },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };

  const patcher = (amount: number, friendlyLabel: string) => {
    id &&
      insurers?.id &&
      patchMapInsurer({
        credit_limit_id: insurers?.id,
        data: {
          credit_committee_amount: amount,
        },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };

  return (
    <Formik
      initialValues={{
        ...mapSummaryDefaults,
        ...mapData,
        limitRequested: currencyFormatter({
          amount: insurers?.requested_amount ?? 0,
        }),
        limitApproved: currencyFormatter({
          amount:
            (insurers?.credit_committee_amount
              ? insurers?.credit_committee_amount
              : insurers?.credit_limit_request_internal_amount) ?? 0,
        }),
        risk_docs: "",
      }}
      onSubmit={console.log}
    >
      {(formikProps) => (
        <Form>
          <MainCard
            title={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography fontWeight="bold">Risk Assessment</Typography>
                <IconButton onClick={refetch}>
                  <Sync />
                </IconButton>
              </Stack>
            }
            content
            contentSX={{ py: 2, px: 3 }}
            headerSx={{ py: 2, px: 3 }}
            children={
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} lg={6}>
                  <Input
                    name="limitRequested"
                    label="Limit Requested"
                    labelClassName={styles.labelClass}
                    textfieldProps={{
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">USD</InputAdornment>
                        ),
                      },
                    }}
                    type="money"
                    disabled
                    fullWidth
                    style={{ width: "100%" }}
                    {...formikProps}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <VariableInput
                    name="limitApproved"
                    label="Limit Approved"
                    type="money"
                    value={valueCleaner(`${formikProps.values.limitApproved}`)}
                    textfieldProps={{
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">USD</InputAdornment>
                        ),
                      },
                    }}
                    handleSave={(value) => {
                      patcher(valueCleaner(`${value}`), "Limit Approved");
                    }}
                    labelClassName={styles.labelClass}
                    fullWidth
                    style={{ width: "100%" }}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <VariableDropdown
                    label="Advance Ratio Approved"
                    name="advance_ratio_approved"
                    keyValue="name"
                    data={ADVANCE_RATIOS}
                    placeholder="Select Advance Ratio"
                    value={
                      ADVANCE_RATIOS.map((ratio) => ratio.name).includes(
                        Number(formikProps.values.advance_ratio_approved)
                      )
                        ? `${formikProps.values.advance_ratio_approved}`
                        : undefined
                    }
                    fullWidth
                    disabled={disabled}
                    handleSave={(value) => {
                      formikProps.setFieldValue(
                        "advance_ratio_approved",
                        Number(value)
                      );
                      editor(
                        "advance_ratio_approved",
                        Number(value),
                        "Advance Ratio Approved"
                      );
                    }}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <VariableDropdown
                    label="Payment Terms Approved"
                    name="payment_terms_approved"
                    placeholder="Select Payment Terms"
                    keyValue="name"
                    value={
                      PAYMENT_TERMS.map((term) => term.name).includes(
                        formikProps.values.payment_terms_approved
                      )
                        ? formikProps.values.payment_terms_approved
                        : undefined
                    }
                    data={PAYMENT_TERMS}
                    disabled={disabled}
                    handleSave={(value) => {
                      formikProps.setFieldValue(
                        "payment_terms_approved",
                        value as string
                      );
                      editor("payment_terms_approved", value, "Payment Terms");
                    }}
                    fullWidth
                    {...formikProps}
                  />
                </Grid>
              </Grid>
            }
            footer={
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    name="score_card_comments"
                    label="Risk Comments"
                    value={formikProps.values.score_card_comments}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "score_card_comments",
                        e.target.value
                      );
                    }}
                    onBlur={() => {
                      editor(
                        "score_card_comments",
                        formikProps.values.score_card_comments,
                        "Risk Comments"
                      );
                    }}
                    placeholder="Use Markdown format: 
                                  # Header 
                                  ## Subheader 
                                  - Bullet point 
                                  1. Numbered list"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    disabled={disabled}
                    InputProps={{
                      className: styles.labelClass,
                      readOnly: disabled,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <VariableInput
                    name="risk_recommendation"
                    label="Risk Recommendation"
                    labelClassName={styles.labelClass}
                    value={formikProps.values.risk_recommendation}
                    placeholder="Enter risk recommendation"
                    handleSave={(value) => {
                      editor(
                        "risk_recommendation",
                        value,
                        "Risk Recommendation"
                      );
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    disabled={disabled}
                    noHover={disabled}
                    {...formikProps}
                  />
                </Grid>

                <Grid item xs={12} id="idenfy-divider">
                  <Divider sx={{ mb: 2 }} />
                  {/* <FileUpload mapId={id ?? ""} parentName={parentName} formikProps={formikProps} disabled={disabled} /> */}
                  {/* <VariableInput
                    name="risk_docs"
                    label="Risk documnents"
                    labelClassName={styles.labelClass}
                    value={formikProps.values.risk_docs}
                    placeholder="Enter risk documents"
                    handleSave={(value) => {
                      editor(
                        "risk_docs",
                        value,
                        "Risk documnents"
                      );
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    disabled={disabled}
                    noHover={disabled}
                    {...formikProps}
                  /> */}
                </Grid>
              </Grid>
            }
            footerSx={{ py: 2, px: 3 }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ScoreCardFooter;

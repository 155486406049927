import { Grid, Stack } from "@mui/material";
import { useDeleteDocMutation } from "api/api-accounts";
import { useGetScreeningDocumentUrlQuery } from "api/api-compliance";

import DocView from "components/Common/DocView";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Modal from "components/Common/Modal";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import { MapContext } from "pages/maps/map";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import type { CompanyDoc } from "types";

interface DocumentProofProps {
  id: string;
  label: string;
  description: string;
  documents: CompanyDoc[];
  submit: any;
  accountType: string;
  isLocked: boolean;
}

interface Item {
  id: string;
  name: string;
  label: string;
  value: string;
  description: string;
  items: Item[];
  template?: string;
  yearly_set_count: number;
  count_unique_uploaded: number;
}

const DocumentProof: React.FC<DocumentProofProps> = (props) => {
  const mapName = useContext(MapContext);
  const { id, label, description, documents, submit, accountType, isLocked } =
    props;

  const initialValues =
    documents
      ?.map((item) => item.name)
      ?.reduce((a, item) => {
        return Object.assign(a, {
          [toCamelCase(item)]: null,
        });
      }, {}) ?? {};

  const items: Item[] = [];
  if (documents && items.length === 0) {
    items.push({
      id: id,
      name: documents?.[0]?.name ?? "",
      label: label,
      value: "",
      description: description,
      items: [],
      yearly_set_count: 1,
      count_unique_uploaded: documents?.[0]?.name !== undefined ? 1 : 0,
    });
  }

  const [show, setShow] = useState<boolean>(false);

  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  const [documentId, setDocumentId] = useState<string>("");

  const { data: screeningDocuments } = useGetScreeningDocumentUrlQuery(
    {
      map_name: mapName ?? "",
      account_type: accountType as "supplier" | "buyer",
      document_id: documentId,
    },
    { skip: !Boolean(documentId) }
  );

  const [deleteDoc] = useDeleteDocMutation();

  const fetchDocument = async (id: string) => {
    setDocumentId(id);
    onOpen();
  };

  const handleSubmit = (values: any) => {
    submit(values.file);
  };

  const validateForm = (values: any) => {
    const errors: any = {};

    return errors;
  };

  const deleteDocument = (_id: string) => {
    deleteDoc({
      account_id: id,
      id: _id,
    })
      .unwrap()
      .then(() => {
        toast(`Document deleted successfully!`, { type: "success" });
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong: ${errorFile}`, {
          type: "error",
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validate={validateForm}
    >
      {(formikProps) => (
        <Form>
          <Stack direction="column">
            <LabelViewOnly label="Screening Proof" />
            <DocumentUploadController
              data={items}
              enableCount={false}
              formikProps={formikProps}
              initialData={documents ?? []}
              onUpload={handleSubmit}
              onView={(fileName: string, id: string, idx: number) => {
                fetchDocument(id);
              }}
              onDelete={(fileName: string, _id: string, idx: number) => {
                deleteDocument(_id);
              }}
              sx={{ minHeight: "10vh" }}
              containerSx={{ mt: 0 }}
              disabled={isLocked}
              deleteDisabled={isLocked}
            />
            <Modal
              modalOverlaySx={{ borderRadius: "2ch" }}
              width={screeningDocuments?.url?.includes(".pdf?") ? "60%" : "30%"}
              height={screeningDocuments?.url?.includes(".pdf?") ? "80vh" : "auto"}
              closeButton
              message={
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    {screeningDocuments?.url?.includes(".pdf?") ? (
                      <Grid item xs={12} id="modal-docview" style={{ height: "100%" }}>
                        <DocView uri={screeningDocuments?.url} staticView />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <img
                          src={screeningDocuments?.url ?? ""}
                          alt={screeningDocuments?.url}
                          width="100%"
                          height="auto"
                          style={{
                            borderRadius: "2ch",
                            margin: "auto",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              }
              open={show}
              onClose={onClose}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default DocumentProof;

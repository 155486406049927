import { Grid, Stack, Typography } from "@mui/material";
import {
  useDeleteDocMutation,
  useGetDirectorDocumentsQuery,
  useGetDirectorRequiredDocumentsQuery,
  useGetDocumentUrlQuery,
} from "api/api-accounts";
import DocView from "components/Common/DocView";
import Modal from "components/Common/Modal";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Item } from "../../CreditReport/types";

interface IDVerificationDirectorProps {
  formikProps: FormikProps<any>;
  handleSubmitDirectorDocument: (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    contactIndex: number
  ) => void;
  director: any;
  index: number;
  accountId: string;
  isLocked: boolean;
}

const IDVerificationDirector: React.FC<IDVerificationDirectorProps> = ({
  formikProps,
  handleSubmitDirectorDocument,
  director,
  index,
  accountId,
  isLocked,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string>("");
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { data: documentUrl } = useGetDocumentUrlQuery(
    {
      account_id: director.director_id,
      document_id: documentId ?? "",
    },
    { skip: !Boolean(documentId) }
  );

  const [deleteDoc] = useDeleteDocMutation();

  const { data: requiredDocuments, isLoading: isLoadingRequiredDocuments } =
    useGetDirectorRequiredDocumentsQuery(accountId);

  const { data: documents, isLoading: isLoadingDocuments } =
    useGetDirectorDocumentsQuery({
      director_id: director.director_id,
      account_id: accountId,
    });

  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  const fetchDocument = async (id: string) => {
    setDocumentId(id);
    onOpen();
  };

  const deleteDocument = (director_id: string, id: string) => {
    deleteDoc({
      account_id: director_id,
      id: id,
    })
      .unwrap()
      .then(() => {
        toast(`Document deleted successfully!`, { type: "success" });
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong: ${errorFile}`, {
          type: "error",
        });
      });
  };

  const idItems: Item[] = (requiredDocuments ?? [])
    ?.map((doc) => {
      if (doc.display_name.includes("ID")) {
        return {
          id: doc.id,
          name: doc.display_name,
          label: `Upload ID for ${director?.name}`,
          value: "",
          description: `${director?.name} ID Verification`,
          items: [],
          yearly_set_count: 1,
          count_unique_uploaded:
            documents?.filter((d) => d.document_type === doc.id).length ?? 0,
        } as Item;
      }
      return null;
    })
    .filter((item): item is Item => item !== null);

  const addressItems: Item[] = (requiredDocuments ?? [])
    ?.map((doc) => {
      if (doc.display_name.includes("Address")) {
        return {
          id: doc.id,
          name: doc.display_name,
          label: `Upload Address for ${director?.name}`,
          value: "",
          description: `${director?.name} Address Verification`,
          items: [],
          yearly_set_count: 1,
          count_unique_uploaded:
            documents?.filter((d) => d.document_type === doc.id).length ?? 0,
        } as Item;
      }
      return null;
    })
    .filter((item) => item !== null);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        sx={{ mt: 3 }}
        key={director.director_id}
      >
        <Grid item xs={12}></Grid>
        <Grid item xs={12} sm={3}>
          <Stack direction="column">
            <Typography variant="h4">Director</Typography>
            <Typography variant="h5">{director?.name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Stack direction="row" spacing={2}>
            {documents && (
              <DocumentUploadController
                data={idItems}
                enableCount={false}
                formikProps={formikProps}
                initialData={documents}
                onUpload={(fileName, label, id) => {
                  handleSubmitDirectorDocument(
                    fileName,
                    "Id verification",
                    id,
                    index
                  );
                }}
                onView={(fileName, id, idx) => {
                  fetchDocument(id);
                }}
                onDelete={(fileName, id, idx) => {
                  deleteDocument(director.director_id, id);
                }}
                sx={{ minHeight: "10vh" }}
                containerSx={{ mt: 0 }}
                disabled={isLocked}
                deleteDisabled={isLocked}
              />
            )}
            {documents && (
              <DocumentUploadController
                data={addressItems}
                enableCount={false}
                formikProps={formikProps}
                initialData={documents}
                onUpload={(fileName, label, id) =>
                  handleSubmitDirectorDocument(
                    fileName,
                    "Proof of Address",
                    id,
                    index
                  )
                }
                onView={(fileName, id, idx) => {
                  fetchDocument(id);
                }}
                onDelete={(fileName, id, idx) => {
                  deleteDocument(director.director_id, id);
                }}
                sx={{ minHeight: "10vh" }}
                containerSx={{ mt: 0 }}
                disabled={isLocked}
                deleteDisabled={isLocked}
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      <Modal
        modalOverlaySx={{ borderRadius: "2ch" }}
        width={documentUrl?.url?.includes(".pdf?") ? "60%" : "30%"}
        height={documentUrl?.url?.includes(".pdf?") ? "80vh" : "auto"}
        closeButton
        message={
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              {documentUrl?.url?.includes(".pdf?") ? (
                <Grid item xs={12} id="modal-docview" style={{ height: "100%" }}>
                  <DocView uri={documentUrl?.url} staticView />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <img
                    src={documentUrl?.url ?? ""}
                    alt={documentUrl?.url}
                    width="100%"
                    height="auto"
                    style={{
                      borderRadius: "2ch",
                      margin: "auto",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        open={show}
        onClose={onClose}
      />
    </>
  );
};

export default IDVerificationDirector;

import { Avatar, Box, ButtonBase } from "@mui/material";
import { useColorScheme, useTheme } from "@mui/material/styles";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Dispatch, SetStateAction } from "react";
import { useAppSelector } from "redux/hooks";
import ColorModeSelector from "./ColorModeSelector";
import LogoSection from "./LogoSection";
import NotificationSection from "./NotificationSection";
import ProfileSection from "./ProfileSection";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({
  handleLeftDrawerToggle,
  drawerOpen,
  profileOpen,
  handleRightDrawerToggle,
}: {
  handleLeftDrawerToggle: () => void;
  drawerOpen: boolean;
  profileOpen: boolean;
  handleRightDrawerToggle: Dispatch<SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const { mode } = useColorScheme();
  const EXPERIMENTAL_FEATURES = useAppSelector(
    (state) => state.experimentalFeatures.value
  );
  const { VITE_EXPERIMENTAL_THEME_SWITCHER } = EXPERIMENTAL_FEATURES;

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection light={mode === "light"} />
        </Box>
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              transition: "all .2s ease-in-out",
              background: theme.palette.background.default,
              color: theme.palette.primary.main,
              "&:hover": {
                background: theme.palette.primary.main,
                color: theme.palette.background.default,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            {drawerOpen ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )}
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {VITE_EXPERIMENTAL_THEME_SWITCHER.value && <ColorModeSelector />}

      {/* notification & profile */}
      <NotificationSection />
      <ProfileSection handleRightDrawerToggle={handleRightDrawerToggle} />
    </>
  );
};

export default Header;

import { Add, SaveAlt } from "@mui/icons-material";
import { Box, Grid, Slide, Typography } from "@mui/material";
import { useGetLeadsQuery } from "api/api-accounts";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, LEADS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CreateLead from "components/Company/Lead/CreateLead";
import ImportLead from "components/Company/Lead/ImportLead";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import styles from "./leads.module.scss";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";

interface ILeadTable {
  internal_name: string;
  country: string;
  id: string;
  name: string;
  sales_owner: string;
  source: string;
  created_at: Date;
  profile: string;
}

const Leads = () => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const [createLead, setCreateLead] = useState<boolean>(false);
  const [importLead, setImportLead] = useState<boolean>(false);
  const searchValue = useAppSelector((state) => state.search.value);
  const [queryParams, setQueryParams] = useState({
    per_page: 10,
    page: 1,
    search: searchValue || undefined,
  });
  const [filters, setFilters] = useState<Record<string, any>>({});

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch]);

  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    if (searchValue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setQueryParams((prev) => ({ ...prev, page: 1, search: searchValue || undefined }));
    }
  }, [searchValue]);

  const {
    data: leads,
    isFetching,
    isLoading,
    startedTimeStamp,
    fulfilledTimeStamp,
    refetch,
  } = useGetLeadsQuery(
    { 
      leadsparams: `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(queryParams)
            .filter(([_, value]) => value !== undefined)
        ) as Record<string, string>
      )}` 
    },
    { refetchOnMountOrArgChange: true }
  );

  const openCreateLeadPage = () => setCreateLead(true);
  const closeCreateLeadPage = () => setCreateLead(false);
  const openImportLeadPage = () => setImportLead(true);
  const closeImportLeadPage = () => setImportLead(false);
  const isCreatorMode = createLead || importLead;

  const getLabel = (): string => {
    switch (true) {
      case createLead:
        return "Create a Lead";
      case importLead:
        return "Import Lead";
      default:
        return "Leads";
    }
  };

  const handleRefetch = async () => {
    await refetch();
    setQueryParams({
      per_page: 10,
      page: 1,
      search: undefined
    });
    setFilters({});
    dispatch(clearAll());
  };

  const formatLeadsForTable = (data: ILeadTable[] = []) => {
    return data.map((item) => ({
      internal_name: item.internal_name,
      country: item.country,
      id: item.id,
      name: item.name,
      sales_owner: item.sales_owner,
      source: item.source,
      created_at: item.created_at,
      profile: item.profile,
    }));
  };

  const handleFilterSubmit = (filtersString: string) => {
    const filtersObj = JSON.parse(filtersString);
    setFilters(filtersObj); // Persist filters
    const newFilters: Record<string, string> = {};
    for (const key in filtersObj) {
      if (filtersObj[key]?.keywords) {
        if (key === "created_at" && typeof filtersObj[key].keywords === "string") {
          newFilters[key] = filtersObj[key].keywords;
        } else if (Array.isArray(filtersObj[key].keywords)) {
          newFilters[key] = filtersObj[key].keywords.join(",");
        }
      }
    }
    setQueryParams((prev) => ({ ...prev, ...newFilters, page: 1 }));
  };

  const handlePageChange = (newPage: number, newPerPage: number) => {
    setQueryParams((prev) => ({
      ...prev,
      page: newPage,
      per_page: newPerPage
    }));
  };

  const formattedData = formatLeadsForTable(leads?.leads_details);
  const headerLength = Object.keys(formattedData?.[0] ?? {}).length;
  const defaultColumnWidth = width ? (width / headerLength) * 1 : 200;

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={getLabel()}
          subtitle={
            (import.meta.env.VITE_ENV === "local" ||
              import.meta.env.VITE_ENV === "dev") &&
            !isCreatorMode &&
            `Development mode only - Data fetched in: ${
              ((fulfilledTimeStamp ?? 0) - (startedTimeStamp ?? 0)) / 1000
            }s`
          }
          className={styles.layout}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 4,
            mid: 0,
            right: isCreatorMode ? 2 : 4,
            syncAction: isCreatorMode ? undefined : handleRefetch,
            xs: {
              left: isCreatorMode ? 7 : 6,
              mid: 0,
              right: isCreatorMode ? 5 : 6,
            },
            alignItems: "center",
          }}
          primary={
            !importLead
              ? {
                  onClick: createLead
                    ? closeCreateLeadPage
                    : openCreateLeadPage,
                  isCancel: createLead,
                  startIcon: createLead ? undefined : <Add />,
                  children: createLead ? "Cancel" : "Lead",
                  variant: "contained",
                  fullWidth: true,
                }
              : undefined
          }
          secondary={
            !createLead
              ? {
                  onClick: importLead
                    ? closeImportLeadPage
                    : openImportLeadPage,
                  fullWidth: true,
                  startIcon: importLead ? undefined : <SaveAlt />,
                  children: importLead ? "Cancel" : "Import Lead",
                  color: importLead ? "error" : "primary",
                  variant: importLead ? "contained" : "outlined",
                }
              : undefined
          }
          mode="default"
        >
          {createLead && (
            <CreateLead
              onEnd={(internal_name) => {
                closeCreateLeadPage();
                if (internal_name)
                  history.push(
                    `${INCOMLEND_INTERNAL_LINK}${LEADS}/${internal_name}`
                  );
              }}
            />
          )}
          {importLead && <ImportLead />}
          {!createLead && !importLead && (
            <Grid item xs={12} margin="5px">
              <Grid item xs={12} ref={ref}>
                {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
                {!isFetching && !isLoading && (
                  <>
                    {formattedData && formattedData.length > 0 ? (
                      <DataTablev2
                        filterColumnTableName={leads}
                        pagination={leads?.pagination}
                        data={formattedData}
                        defaultColumnWidth={defaultColumnWidth}
                        rowsPerPage={rowsPerPage}
                        onPaginationChange={handlePageChange}
                        useKey="internal_name"
                        onFilterSubmit={handleFilterSubmit}
                        initialFilters={{}}
                        filters={filters}
                        setFilters={setFilters}
                        customColumns={{
                          country: {
                            country: "Country",
                            type: "text",
                            minWidth: 120,
                          },
                          internal_name: {
                            internal_name: "Lead ID",
                            type: "text",
                            minWidth: 100,
                            link: {
                              href: (params: ILeadTable) =>
                                `${INCOMLEND_INTERNAL_LINK}/leads/${params.internal_name}`,
                              target: "_self",
                            },
                          },
                          profile: {
                            profile: "Lead Type",
                            type: "text",
                            minWidth: 100,
                          },
                          sales_owner: {
                            sales_owner: "Sales Owner",
                            type: "text",
                            minWidth: 150,
                          },
                          source: {
                            source: "Source",
                            type: "text",
                            minWidth: 120,
                          },
                          name: { 
                            name: "Account", 
                            type: "text", 
                            minWidth: 170 
                          },
                          created_at: {
                            created_at: "Created At",
                            type: "date",
                            renderCell: ({ value }: { value: string }) => (
                              <Typography>
                                {value
                                  ? moment(value)
                                      .utc()
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : "-"}
                              </Typography>
                            ),
                          },
                        }}
                        sx={{
                          table: {
                            minWidth: "100% !important",
                          },
                          "& .MuiTableCell-root": {
                            fontSize: "0.75rem",
                          },
                        }}
                      />
                    ) : (
                      <NoData text="No Leads found" />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};

export default Leads;

import { useGetCreditLimitTimelineQuery } from "api/api-origination";
import MainCard from "components/Common/Cards/MainCard";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useFormikContext } from "formik";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartsTimeline from "highcharts/modules/timeline";
import React, { useRef } from "react";
import type { CLDetails } from "types";

const Timeline: React.FC = () => {
  const formikProps = useFormikContext<CLDetails>();
  const { values } = formikProps;
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  HighChartsTimeline(Highcharts);

  const {
    data: timelineData,
    isFetching,
    refetch: refetchTimeline,
  } = useGetCreditLimitTimelineQuery(values.reqId, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const LABEL_STYLE: Highcharts.TimelineDataLabelsOptionsObject = {
    connectorWidth: 3,
    style: {
      textOutline: "0",
    },
    verticalAlign: "bottom",
    alternate: false,
  };

  const series: Highcharts.SeriesTimelineOptions[] = [
    {
      type: "timeline",
      data: (timelineData ?? [])?.map((item, i) => ({
        ...item,
        dataLabels: LABEL_STYLE,
      })),
    },
  ];
  const options: Highcharts.Options = {
    chart: {
      type: "timeline",
      height: "auto",
    },
    accessibility: {
      screenReaderSection: {
        beforeChartFormat:
          "<h5>{chartTitle}</h5>" +
          "<div>{typeDescription}</div>" +
          "<div>{chartSubtitle}</div>" +
          "<div>{chartLongdesc}</div>" +
          "<div>{viewTableButton}</div>",
      },
      point: {
        valueDescriptionFormat: "{index}. {point.label}. {point.description}.",
      },
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      max: 2,
    },
    title: {
      text: "Credit Limit Timeline",
    },
    credits: {
      enabled: false,
    },
    colors: ["green", "orange", "grey", "grey"],
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },

    series,
  };

  return (
    <MainCard
      title=" "
      headerSx={{
        px: 2,
        py: 0,
      }}
      contentSX={{
        p: 0,
        ":last-child": {
          p: 1,
        },
      }}
      headerConfig={{
        syncAction: refetchTimeline,
      }}
    >
      {isFetching && <SkeletonLoad bars={4} />}

      {!isFetching && Boolean(timelineData?.length) && (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          chartRef={chartComponentRef}
          containerProps={{
            style: { height: "100%" },
          }}
          allowChartUpdate
        />
      )}
    </MainCard>
  );
};

export default Timeline;

import { CallSchedule } from "api/api-risk";
import toCamelCase from "helpers/camelCase";
import type { MapSummary, RiskCategories, RiskCategory } from "types";

export interface MapGeneral {
  insurerBuyerRating: number;
  currency: string;
  previousCreditLimit: number;
  availableCreditLimit: number;
  creditLimitChangeDate: Date | undefined;
  insuredPercentageOfInvoiceFaceValue: number;
  uninsuredPercentageOfInvoiceFaceValue: number;
  insuranceStartDate: Date | undefined;
  riskComment: string;
  hasTopUpInsurance: boolean;
  hasSecondaryInsurance: boolean;
  totalCLbyInsurers: number;
  totalAvailableCreditLimit: number;
  firstFundingDate: Date | undefined;
  lastFundingDate: Date | undefined;
  noOfFundedInvoices: number;
  averageOverdueDays: number;
  expectedPaymentDays: string;
  advanceRatioApproved: number;
  seasonalBusiness: boolean;
  peakSeasonMonths: string[];
  securityDocuments: boolean;
  securityType: string;
  enhancedFactoringApproach: boolean;
  bigBuyerApproach: boolean;
  creditCommitteeConditions: string[];
  riskRestrictions: string[];
  freezeAccount: boolean;
  reasonForFreeze: string;
  freezeStartDate: Date | undefined;
  riskCommentOnFreeze: string;
  shareInVerification: boolean;
  maxOverdueOnFunded: number;
  totalFundedSinceInception: number;
  maxLimitUtilization: number;
  avgLimitUtilpa: number;
  highCLUtilMonths: string;
}
interface CategoryObject {
  [category: string]: { description: string; score: number };
}

export const defaultValues: MapGeneral = {
  insurerBuyerRating: 1,
  currency: "USD",
  previousCreditLimit: 1000,
  availableCreditLimit: 1000,
  creditLimitChangeDate: undefined,
  insuredPercentageOfInvoiceFaceValue: 0,
  uninsuredPercentageOfInvoiceFaceValue: 0,
  insuranceStartDate: undefined,
  riskComment: "",
  hasTopUpInsurance: false,
  hasSecondaryInsurance: false,
  totalCLbyInsurers: 1000,
  totalAvailableCreditLimit: 1000,
  firstFundingDate: undefined,
  lastFundingDate: undefined,
  noOfFundedInvoices: 100,
  averageOverdueDays: 10,
  expectedPaymentDays: "Weekly",
  advanceRatioApproved: 60,
  seasonalBusiness: false,
  peakSeasonMonths: [],
  securityDocuments: false,
  securityType: "",
  enhancedFactoringApproach: false,
  bigBuyerApproach: false,
  creditCommitteeConditions: [],
  riskRestrictions: [],
  freezeAccount: false,
  reasonForFreeze: "",
  freezeStartDate: undefined,
  riskCommentOnFreeze: "",
  shareInVerification: true,
  maxOverdueOnFunded: 0,
  totalFundedSinceInception: 0,
  maxLimitUtilization: 0,
  avgLimitUtilpa: 0,
  highCLUtilMonths: "",
};

export const defaultClientCallValues: CallSchedule = {
  created_at: undefined,
  call_scheduled_date: undefined,
  call_implementation_date: undefined,
  ipl_attendees: [],
  interviewees: [],
  reference: "",
};
export interface IRiskScoring extends Record<string, any> {
  dateOfScoring: Date | undefined;
  iplProduct: string;
  previousMonitoringDate: Date | undefined;
  typeOfScoring: string;
  riskComments: string;
  committeeDecision: string;
  limitRequested: string;
  limitApproved: string;
  advanceRatioApproved: string;
  paymentTermsApproved: string;
  buyer: Record<string, any>;
  supplier: Record<string, any>;
  tradingRelationship: Record<string, any>;
  adjustment: Record<string, any>;
  override: Record<string, any>;
  buyers_assessment_weight: number;
  suppliers_assessment_weight: number;
  trading_relationship_weight: number;
}
export const mapSummaryDefaults: MapSummary = {
  map_name: "",
  product: "",
  buyer_name: "",
  buyer_id: "",
  buyer_internal_name: "",
  seller_name: "",
  seller_id: "",
  seller_internal_name: "",
  map_approved: false,
  payment_terms_approved: "",
  approved_payment_days: 0,
  expected_payment_days: "",
  day: "",
  advance_ratio_approved: 60,
  seasonal_business: [],
  enhanced_factoring: false,
  big_buyer: false,
  credit_committee_conditions: [],
  risk_restrictions: [],
  security_required: false,
  security_type: "",
  freezed: false,
  freeze_reason: "",
  freeze_start_date: undefined,
  freeze_comment: "",
  shared_in_invoice: false,
  next_monitoring_date: undefined,
  map_documents: {
    buyer_documents: [],
    supplier_documents: [],
    trade_documents: [],
  },
  map_insurers: {
    approved_amount: 0,
    atradius_amount: 0,
    atradius_comment: "",
    comment: "",
    id: "",
    requested_amount: 0,
    requested_date: "",
    status: "",
    internal_status: "",
    sales_lead: {
      email: "",
      first_name: "",
      id: "",
      last_name: "",
      mobile: "",
      opted_marketing_updates: false,
      title: "",
    },
    creator: "",
    send_message_to: {
      contact_description: "",
      email: "",
      name: "",
    },
  },
  buyer_country_iso3: "",
  seller_country_iso3: "",
  score_card_product: "",
  score_card_type: "",
  previous_monitoring_date: null,
  score_card_comments: "",
  risk_recommendation: "",
  credit_insurer: "",
  score_card_date: null,
  transaction_comments: "",
  transaction_extra_info: "",
  approvers: {},
  assignment_notice_signed_by_buyer: false,
  assignment_restriction: [],
  credit_note_clause_in_buyer_confirmation: "",
  set_off_clause_in_buyer_confirmation: "",
  legal_comments: "",
  legal_shared_in_invoice: false,
  governing_law: "",
  jurisdiction: "",
  dispute_resolution: "",
  ipl_product_id: "",
};
export const defaultRiskScoring: IRiskScoring = {
  dateOfScoring: new Date(),
  previousMonitoringDate: undefined,
  iplProduct: "",
  typeOfScoring: "",
  riskComments: "",
  committeeDecision: "Partially Approved",
  limitRequested: "3000",
  limitApproved: "1000",
  advanceRatioApproved: "90%",
  paymentTermsApproved: "90 days from Invoice date",
  buyer: {},
  supplier: {},
  tradingRelationship: {},
  adjustment: {},
  override: {},
  buyers_assessment_weight: 0.4,
  suppliers_assessment_weight: 0.2,
  trading_relationship_weight: 0.4,
};

export const average = (array: number[]): number =>
  array.length !== 0
    ? parseFloat((array.reduce((a, b) => a + b) / array.length).toFixed(1))
    : 0;

export const getWeightedAverage = (
  input: Array<Array<number>>,
  weightages: Record<string, number>
) => {
  return input?.reduce((p, c, idx) => {
    return (
      p +
      Object.values(weightages ?? {})[idx] * (average(Object.values(c)) ?? 0)
    );
  }, 0);
};
export function getScore(categoryType: CategoryObject): number[] {
  return (
    Object.values(categoryType ?? {})?.map((item) => item?.score ?? 0) ?? []
  );
}
export const sumArray = (array: number[]): number =>
  array.length !== 0 ? array.reduce((a, b) => a + b) : 0;
export const getFinalScore = ({
  buyer,
  supplier,
  tradingRelationship,
  adjustment,
  weightages,
}: {
  buyer: object;
  supplier: object;
  tradingRelationship: object;
  adjustment: object;
  weightages: Record<string, number>;
}) => {
  return (
    getWeightedAverage(
      [
        getScore(buyer as CategoryObject),
        getScore(supplier as CategoryObject),
        getScore(tradingRelationship as CategoryObject),
      ],
      weightages
    ) + sumArray(getScore(adjustment as CategoryObject))
  );
};

export function generateInitialValues(arr: RiskCategory[]) {
  return (
    arr?.reduce((a, item) => {
      return Object.assign(a, {
        [toCamelCase(item.item)]: {
          description:
            item.current_risk_selection_metadata?.current_risk_rating,
          score:
            item.current_risk_selection_metadata?.current_risk_rating_score,
        },
      });
    }, {}) ?? {}
  );
}
export const getScoringtableInitialValues = (categories?: RiskCategories) => ({
  buyer: categories ? generateInitialValues(categories?.buyers_assessment) : [],
  supplier: categories
    ? generateInitialValues(categories?.suppliers_assessment)
    : [],
  tradingRelationship: categories
    ? generateInitialValues(categories?.trading_relationship)
    : [],
  adjustment: categories ? generateInitialValues(categories?.adjustment) : [],
  override: categories
    ? generateInitialValues(categories?.overrides_to_high_risk)
    : [],
  buyers_assessment_weight: categories
    ? categories.buyers_assessment_weight * 0.01
    : 0.4,
  suppliers_assessment_weight: categories
    ? categories.suppliers_assessment_weight * 0.01
    : 0.2,
  trading_relationship_weight: categories
    ? categories.trading_relationship_weight * 0.01
    : 0.4,
});
interface IFinancials {
  revenue: string;
  grossProfit: string;
  gpMargin: string;
  netProfit: string;
  npMargin: string;
  ebitMargin: string;
  currentAssets: string;
  currentLiabilities: string;
  currentRatio: string;
  totalAssets: string;
  totalLiabilities: string;
  equity: string;
  totalDebt: string;
  de: string;
  cash: string;
  ccc: string;
}

export const financialsTemplate: IFinancials = {
  revenue: "",
  grossProfit: "",
  gpMargin: "",
  netProfit: "",
  npMargin: "",
  ebitMargin: "",
  currentAssets: "",
  currentLiabilities: "",
  currentRatio: "",
  totalAssets: "",
  totalLiabilities: "",
  equity: "",
  totalDebt: "",
  de: "",
  cash: "",
  ccc: "",
};

import { Box, Stack, useTheme } from "@mui/material";
import React, { Fragment } from "react";

interface CriteriaCardTemplateProps {
  children: JSX.Element;
}

const CriteriaCardTemplate: React.FC<CriteriaCardTemplateProps> = ({
  children,
}) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Stack
        sx={{
          borderTop: `20px solid ${theme.palette.primary.main}`, 
          borderBottom: `20px solid ${theme.palette.primary.main}`, 
          borderLeft: `20px solid ${theme.palette.error.main}`, 
          borderRight: `20px solid ${theme.palette.error.main}`, 
          boxShadow: "5px 6px 60px #bebebe, -15px -9px 60px #ffffff",
          borderRadius: "16px", 
          aspectRatio: "4/3",
          height: "297mm",
          width: "210mm",
          overflow: "hidden", 
        }}
        id="criteria-card"
      >
        
        <Stack
          direction="row"
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "16px", 
            }}
          >
            {children}
          </Box>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default CriteriaCardTemplate;

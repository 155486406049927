import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  useFetchMemberDocumentsQuery,
  useGetAccountQuery,
  useGetDocumentContentQuery,
  useGetDocumentUrlQuery,
} from "api/api-accounts";

import { Sync } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useDeleteUserAccessDocumentsMutation,
  useFetchUserAccessLevelsQuery,
  useGetUserAccessLevelsQuery,
  useGetUserAccessRequiredDocumentsQuery,
  useUpdateContactMutation,
  useUploadUserAccessDocumentsMutation,
} from "api/api-users-v2";
import DocView from "components/Common/DocView";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import Modal from "components/Common/Modal";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import { downloadDocument } from "helpers/downloadDocument";
import { useState } from "react";
import { toast } from "react-toastify";
import type { Contact, RequiredDoc } from "types";
import { validationSchema } from "./addUserFormFields";
import styles from "./user.module.scss";

interface Item {
  id: string;
  name: string;
  label: string;
  value: string;
  description: string;
  items: Item[];
  template?: string;
  yearly_set_count?: number;
  count_unique_uploaded: number;
}

const ViewEditUser = ({
  isPartner = false,
  id,
  user,
  company_name = "",
  callBackUrl,
}: {
  isPartner: boolean;
  id: string;
  user: Contact;
  company_name: string;
  callBackUrl?: string;
}) => {
  const PASSPORT = "ID/Passport of Authorised Signatories";
  const LOA = "Letter of Authorization";

  //View Modal
  const [document_id, setDocumentId] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);
  const { data: documentUrl } = useGetDocumentUrlQuery(
    {
      account_id: user?.id ?? "",
      document_id: document_id ?? "",
    },
    { skip: !Boolean(document_id) }
  );
  const { data: documentContent } = useGetDocumentContentQuery(
    {
      account_id: user?.id ?? "",
      document_id: document_id ?? "",
    },
    { skip: !Boolean(document_id) }
  );
  const [deleteUserAccessDocuments] = useDeleteUserAccessDocumentsMutation();

  const [updateUser, { isLoading: isUpdateCompanyUserLoading }] =
    useUpdateContactMutation();

  const { data: company } = useGetAccountQuery(id);
  const { data: docs, refetch } = useGetUserAccessRequiredDocumentsQuery(id, {
    skip: !Boolean(id),
  });
  const { data: user_access_levels } = useGetUserAccessLevelsQuery();
  const { data: access_levels = [] } = useFetchUserAccessLevelsQuery(
    {
      account_id: company?.id ?? "",
      contact_id: user?.id ?? "",
    },
    {
      skip: !Boolean(id),
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: userDocuments, refetch: refetchUserDocuments } =
    useFetchMemberDocumentsQuery(
      { id: user?.id ?? "", account_id: company?.id ?? "" },
      {
        skip: !Boolean(user) || !company?.id || !user?.id,
        refetchOnFocus: true,
      }
    );

  const [uploadUserAccessDocuments, { isLoading: isUploading }] =
    useUploadUserAccessDocumentsMutation();

  // This function shapes the 'questions' to be used in the DocumentUploadController
  const docDictionaryMaker = (
    role: string,
    permissions?: Record<string, string | boolean>[]
  ): Item[] => {
    const dataShaper = (data: RequiredDoc[]): Item[] =>
      data?.map((doc) => ({
        ...doc,
        name: toCamelCase(doc.display_name),
        label: doc.display_name,
        value: doc.display_name,
        items: dataShaper(doc.items),
        template: doc.template,
        yearly_set_count: doc.yearly_set_count,
        count_unique_uploaded: doc.count_unique_uploaded ?? 0,
      }));

    switch (role) {
      case "Director":
        return (
          dataShaper(
            docs?.filter((doc) => {
              return doc.display_name === PASSPORT;
            }) ?? []
          ) ?? []
        );
      case "Non-Director":
        if (
          (permissions &&
            permissions
              ?.map((permission) => Object.keys(permission)?.[0])
              ?.includes("authorized-signatory") === false) ||
          permissions?.[0]?.["authorized-signatory"] === false
        ) {
          return dataShaper(
            docs?.filter((doc) => {
              return doc.display_name === PASSPORT;
            }) ?? []
          );
        }
        return dataShaper(
          docs?.filter((doc) => {
            return doc.display_name === PASSPORT || doc.display_name === LOA;
          }) ?? []
        );
      default:
        return [];
    }
  };
  const fetchDocument = (id: string) => {
    setDocumentId(id);
    onOpen();
  };
  const refetchDocURLs = () => {
    refetch();
    refetchUserDocuments();
  };
  const permissions: { [key: string]: boolean; }[] | undefined = user_access_levels?.map((level) => ({
    [level.id]: access_levels?.includes(level.id),
  }));

  return (
    <Formik
      initialValues={{
        first_name: user?.first_name ?? "",
        last_name: user?.last_name ?? "",
        mobile: user?.mobile ?? "",
        title: user?.title ?? "",
        role: user?.director ? "Director" : "Non-Director",
        permissions,
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={console.log}
    >
      {(formikProps) => {
        return (
          <Form>
            <Grid
              container
              spacing={2}
              justifyContent="start"
              alignItems="center"
            >
              <Grid item xs={12} lg={6}>
                <Input
                  name="first_name"
                  label="First Name"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  name="last_name"
                  label="Last Name"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  name="title"
                  label="Job Title"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  name="mobile"
                  label="Phone Number"
                  type="phone"
                  fullWidth
                  required
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack>
                  <LabelViewOnly
                    label="Role in the company"
                    sx={{ fontWeight: "bolder" }}
                    required
                    {...formikProps}
                  />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="role"
                      name="role"
                      value={formikProps.values.role}
                      onChange={(e) => {
                        formikProps.setFieldValue("role", e.target.value);
                        if (e.target.value === "Director") {
                          //make all permissions true
                          formikProps.setFieldValue(
                            "permissions",
                            user_access_levels?.map((level) => ({
                              [level.id]: true,
                            }))
                          );
                        }
                      }}
                      sx={{ flexWrap: "nowrap" }}
                    >
                      <FormControlLabel
                        value="Non-Director"
                        control={<Radio />}
                        label="Non-Director"
                      />
                      <FormControlLabel
                        value="Director"
                        control={<Radio />}
                        label="Director"
                      />
                    </RadioGroup>
                  </FormControl>
                  {Boolean(formikProps.errors.role) && (
                    <FormHelperText error={Boolean(formikProps.errors.role)}>
                      {formikProps.errors.role}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack>
                  <LabelViewOnly
                    label="Permission(s) on Incomlend Platform"
                    sx={{ fontWeight: "bolder" }}
                    required
                    {...formikProps}
                  />
                  <FormGroup>
                    {user_access_levels?.map((access_level, index) => (
                      <FormControlLabel
                        name="permissions"
                        control={
                          <Checkbox
                            checked={!!(formikProps.values.permissions?.[index]?.[access_level.id])}
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                `permissions[${index}].[${access_level?.id}]`, e.target.checked
                              )
                            }}
                          />
                        }
                        label={access_level.name}
                        disabled={formikProps.values.role === "Director"}
                      />
                    ))}
                  </FormGroup>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={12}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textDecoration: "underline",
                      }}
                    >
                      List of Required Documents
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => refetchDocURLs()}>
                      <Sync />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    {userDocuments && (
                      <DocumentUploadController
                        data={docDictionaryMaker(
                          formikProps.values.role,
                          formikProps.values.permissions
                        )}
                        formikProps={formikProps}
                        initialData={userDocuments}
                        isLoadingSubmission={isUploading}
                        onUpload={(data, _, document_type) => {
                          const formData = new FormData();
                          formData.append("document_type_code", document_type);
                          formData.append("file", data?.file);
                          uploadUserAccessDocuments({
                            contact_id: user?.id ?? "",
                            data: formData,
                          })
                            .unwrap()
                            .then(() => {
                              refetchDocURLs();
                              toast.success("Document uploaded successfully");
                            })
                            .catch((error) => {
                              toast.error(
                                `Failed to upload document: ${error?.message}`
                              );
                            });
                        }}
                        onView={(_, id) => {
                          fetchDocument(id);
                        }}
                        enableCount={false}
                        onDelete={(_, id) => {
                          deleteUserAccessDocuments({
                            contact_id: user?.id ?? "",
                            document_id: id,
                          })
                            .unwrap()
                            .then(() => {
                              refetchDocURLs();
                              toast.success("Document deleted successfully");
                            })
                            .catch((error) => {
                              toast.error(
                                `Failed to delete document: ${error?.message}`
                              );
                            });
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  marginTop={2}
                >
                  <Grid item xs={12} lg={4}></Grid>
                  <Grid item xs={12} lg={3}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      loading={isUpdateCompanyUserLoading}
                      onClick={() => {
                        const updateUserPayload = {
                          account_id: company?.id ?? "",
                          contact_id: user?.id ?? "",
                          data: {
                            first_name: formikProps.values.first_name,
                            last_name: formikProps.values.last_name,
                            mobile: formikProps.values.mobile?.replace(
                              /\s/g,
                              ""
                            ),
                            title: formikProps.values.title,
                            director: formikProps.values.role === "Director",
                            permissions: formikProps.values.permissions?.reduce((prev, curr) => ({ ...prev, ...curr }), {})
                          },
                        };
                        updateUser(updateUserPayload)
                          .unwrap()
                          .then(() => {
                            toast.success("User updated successfully");
                          })
                          .catch((error) => {
                            toast.error(
                              `Failed to update user: ${error?.message}`
                            );
                          });
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Modal
              modalOverlaySx={{ borderRadius: "2ch" }}
              width={documentUrl?.url?.includes(".pdf?") ? "60%" : "30%"}
              height={documentUrl?.url?.includes(".pdf?") ? "80vh" : "auto"}
              closeButton
              message={
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    {documentUrl?.url?.includes(".pdf?") ? (
                      <Grid item xs={12} id="modal-docview" style={{ height: "100%" }}>
                        <DocView
                          uri={documentUrl?.url}
                          staticView
                          pageClassName={styles.pageClass}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <img
                          src={documentUrl?.url}
                          alt={documentUrl?.url}
                          width="100%"
                          height="auto"
                          style={{
                            borderRadius: "2ch",
                            margin: "auto",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              }
              open={show}
              primary={{
                children: "Download",
                onClick: () =>
                  document_id &&
                  documentContent &&
                  downloadDocument(documentContent),
              }}
              onClose={onClose}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default ViewEditUser;

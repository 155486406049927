import Close from "@mui/icons-material/Close";
import {
  Divider,
  Grid,
  type GridProps,
  IconButton,
  Modal,
  type ModalTypeMap,
  Typography,
  type TypographyProps,
} from "@mui/material";
import { type GridTypeMap, type SxProps } from "@mui/system";
import warningIcon from "assets/warning.svg";
import React from "react";

import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import styles from "./modal.module.scss";

export interface ModalProps extends Partial<GridTypeMap> {
  /**Content of the Modal */
  message: any;
  /**if `true`, Modal opens */
  open: boolean;
  /**Determine Modal closing behaviour */
  onClose: () => void;
  /**Primary Button Props */
  primary?: LoadingButtonProps;
  /**Secondary Button Props */
  secondary?: LoadingButtonProps;
  /**Tertiary Button Props */
  tertiary?: LoadingButtonProps;
  /**Modal Width */
  width?: React.CSSProperties["width"];
  /**Modal Height */
  height?: React.CSSProperties["height"];
  messageTextProps?: TypographyProps;
  /**Add a closeButton */
  closeButton?: boolean;
  /**Show a warning icon */
  warn?: boolean;
  /**Modal mui styling props */
  sx?: ModalTypeMap["props"]["sx"];
  /**Grid mui styling props */
  modalOverlaySx?: SxProps;
  modalHeaderSx?: SxProps;
  modalFooterSx?: SxProps;
  modalContentSx?: SxProps;
  Header?: React.ReactNode;
  messageContainerProps?: GridProps;
  modalClassName?: string;
  sectionDivider?: boolean;
  footer?: React.ReactNode;
}

const LCModal = (props: ModalProps) => {
  const {
    message,
    open,
    onClose,
    primary,
    secondary,
    tertiary,
    width = 500,
    height = 290,
    Header,
    footer,
    closeButton = false,
    warn = false,
    modalOverlaySx,
    modalFooterSx,
    modalHeaderSx,
    modalContentSx,
    messageTextProps,
    messageContainerProps,
    modalClassName = "",
    sx,
    sectionDivider = false,
    ...gridProps
  } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        ...sx,
        width,
        margin: "auto",
        display: "flex",
      }}
    >
      <Grid
        container
        className={`${styles.modal} ${modalClassName}`}
        {...gridProps}
        sx={{
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        <Grid
          item
          xs={12}
          className={styles.modalOverlay}
          style={{ width }}
          sx={modalOverlaySx}
        >
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#ffffff",
                position: "relative",
                ...modalHeaderSx,
              }}
            >
              <Grid
                container
                justifyContent={Header ? "space-between" : "end"}
                id="modal-header"
              >
                {Header && <Grid item>{Header}</Grid>}
                {closeButton && (
                  <Grid item sx={{ textAlign: "end" }}>
                    <IconButton
                      onClick={onClose}
                      sx={{
                        cursor: "pointer",
                        justifyContent: "flex-end",
                        padding: "1ch",
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {sectionDivider && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            <Grid
              item
              className={styles.modalContent}
              xs={12}
              id="modal-content"
              sx={modalContentSx}
            >
              {warn ? <img src={warningIcon} alt="Confirm" /> : <></>}

              <Grid
                container
                spacing={2}
                height={height}
                overflow="scroll"
                width="100%"
                {...messageContainerProps}
              >
                {typeof message !== "string" && Array.isArray(message) ? (
                  message?.map((item: any) => (
                    <Grid item xs={12} md={6} lg={6}>
                      {item}
                    </Grid>
                  ))
                ) : typeof message !== "string" ? (
                  message
                ) : (
                  <Grid item xs={12}>
                    <Typography {...messageTextProps}> {message} </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {sectionDivider && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            {/* Render Footer if provided */}
            {footer && (
              <Grid item xs={12}>
                {footer}
              </Grid>
            )}
            {(primary || secondary || tertiary) && (
              <Grid item xs={12}>
                <Grid
                  container
                  marginTop="1ch"
                  spacing={2}
                  alignItems="center"
                  padding="1ch"
                  sx={{
                    backgroundColor: "#ffffff",
                    position: "relative",
                    ...modalFooterSx,
                  }}
                >
                  {secondary && (
                    <Grid item xs={12} lg={3} margin="auto">
                      <LoadingButton
                        variant="outlined"
                        onClick={secondary.onClick}
                        fullWidth
                        data-testid={`${secondary.children}`}
                        {...secondary}
                      >
                        {secondary.children as string}
                      </LoadingButton>
                    </Grid>
                  )}
                  {tertiary && (
                    <Grid item xs={12} lg={3} margin="auto">
                      <LoadingButton
                        variant="outlined"
                        onClick={tertiary.onClick}
                        fullWidth
                        data-testid={`${tertiary.children}`}
                        {...tertiary}
                      >
                        {tertiary.children as string}
                      </LoadingButton>
                    </Grid>
                  )}
                  {primary && (
                    <Grid item xs={12} lg={3} margin="auto">
                      <LoadingButton
                        {...primary}
                        variant="contained"
                        fullWidth
                        data-testid={`${primary.children}`}
                      >
                        {primary.children as string}
                      </LoadingButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LCModal;

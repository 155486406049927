import { Divider, Grid, Link, Stack, Typography } from "@mui/material";
import { useGetDocumentUrlQuery } from "api/api-accounts";
import { useGetChecklistQuery } from "api/api-compliance";
import { useGetMapSummaryQuery } from "api/api-maps";
import { ACCOUNTS, BUYER, INCOMLEND_INTERNAL_LINK, SUPPLIER } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import DocView from "components/Common/DocView";
import Modal from "components/Common/Modal";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import useAccountData from "hooks/useAccountData";
import { MapContext } from "pages/maps/map";
import { useContext, useState } from "react";
import type { CompanyDoc } from "types";
import { ScreeningStatus } from "../types";
import IncomlendInternalComplianceRequirements from "./IncomlendInternalComplianceRequirements";

interface ComplianceScoringChecklistProps {
  accountType: string;
  isLocked: boolean;
  screeningStatusData: ScreeningStatus;
  screeningStatusLoading: boolean;
}

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}

interface Item {
  id: string;
  name: string;
  label: string;
  value: string;
  description: string;
  items: Item[];
  template?: string;
  yearly_set_count: number;
  count_unique_uploaded: number;
}

const ComplianceScoringChecklist: React.FC<ComplianceScoringChecklistProps> = ({
  accountType,
  isLocked,
  screeningStatusData,
  screeningStatusLoading,
}) => {
  const mapName = useContext(MapContext);

  const { data: checklistData, refetch: refetchChecklistData } =
    useGetChecklistQuery({
      map_name: mapName ?? "",
      account_type: accountType as "buyer" | "supplier",
    });

  const {
    data: mapData,
    isFetching,
    isLoading,
    refetch: refetchMapSummary,
  } = useGetMapSummaryQuery(mapName ?? "", { skip: !mapName });

  const { supplierInternalData, buyerInternalData } = useAccountData({
    accountType,
    mapId: mapName ?? "",
  });

  const accountName =
    accountType === BUYER?.toLowerCase()
      ? mapData?.buyer_name
      : mapData?.seller_name;
  const link =
    accountType === BUYER?.toLowerCase()
      ? mapData?.buyer_internal_name
      : mapData?.seller_internal_name;

  const documentsCheck = checklistData?.documents ?? [];
  const checklistCheck = checklistData?.checklist ?? [];

  const items: Item[] = [];

  let initialData: CompanyDoc[] = [];
  if (documentsCheck.length > 0) {
    documentsCheck.forEach((doc) => {
      items.push({
        id: doc.data.document_type_code,
        name: doc.name ?? "",
        label: doc.name,
        value: "",
        description: doc.name,
        items: [],
        yearly_set_count: 1,
        count_unique_uploaded:
          doc?.documents && doc?.documents.length > 0 ? 1 : 0,
      });
      initialData = initialData.concat(
        doc?.documents?.map((docItem: any) => docItem?.uploaded) ?? []
      );
    });
  }

  const checklist = checklistCheck?.reduce<MapTemplate>((acc, check) => {
    acc[toCamelCase(check.name)] = {
      id: check.id,
      name: check.name,
      type: check.type,
      log_id: check.log_id,
      data: check.items as any,
    };
    return acc;
  }, {});

  const filteredItems = items?.filter((item) => item.label !== "Credit Report");
  const creditReportItem = items?.find(
    (item) => item?.label === "Credit Report"
  );

  const [show, setShow] = useState<boolean>(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  const [documentId, setDocumentId] = useState<string>("");

  const finalId =
    accountType === SUPPLIER?.toLowerCase()
      ? (supplierInternalData?.id ?? "")
      : (buyerInternalData?.id ?? "");

  const { data: screeningDocuments } = useGetDocumentUrlQuery(
    {
      account_id: finalId,
      document_id: documentId ?? "",
    },
    {
      skip: !Boolean(finalId) || !Boolean(documentId),
    }
  );

  const fetchDocument = async (id: string) => {
    setDocumentId(id);
    onOpen();
  };

  const syncAction = () => {
    refetchChecklistData();
    refetchMapSummary();
  };

  return (
    <>
      {isLoading && screeningStatusLoading ? (
        <SkeletonLoad bars={10} />
      ) : (
        <MainCard
          title={`Checklist for ${accountName}`}
          subheader={
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                sx={{
                  bgcolor: "primary.main",
                  borderRadius: 1,
                  color: "#ffffff",
                  px: 1,
                  py: 0.5,
                }}
              >
                {accountType?.toUpperCase()}
              </Typography>
              <Link
                href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${link}`}
                target="_blank"
              >
                {link}
              </Link>
            </Stack>
          }
          headerConfig={{ syncAction }}
        >
          <Stack spacing={4} direction="row">
            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <Typography variant="h4">Trustee Requirement</Typography>
                <Divider />
                {filteredItems && filteredItems.length > 0 ? (
                  <Formik
                    initialValues={filteredItems?.reduce(
                      (acc: { [key: string]: null }, curr) => {
                        acc[curr.name] = null;
                        return acc;
                      },
                      {}
                    )}
                    onSubmit={() => {}}
                  >
                    {(formikProps) => (
                      <Form>
                        <DocumentUploadController
                          data={filteredItems ?? []}
                          enableCount={false}
                          formikProps={formikProps}
                          initialData={initialData}
                          viewDisabled={false}
                          uploadDisabled={true}
                          onUpload={function (
                            data: { file: File; year: number | null },
                            label: string,
                            document_type: string,
                            idx: number
                          ): void {
                            throw new Error("Function not implemented.");
                          }}
                          onView={(fileName, id, idx) => fetchDocument(id)}
                          sx={{ minHeight: "10vh" }}
                          containerSx={{ mt: 0 }}
                        />
                        <Modal
                          modalOverlaySx={{ borderRadius: "2ch" }}
                          width={
                            screeningDocuments?.url?.includes(".pdf?")
                              ? "60%"
                              : "30%"
                          }
                          height={
                            screeningDocuments?.url?.includes(".pdf?")
                              ? "90vh"
                              : "auto"
                          }
                          closeButton
                          message={
                            <Grid item xs={12}>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                              >
                                {screeningDocuments?.url?.includes(".pdf?") ? (
                                  <Grid
                                    item
                                    xs={12}
                                    id="modal-docview"
                                    style={{ height: "100%" }}
                                  >
                                    <DocView
                                      uri={screeningDocuments?.url}
                                      staticView
                                    />
                                  </Grid>
                                ) : (
                                  <Grid item xs={12}>
                                    <img
                                      src={screeningDocuments?.url ?? ""}
                                      alt={screeningDocuments?.url}
                                      width="100%"
                                      height="auto"
                                      style={{
                                        borderRadius: "2ch",
                                        margin: "auto",
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          }
                          open={show}
                          onClose={onClose}
                        />
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <NoData text="No Trustee Requirement" />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography variant="h4">Account opening docs</Typography>
                  <Divider />
                  <Formik
                    initialValues={[creditReportItem]?.reduce(
                      (acc: { [key: string]: null }, curr) => {
                        acc[curr?.name ?? ""] = null;
                        return acc;
                      },
                      {}
                    )}
                    onSubmit={() => {}}
                  >
                    {(formikProps) => (
                      <Form>
                        <DocumentUploadController
                          data={creditReportItem ? [creditReportItem] : []}
                          enableCount={false}
                          formikProps={formikProps}
                          initialData={initialData}
                          viewDisabled={false}
                          uploadDisabled={true}
                          onUpload={function (
                            data: { file: File; year: number | null },
                            label: string,
                            document_type: string,
                            idx: number
                          ): void {
                            throw new Error("Function not implemented.");
                          }}
                          onView={(fileName, id, idx) => fetchDocument(id)}
                          sx={{ minHeight: "10vh" }}
                          containerSx={{ mt: 0 }}
                        />
                      </Form>
                    )}
                  </Formik>
                </Stack>

                <Stack spacing={2}>
                  <Typography variant="h4">
                    Incomlend Internal & Compliance Requirements
                  </Typography>
                  <Divider />
                  {checklist && (
                    <IncomlendInternalComplianceRequirements
                      checklist={checklist}
                      accountType={accountType}
                      isLocked={isLocked}
                      screeningStatusData={screeningStatusData}
                    />
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Stack>
        </MainCard>
      )}
    </>
  );
};

export default ComplianceScoringChecklist;

import { Box, Grid, Slide, Stack, Typography, useTheme } from "@mui/material";
import { useGetEligibilitiesQuery } from "api/api-legal";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, LEGAL_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { CountryEligibility } from "types";
import styles from "./countries.module.scss";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";
import NoData from "components/Common/NoData";

const TO_BE_HIDDEN = [
  "has_buyer",
  "has_seller",
  "commit_message",
  "draft",
  "conditions",
  "buyer_has_conditions",
  "seller_has_conditions",
];

const CountryPage = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  
  const searchValue = useAppSelector((state) => state.search.value);
  const [queryParams, setQueryParams] = useState({
    per_page: 10,
    page: 1,
    search: searchValue || undefined,
  });
  const [filters, setFilters] = useState<Record<string, any>>({});

  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch]);

  useEffect(() => {
    if (searchValue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setQueryParams((prev) => ({ ...prev, page: 1, search: searchValue || undefined }));
    }
  }, [searchValue]);

  const {
    data: countries,
    isFetching: isFetchingCountries,
    isLoading: isLoadingCountries,
    refetch,
  } = useGetEligibilitiesQuery(
    { 
      countriesparams: `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(queryParams)
            .filter(([_, value]) => value !== undefined)
        ) as Record<string, string>
      )}` 
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const handleRefetch = async () => {
    await refetch();
    setQueryParams({
      per_page: 10,
      page: 1,
      search: undefined
    });
    setFilters({});
    dispatch(clearAll());
  };

  const handleFilterSubmit = (filtersString: string) => {
    const filtersObj = JSON.parse(filtersString);
    setFilters(filtersObj); // Persist filters
    const newFilters: Record<string, string> = {};

    for (const key in filtersObj) {
      if (filtersObj[key]?.keywords) {
        if (key === "created_at" && typeof filtersObj[key].keywords === "string") {
          newFilters[key] = filtersObj[key].keywords;
        } else if (Array.isArray(filtersObj[key].keywords)) {
          newFilters[key] = filtersObj[key].keywords.join(",");
        }
      }
    }

    setQueryParams((prev) => ({
      ...prev,
      ...newFilters,
      page: 1
    }));
  };

  const handlePageChange = (newPage: number, newPerPage: number) => {
    setQueryParams((prev) => ({
      ...prev,
      page: newPage,
      per_page: newPerPage
    }));
  };

  const initialFilters = {
    supplier: {
      comparator: "is not",
      keywords: ["Unknown"],
    },
    buyer: {
      comparator: "is not",
      keywords: ["Unknown"],
    },
  };

  const formatConditioning = (
    data: CountryEligibility[]
  ): CountryEligibility[] =>
    data?.map((item) => ({
      ...item,
      buyer: item.buyer_has_conditions ? "Conditional" : item.buyer,
      seller: item.seller_has_conditions ? "Conditional" : item.seller,
      legal_comments: item.legal_comments ?? "",
    }));

  const formattedData = formatConditioning(countries?.countries_details || []);
  const headers = Object.keys(formattedData?.[0] ?? {}).filter(
    (item) => !TO_BE_HIDDEN.includes(item)
  );
  const headerLength = headers?.length;

  return (
    <Slide in direction="left">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Layout
          title="Countries Eligibility"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          className={styles.layout}
          headerConfig={{
            syncAction: handleRefetch,
            left: 10,
            mid: 0,
            right: 2,
            xs: {
              left: 12,
              mid: 0,
              right: 0,
            },
            alignItems: "center",
          }}
          mode="default"
        >
          <Grid item xs={12} margin="5px">
            <Grid container ref={ref} className={styles.tableContainer}>
              {(isFetchingCountries || isLoadingCountries) && !formattedData && (
                <SkeletonLoad bars={10} />
              )}
              {!isFetchingCountries && !isLoadingCountries && (
                <Grid item xs={12} margin="5px">
                  {formattedData.length > 0 ? (
                    <DataTablev2
                      filterColumnTableName={countries}
                      onFilterSubmit={handleFilterSubmit}
                      toBeHidden={TO_BE_HIDDEN}
                      pagination={countries?.pagination}
                      data={formattedData}
                      rowsPerPage={rowsPerPage}
                      onPaginationChange={handlePageChange}
                      initialFilters={initialFilters}
                      filters={filters} // Pass persistent filters
                      setFilters={setFilters} // Pass setFilters
                      sx={{
                        width: "100%",
                        flexGrow: 1,
                        "& .MuiDataGrid-root": {
                          width: "100%",
                          maxWidth: "100%",
                          overflowX: "hidden",
                        },
                        "& .MuiDataGrid-cell": {
                          padding: isMobile ? "4px" : "8px",
                          fontSize: isMobile ? "0.75rem" : "0.875rem",
                          whiteSpace: "normal",
                          height: "auto !important",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        "& .MuiDataGrid-row": {
                          height: "auto !important",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          width: "100% !important",
                          overflow: "auto",
                        },
                        "& .MuiDataGrid-main": {
                          width: "100%",
                          overflow: "hidden",
                        },
                        "& .MuiDataGrid-viewport": {
                          width: "100%",
                        },
                        "& .MuiDataGrid-renderingZone": {
                          width: "100%",
                        },
                      }}
                      customColumns={{
                        additional_requirements: {
                          additional_requirements: "Additional Requirements",
                          flex: 1,
                          minWidth: 150,
                          renderCell: ({ value }: { value: string }) => (
                            <Typography sx={{ textAlign: "center" }}>
                              {value}
                            </Typography>
                          ),
                        },
                        name: {
                          name: "Name",
                          flex: 1,
                          minWidth: 200,
                          link: {
                            href: (country: CountryEligibility) =>
                              `${INCOMLEND_INTERNAL_LINK}${LEGAL_LINK}/${country?.id}/view-eligibility`,
                            target: "_self",
                          },
                          headerProps: {
                            sx: {
                              width: "100%",
                            },
                          },
                        },
                        buyer: {
                          buyer: t("buyer"),
                          flex: 1,
                          minWidth: 200,
                          type: "text",
                          valueFormatter: (value: string) => `${value}`,
                          renderCell: (params: any) => {
                            const background =
                              params.value === "Eligible"
                                ? "#9FCE63"
                                : params.value === "Conditional"
                                  ? "orange"
                                  : params.value === "Unknown"
                                    ? "grey"
                                    : "red";
                            const text = params.value;
                            return (
                              <Typography
                                style={{
                                  background,
                                  padding: "0.5ch",
                                  borderRadius: "10px",
                                  color: "white",
                                  fontWeight: "600",
                                  textAlign: "center",
                                }}
                                sx={{
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "1em",
                                  },
                                }}
                              >
                                {text}
                              </Typography>
                            );
                          },
                        },
                        seller: {
                          seller: t("supplier"),
                          flex: 1,
                          minWidth: 200,
                          type: "text",
                          valueFormatter: (value: string) => `${value}`,
                          renderCell: (params: any) => {
                            const background =
                              params.value === "Eligible"
                                ? "#9FCE63"
                                : params.value === "Conditional"
                                  ? "orange"
                                  : params.value === "Unknown"
                                    ? "grey"
                                    : "red";
                            const text = params.value;

                            return (
                              <Typography
                                style={{
                                  background,
                                  padding: "0.5ch",
                                  borderRadius: "10px",
                                  color: "white",
                                  fontWeight: "600",
                                  textAlign: "center",
                                }}
                                sx={{
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "1em",
                                  },
                                }}
                              >
                                {text}
                              </Typography>
                            );
                          },
                        },
                        party: {
                          party: "Parties",
                          flex: 1,
                          minWidth: 100,
                          renderCell: ({ value }: { value: string[] }) => {
                            return (
                              <Stack spacing={1} sx={{ textAlign: "center" }}>
                                {!value?.length && (
                                  <Typography
                                    sx={{
                                      color: theme.palette.secondary.main,
                                      textAlign: "center",
                                    }}
                                  >
                                    Unknown
                                  </Typography>
                                )}
                                {value?.map((item) => (
                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      fontSize: "1em",
                                      p: 1,
                                      borderRadius: 1,
                                      border: `1px solid ${theme.palette.primary.main}`,
                                      textAlign: "center",
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                ))}
                              </Stack>
                            );
                          },
                        },
                        document: {
                          document: "Documents",
                          flex: 1,
                          minWidth: 200,
                          renderCell: ({ value }: { value: string[] }) => {
                            return (
                              <Stack spacing={1} sx={{ textAlign: "center" }}>
                                {!value?.length && (
                                  <Typography
                                    sx={{
                                      color: theme.palette.secondary.main,
                                      textAlign: "center",
                                    }}
                                  >
                                    Unknown
                                  </Typography>
                                )}
                                {value?.map((item) => (
                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      fontSize: "1em",
                                      p: 1,
                                      borderRadius: 1,
                                      border: `1px solid ${theme.palette.primary.main}`,
                                      textAlign: "center",
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                ))}
                              </Stack>
                            );
                          },
                        },
                        legal_comments: {
                          legal_comments: "Legal Comments",
                          flex: 1,
                          minWidth: 400,
                          renderCell: ({ value }: { value: string }) => {
                            return (
                              <Box
                                sx={{
                                  width: "100%",
                                  p: isMobile ? 0.5 : 1,
                                  overflow: "hidden",
                                  textAlign: "center",
                                }}
                              >
                                {!value ? (
                                  <Typography
                                    sx={{ color: theme.palette.secondary.main }}
                                  >
                                    None
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: isMobile ? "0.875em" : "1em",
                                      lineHeight: "1.5em",
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                      display: "-webkit-box",
                                      WebkitLineClamp: isMobile ? 2 : 3,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {value}
                                  </Typography>
                                )}
                              </Box>
                            );
                          },
                        },
                      }}
                    />
                  ) : (
                    <NoData text="No Countries found" />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};

export default CountryPage;

import { Box, Grid, Slide, Typography } from "@mui/material";
import {
  useGetCustomerTemsheetListQuery,
  useGetTermsheetListQuery,
} from "api/api-accounts";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import {
  APPROVED,
  DEFAULT_ROWS,
  INCOMLEND_INTERNAL,
  INCOMLEND_INTERNAL_LINK,
  LEADS,
  PRODUCTS,
  REJECTED,
  TERMSHEETS_LINK,
} from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import StatusDisplay, {
  type TermSheetViewingRole,
} from "components/Termsheets/StatusDisplay";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";
import type { TermSheetListItem, TermsheetTableItem } from "types";

const format_termsheet_list = (
  items: TermSheetListItem[]
): TermsheetTableItem[] => {
  return items?.map((termsheet) => {
    const { id, internal_name, ...data } = termsheet;
    return {
      id,
      internal_name,
      lead_name: termsheet?.lead?.name ?? "",
      partner_name: termsheet?.partner?.name ?? "",
      ...data,
      last_updated_by:
        termsheet?.comment?.created_by ?? termsheet?.submitted_by ?? "",
      last_updated_on:
        termsheet?.comment?.created_at ?? termsheet?.last_update ?? null,
      comments: termsheet?.comment?.comments ?? "",
    };
  });
};

const Termsheets = () => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const searchValue = useAppSelector((state) => state.search.value);
  const [queryParams, setQueryParams] = useState({
    per_page: 10,
    page: 1,
    search: searchValue || undefined,
  });
  const [filters, setFilters] = useState<Record<string, any>>({});

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch]);

  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    if (searchValue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setQueryParams((prev) => ({ ...prev, page: 1, search: searchValue || undefined }));
    }
  }, [searchValue]);

  const { data: curr_user } = useGetCurrentUserQuery();
  const defaultColumnWidth = width ? (width / 11) * 1.2 : 200;
  const settings = curr_user?.settings ?? {};
  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  const isInternal = APP_PERMISSION === INCOMLEND_INTERNAL;
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const internalRole: TermSheetViewingRole = USER_PERMISSIONS.includes(
    PERMISSIONS.termsheet_approver
  )
    ? "HOD"
    : "RM";
  const currentRole: TermSheetViewingRole = isInternal
    ? internalRole
    : "CUSTOMER";
  const toBeHidden = [
    "id",
    "account_relationship_id",
    "created_at",
    "comment",
    "expiry_info",
    "last_update",
    "termsheet_signed_date",
    "lead",
    "partner",
  ];
  const toBeHiddenForRM = [...toBeHidden, "submitted_by"];

  const textSx = {
    fontSize: "1.1em!important",
    lineHeight: "1.5em",
    height: "auto",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    wordWrap: "break-word",
    minWidth: isMobile ? "100%" : "10vw",
  };

  const getProductLabel = (product: string) => {
    if (product === PRODUCTS.factoring) return "Factoring";
    if (product === PRODUCTS.reverse_factoring) return "Reverse Factoring";
    return null;
  };

  const getHiddenColums = () => {
    if (currentRole === "CUSTOMER") {
      return [...toBeHidden, "internal_name", "lead"];
    }
    if (currentRole === "RM") {
      return toBeHiddenForRM;
    }
    return toBeHidden;
  };

  const {
    data: termsheetsList,
    isFetching,
    refetch,
  } = useGetTermsheetListQuery(
    { 
      termshetparams: `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(queryParams)
            .filter(([_, value]) => value !== undefined)
        ) as Record<string, string>
      )}` 
    },
    { skip: !isInternal }
  );

  const {
    data: customerTermsheetsList,
    refetch: customerRefetch,
    isFetching: customerIsFetching,
  } = useGetCustomerTemsheetListQuery(null, {
    skip: isInternal,
  });

  const handleRefetch = async () => {
    if (isInternal) {
      await refetch();
    } else {
      await customerRefetch();
    }
    setQueryParams({
      per_page: 10,
      page: 1,
      search: undefined
    });
    setFilters({});
    dispatch(clearAll());
  };

  const handleFilterSubmit = (filtersString: string) => {
    const filtersObj = JSON.parse(filtersString);
    setFilters(filtersObj); // Persist filters
    const newFilters: Record<string, string> = {};
    for (const key in filtersObj) {
      if (filtersObj[key]?.keywords) {
        if (key === "created_at" && typeof filtersObj[key].keywords === "string") {
          newFilters[key] = filtersObj[key].keywords;
        } else if (Array.isArray(filtersObj[key].keywords)) {
          newFilters[key] = filtersObj[key].keywords.join(",");
        }
      }
    }
    setQueryParams((prev) => ({ ...prev, ...newFilters, page: 1 }));
  };

  const handlePageChange = (newPage: number, newPerPage: number) => {
    setQueryParams((prev) => ({
      ...prev,
      page: newPage,
      per_page: newPerPage
    }));
  };

  const formattedData = format_termsheet_list(
    isInternal ? termsheetsList?.termsheet_details : customerTermsheetsList
  ) ?? [];

  const isFetchingTermsheetData = isInternal ? isFetching : customerIsFetching;

  return (
    <Slide in direction="left">
      <Box sx={{ flexGrow: 1, p: 0 }}>
        <Layout
          sx={{ padding: "1px" }}
          title="Termsheets"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            syncAction: handleRefetch,
            left: 4,
            mid: 0,
            right: 4,
            xs: {
              left: 6,
              mid: 0,
              right: 6,
            },
            alignItems: "center",
          }}
          mode="default"
        >
          <Grid item xs={12} ref={ref} sx={{ margin: "5px" }}>
            <Grid container spacing={1}>
              {isFetchingTermsheetData && <SkeletonLoad bars={10} />}
              {!isFetchingTermsheetData && formattedData && (
                <DataTablev2
                  onFilterSubmit={handleFilterSubmit}
                  filterColumnTableName={termsheetsList}
                  pagination={termsheetsList?.pagination}
                  onPaginationChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  defaultColumnWidth={defaultColumnWidth}
                  data={formattedData}
                  toBeHidden={getHiddenColums()}
                  filters={filters}
                  setFilters={setFilters}
                  customColumns={{
                    internal_name: {
                      internal_name: "Termsheet ID",
                      type: "text",
                      flexGrow: 1,
                      link: {
                        href: (params: TermSheetListItem) => {
                          if (!isInternal) {
                            return `${TERMSHEETS_LINK}/${params?.id}`;
                          }
                          if (
                            [REJECTED, APPROVED].includes(
                              params.termsheet_status
                            )
                          ) {
                            return `${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}/${params?.internal_name}`;
                          } else {
                            return `${INCOMLEND_INTERNAL_LINK}${LEADS}/${params?.lead?.internal_name}?partner=${params?.partner?.internal_name}`;
                          }
                        },
                        target: "_self",
                      },
                    },
                    lead_name: {
                      lead_name: "Customer",
                      type: "text",
                      flexGrow: 1,
                      link: isInternal
                        ? {
                            href: (params: TermSheetListItem) => {
                              return `${INCOMLEND_INTERNAL_LINK}${LEADS}/${params?.lead?.internal_name}`;
                            },
                            target: "_self",
                          }
                        : undefined,
                    },
                    partner_name: {
                      partner_name: "Partner",
                      type: "text",
                      flexGrow: 1,
                      renderCell: ({ value }: { value: string }) => (
                        <Typography
                          sx={
                            isInternal
                              ? textSx
                              : {
                                  ...textSx,
                                  textDecoration: "underline",
                                }
                          }
                        >
                          {value}
                        </Typography>
                      ),
                      link: isInternal
                        ? undefined
                        : {
                            href: (params: TermSheetListItem) => {
                              return `${TERMSHEETS_LINK}/${params?.id}`;
                            },
                            target: "_self",
                          },
                    },
                    product: {
                      product: "Product",
                      type: "text",
                      flexGrow: 1,
                      renderCell: ({ value }: { value: string }) => (
                        <Typography sx={textSx}>
                          {getProductLabel(value)}
                        </Typography>
                      ),
                    },
                    submitted_by: {
                      submitted_by: "Submitted By",
                      type: "text",
                      flexGrow: 1,
                    },
                    termsheet_status: {
                      termsheet_status: "Status",
                      type: "text",
                      flexGrow: 1,
                      renderCell: ({ value }: { value: string }) => (
                        <Grid
                          container
                          justifyContent={isMobile ? "left" : "center"}
                        >
                          <Grid item xs={12}>
                            <StatusDisplay status={value} />
                          </Grid>
                        </Grid>
                      ),
                    },
                    last_updated_by: {
                      last_updated_by: "Last Updated By",
                      type: "text",
                      flexGrow: 1,
                    },
                    last_updated_on: {
                      last_updated_on: "Last Updated On",
                      type: "text",
                      flexGrow: 1,
                      renderCell: ({ value }: { value: string }) => {
                        return (
                          <Typography sx={textSx}>
                            {value
                              ? moment(value).format("DD/MM/YYYY, HH:mm:ss")
                              : "None"}
                          </Typography>
                        );
                      },
                    },
                    comments: {
                      comments: "Comments",
                      flexGrow: 1,
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography
                          sx={textSx}
                          color={value ? "primary" : "grey"}
                        >
                          {value.length ? value : "None"}
                        </Typography>
                      ),
                    },
                  }}
                  onRowClicked={(row) => {
                    const params = new URLSearchParams();
                    if (!isInternal) {
                      history.push(`${TERMSHEETS_LINK}/${row?.id}`);
                      return;
                    }
                    if ([REJECTED, APPROVED].includes(row?.termsheet_status)) {
                      history.push(
                        `${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}/${row?.internal_name}`
                      );
                      return;
                    }
                    params.set("partner", row?.partner?.internal_name);
                    history.push(
                      `${INCOMLEND_INTERNAL_LINK}${LEADS}/${row?.lead?.internal_name}?${params.toString()}`
                    );
                  }}
                />
              )}
              {!isFetchingTermsheetData &&
                (!formattedData || formattedData?.length === 0) && (
                  <NoData text="No termsheets found" sx={{ margin: "auto" }} />
                )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default Termsheets;

import { Clear } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import * as React from "react";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid'; 

interface SingleTextAcProps {
  data: any[]; // Options for the autocomplete
  header: string; // The filter key (e.g., "name", "seller")
  filters: Record<string, any>; // Current filter state
  setFilters: (filters: Record<string, any>) => void; // Function to update filters (updateFilters from Toolbar)
  customFieldName?: string; // Optional custom label for the field
  getStatusApiValue?: (value: string) => string; // Function to map display value to API value
}

const SingleTextAc = ({
  data,
  header,
  setFilters,
  filters,
  customFieldName,
  getStatusApiValue,
}: SingleTextAcProps) => {
  // Initialize value from filters, defaulting to an empty array if undefined
  const [value, setValue] = useState<any[]>(filters[header]?.keywords ?? []);

  // Sync local value with filters prop when it changes externally
  useEffect(() => {
    setValue(filters[header]?.keywords ?? []);
  }, [filters, header]);

  // Handle filter updates
  const updateFilterState = (newValue: any[]) => {
    if (newValue.length) {
      // Map display values to API values if needed
      const apiValues = getStatusApiValue 
        ? newValue.map((val) => getStatusApiValue(val))
        : newValue;

      // Add or update the filter for this header
      setFilters({
        ...filters,
        [header]: {
          comparator: "is", // Default comparator
          keywords: apiValues,
        },
      });
    } else {
      // Remove the filter for this header if no values are selected
      const updatedFilters = { ...filters };
      delete updatedFilters[header];
      setFilters(updatedFilters);
    }
  };

  return (
    <Autocomplete
      id={`${header}-filter`}
      multiple
      sx={{
        width: "100%",
        ".MuiFormControl-root": {
          ".MuiInputBase-root": {
            paddingRight: "0 !important",
          },
        },
      }}
      value={value}
      clearIcon={
        <Clear
          onClick={() => {
            setValue([]); // Clear local value
            const updatedFilters = { ...filters };
            delete updatedFilters[header]; // Remove filter
            setFilters(updatedFilters); // Update parent state
          }}
        />
      }
      onChange={(event: any, newValue: any[]) => {
        setValue(newValue); // Update local state with display values
        updateFilterState(newValue); // Update filter state with API values
      }}
      options={data.filter((option) => option != null)} // Filter out null/undefined options
      getOptionLabel={(option: any) => (option ? String(option) : "Not defined")}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={customFieldName ?? header}
          margin="normal"
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        if (option) {
          const matches = match(String(option), inputValue);
          const parts = parse(String(option), matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part: any, index: number) => (
                  <span
                    key={`${part.text}-${uuidv4()}`} // Use a unique key for each part
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }
        return <li {...props}>Not defined</li>;
      }}
    />
  );
};

export default SingleTextAc;

import { Chip, Grid, Tooltip } from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import type { CustomColumn } from "..";
import {
  FILTER_TYPE_IS_BETWEEN,
  FILTER_TYPE_ONLY,
  FILTER_TYPE_TILL,
} from "./filterTypes";

interface StatusOptions {
  status?: Record<string, string>;
  termsheet_status?: Record<string, string>;
  internalStatus?: Record<string, string>;
  invoice_status?: Record<string, string>;
}

interface FilterChipProps {
  filters: Record<string, any>;
  clearFilters: () => void;
  customColumns: CustomColumn;
  getStatusDisplayValue?: (statusKey: keyof StatusOptions, apiValue: string) => string;
}

const FilterChip = ({
  filters,
  clearFilters,
  customColumns,
  getStatusDisplayValue,
}: FilterChipProps) => {
  const isMobile = useIsMobile();

  const formatContent = (key: string, comparator: string, keywords: any) => {
    let compares = comparator;
    let content = "";

    // Handle status fields with display values
    const isStatusField = key === "status" || 
                        key.endsWith("_status") || 
                        key === "internalStatus";
    
    const processKeywords = (kw: any) => {
      if (isStatusField && getStatusDisplayValue) {
        if (Array.isArray(kw)) {
          return kw.map((val) => getStatusDisplayValue(key as keyof StatusOptions, val));
        }
        return getStatusDisplayValue(key as keyof StatusOptions, kw);
      }
      return kw;
    };

    switch (comparator) {
      case FILTER_TYPE_IS_BETWEEN:
        compares = "is between";
        if (Array.isArray(keywords) && keywords.length === 2) {
          const [start, end] = keywords.map((kw) => {
            const processed = processKeywords(kw);
            return customColumns[key]?.type === "date" && processed
              ? moment(processed).format("L")
              : processed;
          });
          content = `${start} and ${end}`;
        }
        break;

      case FILTER_TYPE_TILL:
        compares = "till";
        if (Array.isArray(keywords)) {
          content = keywords
            .map((kw) => {
              const processed = processKeywords(kw);
              return customColumns[key]?.type === "date" && processed
                ? moment(processed).format("L")
                : processed;
            })
            .join(" till ");
        }
        break;

      case FILTER_TYPE_ONLY:
        compares = "is";
        content = Array.isArray(keywords)
          ? keywords.map(processKeywords).join(", ")
          : processKeywords(keywords);
        break;

      default:
        content = Array.isArray(keywords)
          ? keywords.map(processKeywords).join(", ")
          : processKeywords(keywords);
        break;
    }

    return { compares, content };
  };

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        margin="auto"
        spacing={0.5}
      >
        {Object.entries(filters).map(([key, value]) => {
          const { comparator, keywords } = value;
          const displayKey = customColumns?.[key]?.[key] || key;
          
          if (!keywords || (Array.isArray(keywords) && keywords.length === 0)) {
            return null;
          }

          const { compares, content } = formatContent(key, comparator, keywords);

          return (
            <Grid item maxWidth={isMobile ? "100%" : "25%"} key={key}>
              <Tooltip title={`${displayKey} ${compares} ${content}`}>
                <Chip
                  label={`${displayKey} ${compares} ${content}`}
                  variant="filled"
                  sx={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
              </Tooltip>
            </Grid>
          );
        })}

        <Grid item maxWidth={isMobile ? "100%" : "25%"} key="clear">
          <Tooltip title="Clear Filters">
            <Chip
              label="Clear Filters"
              variant="outlined"
              onDelete={clearFilters}
              sx={{ cursor: "pointer" }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterChip;

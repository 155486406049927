import {
  MenuItem,
  type MenuProps,
  Select,
  Stack,
  type SxProps,
  useTheme,
} from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";

import { useEffect, useState } from "react";

export interface DropDownProps {
  name: string;
  keyValue: string;
  data: any[];
  fullWidth?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  placeholder?: string;
  setValue?: (value: string, prev: string, index?: any) => any;
  /**
   * Used in conditions like form-within-form, where the field value setter is external.
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: any) => void;
  index?: number;
  plain?: boolean;
  value?: string;
  error?: boolean;
  label?: string;
  menuItemSx?: SxProps;
  MenuProps?: Partial<MenuProps>;
  NestedMenuProps?: Partial<MenuProps>;
  className?: string;
  [x: string]: any;
}

const SalesDropDown = ({
  name,
  data,
  value,
  keyValue,
  fullWidth,
  sx,
  index,
  placeholder = "Select",
  setValue = () => {},
  setFieldValue,
  plain,
  error,
  label,
  menuItemSx,
  MenuProps,
  NestedMenuProps,
  disabled,
  className,
  ...rest
}: DropDownProps) => {
  const formikValue =
    !isObjectEmpty(rest) && rest?.hasOwnProperty("values")
      ? rest["values"][name][keyValue]
      : value;

  const [dropdownValue, setCurrentValue] = useState("");
  const isMobile = useIsMobile();
  const theme = useTheme();

  useEffect(() => {
    if (formikValue) setCurrentValue(formikValue);
  }, [formikValue]);

  const handleChange = (event: any) => {
    const eTarget = event.target;
    setValue(eTarget.value as string, dropdownValue, index);
    setCurrentValue(eTarget.value as string);
    const newVal = data.find((rep) => rep[keyValue] === eTarget.value) ?? {};
    setFieldValue ? setFieldValue(`${name}`, newVal, true) : null;
  };

  useEffect(() => {
    if (formikValue !== undefined && formikValue !== dropdownValue)
      setCurrentValue(formikValue);
  }, [formikValue, dropdownValue]);

  const selectSx = {
    width: isMobile ? "90vw" : "30vw",
    borderRadius: "10px !important",
    ".Mui-disabled": {
      borderRadius: "10px !important",
      backgroundClip: "content-box",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      "> div": {
        height: "auto",
        "> input": {
          height: "0.8em",
          fontSize: "0.8em",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "0.8em",
      height: "2.9em",
      ">div": {
        height: "2.3em",
        ">input": {
          height: "0.8em",
          fontSize: "0.8em",
        },
      },
    },
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%' }}>
      <Stack sx={{ flexShrink: 0, minWidth: '90px' }}>
        {label && <LabelViewOnly label={label} />}
      </Stack>
      <Select
        error={error}
        disabled={disabled}
        placeholder={placeholder}
        value={dropdownValue}
        MenuProps={MenuProps}
        displayEmpty
        variant={plain ? "standard" : "outlined"}
        fullWidth={fullWidth}
        sx={{ ...selectSx, ...sx }}
        name={name}
        onChange={handleChange}
      >
        <MenuItem value="" disabled>
          {value ?? placeholder}
        </MenuItem>
        {data &&
          data.map((item: any) => (
            <MenuItem
              key={item[keyValue]}
              value={item[keyValue]}
              disabled={item.disabled}
            >
              {item[keyValue]}
            </MenuItem>
          ))}
      </Select>
    </Stack>
  );
};
export default SalesDropDown;

import EmailIcon from "@mui/icons-material/Email";
import { Box, Slide } from "@mui/material";
import { useGetInternalUsersQuery } from "api/api-it";
import Layout from "components/Common/Layout";
import InvitedUsers from "components/Users/InvitedUsers";
import InviteUser from "components/Users/InviteUser";
import useIsMobile from "hooks/useIsMobile";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useEffect, useState } from "react";
import type { OverridableStringUnion } from "types";
import AllUsers from "components/Users/AllUsers";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface IUserIT {
  departments: string[];
  first_name: string;
  groups: string[];
  is_active: string;
  last_login: Date;
  last_name: string;
  id: string;
  username: string;
  contact_id: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ITUserManagement = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const isMobile = useIsMobile();
  const [userType, setUserType] =
    useState<
      OverridableStringUnion<"internal" | "external" | "agent" | "salespartner">
    >("Internal");

  const usermanagparams = userType.replaceAll(" ", "").toLowerCase();

  const {
    data: internalUsers,
    refetch: refetchInternalUsers,
    isFetching: isFetchingInternalUsers,
  } = useGetInternalUsersQuery({ usermanagparams });
  // const {
  //   data: externalUsers,
  //   refetch: refetchExternalUsers,
  //   isFetching: isFetchingExternalUsers,
  // } = useGetExternalUsersQuery({usermanagparams});

  const [inviteuserMode, setInviteUserMode] = useState<boolean>(false);

  const USERS_TABLE_DATA: Record<
    string,
    { data: IUserIT[]; refetch: () => void; isFetching: boolean }
  > = {
    Internal: {
      data: internalUsers ?? [],
      refetch: refetchInternalUsers,
      isFetching: isFetchingInternalUsers,
    },
    // Clients: {
    //   data: externalUsers ?? [],
    //   refetch: refetchExternalUsers,
    //   isFetching: isFetchingExternalUsers,
    // },
    // All: {
    //   data: [...(internalUsers ?? []), ...(externalUsers ?? [])],
    //   refetch: () => [refetchInternalUsers(), refetchExternalUsers()],
    //   isFetching: isFetchingInternalUsers || isFetchingExternalUsers,
    // },
  };

  const headerLength = Object.keys(internalUsers?.[0] ?? {})?.length;

  useEffect(() => {
    refetchInternalUsers();
  }, [userType]);

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={inviteuserMode ? "Invite User" : "Users Table"}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 6,
            mid: 0,
            right: 3,
            syncAction: USERS_TABLE_DATA[userType]?.refetch,
            justifyContent: "space-between",
            alignItems: "center",
            xs: {
              left: 12,
              mid: 0,
              right: 12,
            },
          }}
          primary={{
            onClick: () => setInviteUserMode(!inviteuserMode),
            isCancel: inviteuserMode,
            startIcon: inviteuserMode ? null : <EmailIcon />,
            children: inviteuserMode ? "Cancel" : "Invite User",
            variant: "contained",
            fullWidth: true,
          }}
          mode="default"
        >
          {inviteuserMode && (
            <InviteUser setInviteUserMode={setInviteUserMode} />
          )}
          {/* {!inviteuserMode && (
            <UserManagementTabs
              tabs={[
                <UsersTable
                  users={USERS_TABLE_DATA}
                  userType={userType}
                  setUserType={setUserType}
                  headerLength={headerLength}
                />,
                <InvitedUsers />,
              ]}
            />
          )} */}
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="User List" {...a11yProps(0)} />
                <Tab label="Invited Users" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <AllUsers />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <InvitedUsers />
            </CustomTabPanel>
          </Box>
        </Layout>
      </Box>
    </Slide>
  );
};

export default ITUserManagement;

import { Box, Grid, Slide, Typography } from "@mui/material";
import {
  useGetCustomerTermsheetBillingListQuery,
  useGetTermsheetBillingListQuery,
} from "api/api-accounts";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { BILLINGS_LINK, DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK } from "codes";
import StatusDisplay from "components/Billings/StatusDisplay";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";
import type { TermsheetInvoice } from "types";

const Billings: React.FC = () => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const textSx = {
    fontSize: "1.1em!important",
    lineHeight: "2em",
    height: "1.8em!important",
    overflow: "hidden",
    maxWidth: "76%",
    textOverflow: "ellipsis",
    minWidth: isMobile ? "100%" : "10vw",
  };

  const searchValue = useAppSelector((state) => state.search.value);
  const dispatch = useAppDispatch();
  const [queryParams, setQueryParams] = useState({
    per_page: 10,
    page: 1,
    search: searchValue || undefined,
  });
  const [filters, setFilters] = useState<Record<string, any>>({});

  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch]);

  const { data: curr_user } = useGetCurrentUserQuery();
  const isInternal = curr_user?.is_internal;
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    if (searchValue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setQueryParams((prev) => ({ ...prev, page: 1, search: searchValue || undefined }));
    }
  }, [searchValue]);

  // Internal user billing query
  const {
    data: bills,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
    refetch: refetchBills,
  } = useGetTermsheetBillingListQuery(
    { 
      billingparams: `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(queryParams)
            .filter(([_, value]) => value !== undefined)
        ) as Record<string, string>
      )}` 
    },
    { skip: !isInternal, refetchOnMountOrArgChange: true }
  );

  // Customer billing query
  const {
    data: customerBills,
    isFetching: isFetchingCustomerBills,
    isLoading: isLoadingCustomerBills,
    refetch: refetchCustomerBills,
  } = useGetCustomerTermsheetBillingListQuery(null, {
    skip: isInternal,
  });

  const handleRefetch = async () => {
    if (isInternal) {
      await refetchBills();
    } else {
      await refetchCustomerBills();
    }
    setQueryParams({
      per_page: 10,
      page: 1,
      search: undefined
    });
    setFilters({});
    dispatch(clearAll());
  };

  const handleFilterSubmit = (filtersString: string) => {
    const filtersObj = JSON.parse(filtersString);
    setFilters(filtersObj); // Persist filters
    const newFilters: Record<string, string> = {};
    for (const key in filtersObj) {
      if (filtersObj[key]?.keywords) {
        if (key === "created_at" && typeof filtersObj[key].keywords === "string") {
          newFilters[key] = filtersObj[key].keywords;
        } else if (Array.isArray(filtersObj[key].keywords)) {
          newFilters[key] = filtersObj[key].keywords.join(",");
        }
      }
    }
    setQueryParams((prev) => ({ ...prev, ...newFilters, page: 1 }));
  };

  const handlePageChange = (newPage: number, newPerPage: number) => {
    setQueryParams((prev) => ({
      ...prev,
      page: newPage,
      per_page: newPerPage
    }));
  };

  const formatBillsForTable = (data: TermsheetInvoice[] = []) => {
    return data.map((item) => ({
      ...item,
      lead: item.lead?.name || '',
    }));
  };

  const formattedData = formatBillsForTable(bills?.billing_details || []);
  const customerFormattedData = formatBillsForTable(customerBills || []);
  const dataToDisplay = isInternal ? formattedData : customerFormattedData;
  const isLoading = isInternal ? isLoadingBills : isLoadingCustomerBills;
  const isFetching = isInternal ? isFetchingBills : isFetchingCustomerBills;

  const headerLength = isInternal ? 8 : 6; // Adjust based on visible columns
  const defaultColumnWidth = width ? (width / headerLength) * 1.2 : 200;

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1} ref={ref}>
        <Layout
          title="Finance Billings"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 4,
            mid: 0,
            right: 4,
            syncAction: handleRefetch,
            xs: {
              left: 6,
              mid: 0,
              right: 6,
            },
            alignItems: "center",
          }}
          mode="default"
        >
          {(isFetching || isLoading) && <SkeletonLoad bars={10} />}

          {!isFetching && !isLoading && (
            <Grid item xs={12}>
              {dataToDisplay && dataToDisplay.length > 0 ? (
                <DataTablev2 
                  filterColumnTableName={bills}
                  data={dataToDisplay}
                  defaultColumnWidth={defaultColumnWidth}
                  rowsPerPage={rowsPerPage}
                  onPaginationChange={handlePageChange}
                  useKey="internal_name"
                  onFilterSubmit={handleFilterSubmit}
                  initialFilters={{}}
                  filters={filters}
                  setFilters={setFilters}
                  pagination={isInternal ? bills?.pagination : undefined}
                  toBeHidden={isInternal ? [] : ["internal_name", "lead"]}
                  customColumns={{
                    internal_name: {
                      internal_name: "Bill Id",
                      type: "text",
                      link: {
                        href: (row: TermsheetInvoice) =>
                          `${INCOMLEND_INTERNAL_LINK}${BILLINGS_LINK}/${row?.internal_name}`,
                        target: "_self",
                      },
                    },
                    reference_number: {
                      reference_number: "Ref No",
                      type: "text",
                      link: isInternal
                        ? undefined
                        : {
                            href: (row: TermsheetInvoice) =>
                              `${BILLINGS_LINK}/${row?.id}`,
                            target: "_self",
                          },
                    },
                    lead: {
                      lead: "Customer",
                      type: "text",
                      minWidth: 260,
                      renderCell: ({ value }: { value: string }) => (
                        <Typography sx={textSx}>{value}</Typography>
                      ),
                    },
                    amount_paid: {
                      amount_paid: "Amount",
                      type: "text",
                    },
                    paid_at: {
                      paid_at: "Paid On",
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography
                          sx={textSx}
                          color={value ? "primary" : "grey"}
                        >
                          {value
                            ? moment(value).format("DD/MM/YYYY, HH:mm:ss")
                            : "None"}
                        </Typography>
                      ),
                    },
                    approved_at: {
                      approved_at: "Payment Approved On",
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography
                          sx={textSx}
                          color={value ? "primary" : "grey"}
                        >
                          {value
                            ? moment(value).format("DD/MM/YYYY, HH:mm:ss")
                            : "None"}
                        </Typography>
                      ),
                    },
                    invoice_status: {
                      
                      invoice_status: "Status",
                      type: "text",
                      renderCell: ({ value }: { value: string }) => {
                        return (
                        <Grid
                          container
                          justifyContent={isMobile ? "left" : "center"}
                        >
                          <Grid item xs={12}>
                            <StatusDisplay status={value} />
                          </Grid>
                        </Grid>
                      )
                    },
                    },
                  }}
                  sx={{
                    table: {
                      minWidth: "100% !important",
                    },
                    "& .MuiTableCell-root": {
                      fontSize: "0.75rem",
                    },
                  }}
                />
              ) : (
                <NoData text="No termsheets found" sx={{ margin: "auto" }} />
              )}
            </Grid>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};

export default Billings;

import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    useGetAllPermissionsQuery,
    useGetAllUserPermissionsQuery,
    useAddUserPermissionMutation,
    useRemoveUserPermissionMutation
} from "api/api-it";
import { useParams } from "react-router-dom";

function not(a: string[], b: string[]): string[] {
    return a.filter((value) => !b.includes(value));
}

function intersection(a: string[], b: string[]): string[] {
    return a.filter((value) => b.includes(value));
}

export default function UserPermissionSet() {
    const { id } = useParams();
    const { data: permission, isLoading: permissionLoading } = useGetAllPermissionsQuery(id || "");
    const { data: userPermissions, isLoading: userPermissionsLoading, refetch } = useGetAllUserPermissionsQuery(id || "");
    const [addUserPermission, { isLoading: adding }] = useAddUserPermissionMutation();
    const [removeUserPermission, { isLoading: removing }] = useRemoveUserPermissionMutation();

    const [checked, setChecked] = React.useState<string[]>([]);
    const [left, setLeft] = React.useState<string[]>([]);
    const [right, setRight] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (permission && userPermissions) {
            const allPermissions = permission.map((perm) => perm.name);
            const userPermissionNames = userPermissions.map((perm) => perm.permission_name);
            setRight(userPermissionNames);
            setLeft(not(allPermissions, userPermissionNames));
        }
    }, [permission, userPermissions]);

    if (permissionLoading || userPermissionsLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
                <CircularProgress />
            </Grid>
        );
    }
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const handleCheckedRight = async () => {
        if (leftChecked.length === 0) return;
        const permissionIds = permission
            ?.filter((perm) => leftChecked.includes(perm.name))
            .map((perm) => perm.id);
        if (!(permissionIds?.length ?? 0)) return;

        try {
            if (permissionIds && permissionIds.length > 0) {
                if (id) {
                    await addUserPermission({ user_id: id, permission_ids: permissionIds });
                } else {
                    toast.error("User ID is missing");
                }
            }
            toast.success("Permissions added successfully!");
            await refetch();
        } catch (error) {
            toast.error("Failed to add permissions");
        }
        setChecked(not(checked, leftChecked));
    };
    const handleCheckedLeft = async () => {
        if (rightChecked.length === 0) return;
        const permissionSetIds = userPermissions?.filter((perm) => rightChecked.includes(perm.permission_name))
            .map((perm) => perm.id);
        if (!(permissionSetIds?.length ?? 0)) return;

        try {
            await removeUserPermission({ permission_set_ids: permissionSetIds ?? [] });
            toast.success("Permissions removed successfully!");
            await refetch();
        } catch (error) {
            toast.error("Failed to remove permissions");
        }
        setChecked(not(checked, rightChecked));
    };
    const customList = (title: string, items: string[]) => (
        <Card sx={{ width: { xs: "100%", md: 350 }, minHeight: 350 }}>
            <CardHeader sx={{ px: 2, py: 1 }} title={title} subheader={`${intersection(checked, items).length}/${items.length} selected`} />
            <Divider />
            <List sx={{ width: "100%", height: 300, overflow: "auto" }}>
                {items.map((name) => (
                    <ListItemButton key={name} onClick={() => setChecked((prev) => prev.includes(name) ? prev.filter((item) => item !== name) : [...prev, name])}>
                        <ListItemIcon>
                            <Checkbox checked={checked.includes(name)} />
                        </ListItemIcon>
                        <ListItemText primary={name} />
                    </ListItemButton>
                ))}
            </List>
        </Card>
    );
    return (
        <Grid container spacing={4} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Grid item xs={12} md={4}>{customList("Choices", left)}</Grid>
            <Grid item xs={12} md={2} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Button sx={{ my: 1 }} variant="outlined" size="small" onClick={handleCheckedRight} disabled={leftChecked.length === 0 || adding}>
                    {adding ? <CircularProgress size={20} /> : ">"}
                </Button>
                <Button sx={{ my: 1 }} variant="outlined" size="small" onClick={handleCheckedLeft} disabled={rightChecked.length === 0 || removing}>
                    {removing ? <CircularProgress size={20} /> : "<"}
                </Button>
            </Grid>
            <Grid item xs={12} md={4}>{customList("Chosen", right)}</Grid>
        </Grid>
    );
}

import { Autocomplete, TextField } from "@mui/material";
import { useGetIndustriesQuery } from "api/api-company";
import { useGetCountriesQuery } from "api/api-misc";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import Goods from "components/Company/Goods";
import { useFormikContext } from "formik";
import styles from "./creditreport.module.scss";

const InputComponent = <T extends object>(props: {
  type: string;
  name: string;
  value: any;
  data?: string[];
  isLocked?: boolean;

  handleSave: (value: any) => void;
}) => {
  const { data: industries, isFetching: isFetchingIndustries } =
    useGetIndustriesQuery();
  const { data: countries, isFetching: isFetchingCountries } =
    useGetCountriesQuery();

  const formikProps = useFormikContext<T>();

  switch (props?.type) {
    case "multi":
      return (
        <Autocomplete
          disabled={props?.isLocked}
          disablePortal
          id={`${props?.name}-filter-combo-box`}
          options={props?.data ?? []}
          onChange={(event: any, changedValue: any) => {
            formikProps?.setFieldValue(props?.name, changedValue);
            props?.handleSave(changedValue);
          }}
          value={props?.value ?? ""}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option, { inputValue }) => {
            const name = option;
            if (name) {
              const matches = match(name, inputValue);
              const parts = parse(name, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index: number) => (
                      <span
                        key={part.text}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }
            return <li {...props}>Not defined</li>;
          }}
        />
      );
    case "date":
      return (
        <TextField
          disabled={props?.isLocked}
          type="date"
          name={props?.name}
          value={props?.value}
          onChange={(e) => {
            props?.handleSave(
              e.target.value === "" ? undefined : e.target.value
            );
          }}
          {...formikProps}
        />
      );
    case "text":
      return (
        <VariableInput
          disabled={props?.isLocked}
          noHover={props?.isLocked}
          name={props?.name}
          label={undefined}
          labelClassName={styles.labelClass}
          type="text"
          value={props?.value}
          handleSave={props?.handleSave}
          {...formikProps}
        />
      );
    case "drop":
      const data = (props?.data ?? []).map((item) => ({ value: item }));
      return (
        <VariableDropdown
          disabled={props?.isLocked}
          noHover={props?.isLocked}
          name={props?.name}
          keyValue="value"
          data={data}
          value={props?.value}
          handleSave={props?.handleSave}
          setValue={(value) => formikProps?.setFieldValue(props?.name, value)}
        />
      );
    case "industry":
      return isFetchingIndustries ? (
        <SkeletonLoad bars={1} />
      ) : (
        !isFetchingIndustries && industries && Boolean(industries?.length) && (
          <VariableDropdown
            disabled={props?.isLocked}
            noHover={props?.isLocked}
            label=""
            labelClassName={styles.labelClass}
            name={props?.name}
            style={{ width: "100%" }}
            value={props?.value}
            keyValue="name"
            data={industries}
            handleSave={props?.handleSave}
            placeholder="Select Industry"
            setValue={(value) => formikProps?.setFieldValue(props?.name, value)}
            {...formikProps}
          />
        )
      );

    case "goods":
      const [creditReport, fieldName] = props?.name?.split(".");
      let industryFieldName = "businessIndustry";
      if (
        creditReport &&
        (formikProps?.values as Record<string, any>)?.[creditReport]
      )
        industryFieldName =
          Object.keys(
            (formikProps?.values as Record<string, any>)?.[creditReport]
          )?.find((item) => item?.toLowerCase()?.includes("industry")) ?? "";

      if (industryFieldName)
        return (
          <Goods
            industry={
              (formikProps?.values as Record<string, any>)?.[creditReport]?.[
                industryFieldName
              ] ?? ""
            }
            disabled={false || props?.isLocked}
            formikProps={formikProps}
            name={props?.name}
            industryFieldName={`${creditReport}.${industryFieldName}`}
            asComparison
            handleSave={props?.handleSave}
          />
        );
      return <LabelViewOnly label="Select Industry First" />;
    case "country":
      return isFetchingCountries ? (
        <SkeletonLoad bars={1} />
      ) : (
        !isFetchingCountries && countries && Boolean(countries?.length) && (
          <Autocomplete
            disabled={props?.isLocked}
            disablePortal
            size="small"
            id={`${props?.name}-filter-combo-box`}
            options={countries.map((country) => country.name)}
            onChange={(event: any, changedValue: any) => {
              if (changedValue) {
                formikProps?.setFieldValue(props?.name, changedValue);
                props?.handleSave(changedValue);
              }
            }}
            sx={{ maxHeight: "20" }}
            value={props?.value ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ maxHeight: 20 }}
                size="small"
                label="Select Country"
              />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option, inputValue);
              const parts = parse(option, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index: number) => (
                      <span
                        key={part.text}
                        style={{ fontWeight: part.highlight ? 300 : 200 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />
        )
      );

    default:
      return (
        <VariableInput
          disabled={props?.isLocked}
          noHover={props?.isLocked}
          name={props?.name}
          label={undefined}
          labelClassName={styles.labelClass}
          type="text"
          value={props?.value}
          handleSave={props?.handleSave}
          {...formikProps}
        />
      );
  }
};

export default InputComponent;

import { Grid, Typography } from "@mui/material";
import {
  useGetAccountRelationshipQuery,
  useGetBuyerCreditLimitQuery,
} from "api/api-accounts";
import SkeletonLoad from "components/Common/SkeletonLoad";

import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useEffect } from "react";
import type { Account } from "types";
import type { AccountSections } from "./AccountSectionTabs";
import {
  CL_APPROVED,
  CL_REJECTED,
  CL_REQUESTED,
  PENDING_SALES,
  BUYER,
} from "codes";

const AccountPartnerCreditLimit = ({
  account,
  partner,
}: {
  account: Account;
  partner: Account;
  onEnd: () => void;
}) => {
  const formikProps = useFormikContext<AccountSections>();
  const { setFieldValue } = formikProps;
  const { profile } = partner;
  const {
    data: availableCreditLimit,
    isFetching: isFetchingCreditLimit,
    status,
    refetch: refetchCL,
  } = useGetBuyerCreditLimitQuery(partner?.id ?? "", {
    skip: !account || profile !== BUYER.toLowerCase(),
  });

  const { data: relationship, refetch } = useGetAccountRelationshipQuery(
    {
      companyId: account?.id,
      partnerId: partner?.id ?? "",
    },
    { skip: !account || !partner?.id }
  );
  const IS_BUYER = profile === BUYER.toLowerCase();

  useEffect(() => {
    setFieldValue(
      "average_invoice_amount",
      relationship?.average_invoice_amount
    );
    setFieldValue("average_payment_terms", relationship?.average_payment_terms);
    setFieldValue(
      "number_of_past_paid_transaction",
      relationship?.number_of_past_paid_transaction
    );
    setFieldValue("any_credit_note", relationship?.any_credit_note);
    setFieldValue(
      "reason_for_credit_note",
      relationship?.reason_for_credit_note
    );
    setFieldValue(
      "any_delays_of_payment_with_buyer",
      relationship?.any_delays_of_payment_with_buyer
    );
    setFieldValue(
      "reason_of_any_delays_of_payment_with_buyer",
      relationship?.reason_of_any_delays_of_payment_with_buyer
    );
  }, [relationship, IS_BUYER]);

  return (
    <>
      {isFetchingCreditLimit && IS_BUYER && <SkeletonLoad bars={3} />}
      {!isFetchingCreditLimit && availableCreditLimit && IS_BUYER && (
        <Grid container spacing={1}>
          {(availableCreditLimit.status === CL_REQUESTED || availableCreditLimit.status === PENDING_SALES) && (
            <Grid item xs={12}>
              <Typography sx={{
                fontWeight: "bold",
                padding: 1,
                borderRadius: 1,
                fontSize: "1.2em",
                color: "warning.main"
              }}>
                Requested Credit Limit: {availableCreditLimit.currency}{" "}
                {currencyFormatter({
                  amount: availableCreditLimit.requested_amount || 0
                })}
              </Typography>
            </Grid>
          )}

          {availableCreditLimit.status === CL_REJECTED && (
            <Grid item xs={12}>
              <Typography sx={{
                fontWeight: "bold",
                padding: 1,
                borderRadius: 1,
                fontSize: "1.2em",
                color: "error.main"
              }}>
                Credit Limit Rejected
              </Typography>
            </Grid>
          )}

          {availableCreditLimit.status === CL_APPROVED && (

              <Grid item xs={12}>
                <Typography sx={{
                  fontWeight: "bold",
                  padding: 1,
                  borderRadius: 1,
                  fontSize: "1.2em",
                  color: "success.main"
                }}>
                  Available Credit Limit: {availableCreditLimit.currency}{" "}
                  {currencyFormatter({
                    amount: availableCreditLimit.approved_amount || 0
                  })}
                </Typography>
              </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default AccountPartnerCreditLimit;

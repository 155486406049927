import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Stack } from "@mui/material";
import {
  useApproveRiskMapMutation,
  useEditApproverRiskMapMutation,
  useGetRiskMapApprovalsQuery,
} from "api/api-maps";
import { useGetApproversQuery, useGetCurrentUserQuery } from "api/api-users-v2";
import MainCard from "components/Common/Cards/MainCard";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import { uniqueObject } from "helpers/unique";
import { MapContext } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";

const RiskScoringApproval = () => {
  const { data: approvers, isFetching } = useGetApproversQuery("RISK");
  const [managerApproval, setManagerApproval] = useState(false);
  const [approveMap] = useApproveRiskMapMutation();
  const [editApproverMap] = useEditApproverRiskMapMutation();
  const id = useContext(MapContext);
  const { data: approvals } = useGetRiskMapApprovalsQuery(id ?? "", {
    skip: id === undefined,
  });
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const { data: currentUser } = useGetCurrentUserQuery();

  const shouldDisableDelegation = (currentDecision?: string): boolean => {
    if (currentDecision === "Rejected") return true;
    if (currentDecision === "Revoked") return false;
    if (currentDecision === "Pending") return false;
    if (
      USER_PERMISSIONS.includes(PERMISSIONS.approval_level_head_risk) ||
      currentDecision === "Approved"
    )
      return true;
    return false;
  };

  const READ_ONLY = USER_PERMISSIONS.includes(PERMISSIONS.map_view_risk);
  
  const shouldDisableButton = (
    currentDecision: string,
    assign_contact_id?: string
  ): boolean => {
    if (assign_contact_id === null && managerApproval === false) return true;
    return currentUser?.id === assign_contact_id;
  };

  const members = uniqueObject(
    approvers?.map((item) => ({
      id: item.contact_id,
      approver_name: `${item.first_name} ${item.last_name}`,
      approver_title: item.title,
      approver_status: "Not Approved",
      approval_date: undefined,
      comments: "",
    })) ?? [],
    "approver_name"
  );

  const PHASES = ["Approved by Risk Manager"];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...PHASES.reduce(
          (
            acc: {
              [key: string]: {
                contact_id: string;
                approver_name: string;
                approval_date: Date | undefined;
                approval_status: string;
                approver_title: string;
                comments: string;
              };
            },
            curr
          ) => {
            acc[toCamelCase(curr)] = {
              contact_id: "",
              approver_name: "",
              approval_date: undefined,
              approval_status: "",
              approver_title: "",
              comments: "",
              ...approvals,
            };
            return acc;
          },
          {}
        ),
      }}
      onSubmit={console.log}
    >
      {(formikProps) => {
        return (
          <Form>
            <MainCard
              footer={
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  width="100%"
                >
                  <LoadingButton
                    variant="outlined"
                    color="error"
                    disabled={
                      shouldDisableButton(
                        formikProps.values["approvedByRiskManager"]
                          ?.approval_status,
                        formikProps.values["approvedByRiskManager"]?.contact_id
                      ) ||
                      formikProps.values["approvedByRiskManager"]
                        ?.approval_status === "Approved" ||
                      formikProps.values["approvedByRiskManager"]
                        ?.approval_status === "Rejected"
                    }
                    onClick={() =>
                      id &&
                      approveMap({
                        map_name: id,
                        data: {
                          approver:
                            formikProps.values["approvedByRiskManager"]
                              .contact_id,
                          approval_status: "Rejected",
                          comments:
                            formikProps.values["approvedByRiskManager"]
                              .comments,
                        },
                      })
                        .then(() => toast.success("Risk Map Rejected"))
                        .catch(() => toast.error("Error Rejecting Risk Map"))
                    }
                  >
                    Reject
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color={
                      formikProps.values["approvedByRiskManager"]
                        ?.approval_status === "Approved" ||
                      formikProps.values["approvedByRiskManager"]
                        ?.approval_status === "Rejected"
                        ? "warning"
                        : "success"
                    }
                    disabled={shouldDisableButton(
                      formikProps.values["approvedByRiskManager"]
                        ?.approval_status,
                      formikProps.values["approvedByRiskManager"]?.contact_id
                    )}
                    onClick={() => {
                      const isApproved =
                        formikProps.values["approvedByRiskManager"]
                          ?.approval_status === "Approved";
                      const isRejected =
                        formikProps.values["approvedByRiskManager"]
                          ?.approval_status === "Rejected";

                      id &&
                        approveMap({
                          map_name: id,
                          data: {
                            approver:
                              formikProps.values["approvedByRiskManager"]
                                ?.contact_id,
                            approval_status:
                              isApproved || isRejected ? "Revoked" : "Approved",
                            comments:
                              formikProps.values["approvedByRiskManager"]
                                ?.comments,
                          },
                        })
                          .unwrap()
                          .then(() => {
                            if (isApproved) {
                              toast.success("Approval has been revoked");
                            } else if (isRejected) {
                              toast.success("Rejection has been revoked");
                            } else {
                              toast.success("Risk Map Approved");
                            }
                          })
                          .catch(() => {
                            toast.error(
                              "Error updating Risk Map approval status"
                            );
                          });
                    }}
                  >
                    {formikProps.values["approvedByRiskManager"]
                      ?.approval_status === "Approved" ||
                    formikProps.values["approvedByRiskManager"]
                      ?.approval_status === "Rejected"
                      ? "Revoke"
                      : "Approve"}
                  </LoadingButton>
                </Stack>
              }
            >
              {isFetching && !approvers && <SkeletonLoad bars={2} />}
              {!isFetching && approvers && (
                <Stack spacing={2}>
                  {PHASES.map((phase) => (
                    <Stack direction="row" spacing={2} key={phase}>
                      <Grid item xs={12} md={4}>
                        <LabelViewOnly label={phase} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <VariableDropdown
                          name={`${toCamelCase(phase)}.approver_name`}
                          keyValue="approver_name"
                          data={members}
                          placeholder="Select Officer"
                          disabled={
                            shouldDisableDelegation(
                              formikProps.values[toCamelCase(phase)]
                                ?.approval_status
                            ) || READ_ONLY
                          }
                          noHover={
                            shouldDisableDelegation(
                              formikProps.values[toCamelCase(phase)]
                                ?.approval_status
                            ) || READ_ONLY
                          }
                          value={
                            formikProps.values[toCamelCase(phase)]
                              ?.approver_name
                          }
                          handleSave={(value) => {
                            if (
                              !shouldDisableDelegation(
                                formikProps.values[toCamelCase(phase)]
                                  ?.approval_status
                              )
                            ) {
                              id &&
                                value &&
                                editApproverMap({
                                  map_name: id,
                                  data: {
                                    approver:
                                      formikProps.values[toCamelCase(phase)]
                                        .contact_id,
                                  },
                                })
                                  .unwrap()
                                  .then((res) => {
                                    toast.success(
                                      `Successfully designated ${value} as an approver`
                                    );
                                    setManagerApproval(true);
                                  })
                                  .catch((err) =>
                                    toast.error(
                                      `Error designating approver: ${err?.message}`
                                    )
                                  );
                            }
                          }}
                          setValue={(value) => {
                            formikProps.setFieldValue(
                              `${toCamelCase(phase)}.approver_name`,
                              value
                            );
                            const contact_id = members.find(
                              (item) => item.approver_name === value
                            )?.id;
                            if (contact_id)
                              formikProps.setFieldValue(
                                `${toCamelCase(phase)}.contact_id`,
                                contact_id
                              );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <VariableInput
                          sx={{
                            ".MuiInputBase-root": {
                              height: "11vh",
                              textarea: {
                                height: "10vh !important",
                                overflow: "scroll !important",
                              },
                            },
                          }}
                          name={`${toCamelCase(phase)}.comments`}
                          label=""
                          placeholder="Enter Comment.."
                          multiline
                          style={{ width: "100%" }}
                          value={
                            formikProps.values["approvedByRiskManager"].comments
                          }
                          fullWidth
                          disabled={
                            shouldDisableDelegation(
                              formikProps.values[toCamelCase(phase)]
                                ?.approval_status
                            ) || READ_ONLY
                          }
                          noHover={
                            shouldDisableDelegation(
                              formikProps.values[toCamelCase(phase)]
                                ?.approval_status
                            ) || READ_ONLY
                          }
                          {...formikProps}
                        />
                      </Grid>
                    </Stack>
                  ))}
                </Stack>
              )}
              {!isFetching && !approvers && (
                <NoData text="No Approvers Found" />
              )}
            </MainCard>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RiskScoringApproval;

import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V1_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import { Country } from "./api-countries.d";

export const countries = createApi({
  reducerPath: "countriesDropDown",
  tagTypes: ["COUNTRIES"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V1_URL}/countries`,
  }),
  endpoints: (builder) => ({
    getCountries: builder.query<Country[], void>({
      query: () => ({
        url: "",
        method: "get",
      }),
      transformResponse: (response: { data: Country[] }) => response.data,
      providesTags: ["COUNTRIES"],
    }),
  }),
});

export const { useGetCountriesQuery } = countries;

import {
  Grid,
  Typography,
  Alert,
  AlertTitle,
  Button,
  Stack,
  Divider,
  InputAdornment,
} from "@mui/material";
import { useGetBuyerCreditLimitQuery } from "api/api-accounts";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Formik } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import type { Account, RegisterLeadPartnerCreditLimit } from "types";
import Input from "components/Common/Input";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCreateLeadPartnerCreditLimitMutation } from "api/api-origination";
import valueCleaner from "helpers/valueCleaner";
import { toast } from "react-toastify";

import {
  CL_APPROVED,
  CL_REJECTED,
  CL_REQUESTED,
  PENDING_SALES,
  PRODUCTS,
  BUYER,
  CL_DEFAULT
} from "codes";

const AvailableCreditLimit = ({
  buyer,
  disabled,
  navigateTab,
}: {
  buyer: Account;
  disabled?: boolean;
  navigateTab?: (index: number) => void;
}) => {
  const { profile, account_product } = buyer;
  const {
    data: availableCreditLimit,
    isFetching: isFetchingCreditLimit,
    status,
    refetch: refetchCL,
  } = useGetBuyerCreditLimitQuery(buyer?.id ?? "", {
    skip: profile !== BUYER.toLowerCase(),
  });
  const [
    createLeadPartnerCreditLimit,
    { isLoading: isLoadingCreateLeadPartnerCreditLimit },
  ] = useCreateLeadPartnerCreditLimitMutation();
  const CURRENCY = "USD";

  const SUCCESFUL_CL_CALL =
    !isFetchingCreditLimit && availableCreditLimit && status !== "rejected";
  const NO_CL = !isFetchingCreditLimit && status === "rejected";

  const createLeadCLActions = (requested_amount: string) => {
    const createLeadCLRequestBody: RegisterLeadPartnerCreditLimit = {
      requested_amount: valueCleaner(requested_amount),
      lead_id: buyer?.id ?? "",
    };
    createLeadPartnerCreditLimit(createLeadCLRequestBody)
      .unwrap()
      .then((res) => {
        toast(`Credit Limit Submitted for  ${buyer?.name}`, {
          type: "success",
        });
        refetchCL();
      })
      .catch((e) => {
        toast(e?.message ?? "An error occured", {
          type: "error",
        });
      });
  };

  return (
    <>
      {isFetchingCreditLimit && <SkeletonLoad bars={10} />}
      {NO_CL && account_product === PRODUCTS.factoring && (
        <Grid item xs={12}>
          <NoData text="No Credit Limit Available" />
        </Grid>
      )}
      {NO_CL && navigateTab && account_product === PRODUCTS.reverse_factoring && (
        <Formik
          initialValues={{
            requested_amount: currencyFormatter({ amount: CL_DEFAULT }),
          }}
          onSubmit={console.log}
        >
          {(formikProps) => (
            <Grid item xs={12}>
              <Alert severity={disabled ? "error" : "info"}>
                <Stack spacing={1}>
                  <AlertTitle sx={{ fontSize: "1.5em", fontWeight: "bold" }}>
                    Request a Credit Limit{" "}
                    {disabled ? "(Please complete the Details to proceed)" : ""}
                  </AlertTitle>
                  <Divider />
                  <Typography fontSize="1.5em">
                    Please ensure that the Company Registration No in
                    <Button onClick={() => navigateTab(0)}>
                      Registration Details
                    </Button>{" "}
                    & Annual Revenue in
                    <Button onClick={() => navigateTab(1)}>
                      Additional Details
                    </Button>{" "}
                    regarding <strong>{buyer?.name}</strong> are completed to
                    request a Credit Limit as per the Business Requirements.
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="end">
                    <Grid item xs={6}>
                      <Input
                        name="requested_amount"
                        label="Credit Limit"
                        type="money"
                        textfieldProps={{
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                {CURRENCY}
                              </InputAdornment>
                            ),
                          },
                        }}
                        style={{ width: "100%" }}
                        disabled={
                          disabled || !formikProps.values.requested_amount
                        }
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <LoadingButton
                        fullWidth
                        onClick={() => {
                          createLeadCLActions(
                            formikProps.values.requested_amount
                          );
                        }}
                        variant="contained"
                        type="button"
                        loading={isLoadingCreateLeadPartnerCreditLimit}
                        disabled={
                          disabled || !formikProps.values.requested_amount
                        }
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Stack>
                </Stack>
              </Alert>
            </Grid>
          )}
        </Formik>
      )}
      {SUCCESFUL_CL_CALL && (
              <Grid container spacing={2}>
                  {(availableCreditLimit.status === CL_REQUESTED || availableCreditLimit.status === PENDING_SALES) && (
                    <Grid item xs={12}>
                      <Typography sx={{
                        fontWeight: "bold",
                        padding: 1,
                        borderRadius: 1,
                        fontSize: "1.2em",
                        color: "warning.main"
                      }}>
                        Requested Credit Limit: {availableCreditLimit.currency}{" "}
                        {currencyFormatter({
                          amount: availableCreditLimit.requested_amount || 0
                        })}
                      </Typography>
                    </Grid>
                  )}
        
                  {availableCreditLimit.status === CL_REJECTED && (
                    <>
                    <Grid item xs={12}>
                      <Typography sx={{
                        fontWeight: "bold",
                        padding: 1,
                        borderRadius: 1,
                        fontSize: "1.2em",
                        color: "error.main"
                      }}>
                        Credit Limit Rejected
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                    <Typography sx={{
                      fontWeight: "bold",
                      padding: 1,
                      borderRadius: 1,
                      fontSize: "1.2em"
                    }}>
                      Requested Credit Limit: {availableCreditLimit.currency}{" "}
                      {currencyFormatter({
                        amount: availableCreditLimit.requested_amount || 0
                      })}
                    </Typography>
                  </Grid>
                  </>
                  )}
        
                  {availableCreditLimit.status === CL_APPROVED && (
                    <>
                      <Grid item xs={12}>
                        <Typography sx={{
                          fontWeight: "bold",
                          padding: 1,
                          borderRadius: 1,
                          fontSize: "1.2em",
                          color: "success.main"
                        }}>
                          Approved Credit Limit: {availableCreditLimit.currency}{" "}
                          {currencyFormatter({
                            amount: availableCreditLimit.approved_amount || 0
                          })}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={12}>
                        <Typography sx={{
                          fontWeight: "bold",
                          padding: 1,
                          borderRadius: 1,
                          fontSize: "1.2em"
                        }}>
                          Requested Credit Limit: {availableCreditLimit.currency}{" "}
                          {currencyFormatter({
                            amount: availableCreditLimit.requested_amount || 0
                          })}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
      )}
    </>
  );
};

export default AvailableCreditLimit;

import moment from "moment";
import type { CLDetails, CreditLimit } from "types";

const initialSalesLeadValue = {
  email: "",
  first_name: "Unassigned",
  id: "",
  last_name: "Unassigned",
  mobile: "",
  opted_marketing_updates: false,
  title: "",
};
export const formatCL = (item: CreditLimit): CLDetails => {
  const coface_json = item.coface_json?.icon;
  return {
    reqId: item.name ?? "",
    internalStatus: item.internal_status,
    buyerName: item.buyer?.name ?? "Not Available",
    buyerInternalName: item.buyer?.internal_name ?? "Not Available",
    // eslint-disable-next-line no-extra-boolean-cast
    buyerRegNo: item.buyer?.reg_no ?? "Not Available",
    buyerAddress: `${item.buyer?.address_line1} ${item.buyer?.address_line2 ?? ""} ${item.buyer?.postcode ?? ""} ${item.buyer?.city ?? ""}`,
    buyerCountry: item.buyer?.country ?? "Not Available",
    buyerEmail: "Not Available",
    buyerAnnualRevenue: item.buyer?.annual_revenue ?? 0,
    //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    insurerResponse: item.insurer_response_date
      ? moment(item.insurer_response_date).format("YYYY-MM-DD HH:mm:ss")
      : ("Not Available" as any),

    // eslint-disable-next-line no-extra-boolean-cast
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    salesLead: item.sales_lead?.first_name
      ? item.sales_lead
      : initialSalesLeadValue,
    salesName: `${item.sales_lead?.first_name} ${item.sales_lead?.last_name}`,
    buyerSource: item?.source ?? "",
    product: item?.buyer?.account_product ?? "Not Available",
    clStatus: item.status,
    reqAmount: Number(item.requested_amount),
    credit_limit_request_internal_amount:
      item.credit_limit_request_internal_amount ?? 0,
    credit_committee_amount: item.credit_committee_amount ?? 0,
    currency: item.currency,
    buyerId: item.buyer?.id,
    //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dateRequested: item.requested_date
      ? moment(item.requested_date).utc().format("YYYY-MM-DD HH:mm:ss")
      : "-",
    rating: item?.coface_rating,
    // coface_rating_description: item?.coface_rating_description,
    // coface_amount: item?.coface_amount,
    coface_currency: item?.coface_currency,
    // coface_found: item?.coface_found,
    coface_status: item?.coface_status,
    requested_to_coface_by: item?.requested_to_coface_by,
    buyerIsIdentified: Boolean(item.buyer?.is_identified),
    sf_link: item.sf_link,
    insurer: item?.insurer,
    requestedBy: item?.requested_by,
    contact_description: item?.send_message_to?.contact_description,
    email: item?.send_message_to?.email,
    client_name: item?.send_message_to?.name,
    source: item?.buyer?.source ?? "",
    leadCreatedBy: item.buyer?.created_by,
    decision_at: item.decision_at,
    decision_by: item.decision_by,
    coface_last_update: item.coface_last_update,
    coface_json,
    ...item,
  };
};

import { Divider, Grid, Typography, useTheme, Button } from "@mui/material";
import moment from "moment";
import { Dispatch, Fragment, SetStateAction, useEffect } from "react";
import Modal from "components/Common/Modal";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";
import { CustomColumn } from "../..";
import { filterAction, getColumns } from "../../processor";
import FilterChip from "../FilterChip";
import SingleDateFilter from "./SingleDateFilter";
import SingleRadio from "./SingleRadio";
import SingleRangeFilter from "./SingleRangeFilter";
import SingleTextAc from "./SingleTextAc";
import { modalStyling } from "./styling";
import { useGetCountriesQuery } from "api/api-countries";
import { useGetCommonKeysQuery } from "api/api-status";

interface DataTableFilterModalProps<T> {
  data: T[];
  initialData: T[];
  filters: Record<string, any>;
  setFilters: (filters: Record<string, any>) => void;
  open: boolean;
  handleClose: () => void;
  setFilteredData: Dispatch<SetStateAction<T[]>>;
  customColumns: CustomColumn;
  clearFilters: () => void;
  columnsToHide?: string[];
  filterColumnTableName?: Record<string, any>;
  onFilterChange: (filters: Record<string, any>) => void;
  onSubmit: (filters: string) => void;
}

const FilterModal = <T extends Record<string, any> = {}>({
  filterColumnTableName,
  data,
  initialData,
  open,
  handleClose,
  setFilteredData,
  filters,
  setFilters,
  columnsToHide,
  customColumns,
  clearFilters,
  onFilterChange,
  onSubmit,
}: DataTableFilterModalProps<T>) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  const { 
    data: countries, 
    isLoading: countriesLoading, 
    isError: countriesError 
  } = useGetCountriesQuery();

  const countriesList = countries || [];

  const { 
    data: statusData, 
    isLoading, 
    isError, 
    error,
    refetch 
  } = useGetCommonKeysQuery();

  console.log("statusData", statusData)

  const { messageContainerProps, modalContentSx, modalOverlaySx, modalSx } =
    modalStyling({ isMobile, theme });    
  
  // CRM FUNCTION CUSTOM COLUMNS FOR FILTERS
  const accountDetailsFilterColumns = ['country', 'status', 'created_at'];
  const termsheetsFilterColumns = ['termsheet_status', 'submitted_by'];
  const creditLimitFilterColumns = ['buyerCountry', 'salesName', 'internalStatus', 'buyerSource', 'approved_amount', 'dateRequestedMktg', 'dateRequested'];
  const leadFilterColumns = ['country', 'created_at', 'profile'];
  const mapFilterColumns = ['seller', 'product', 'status'];
  const usersFilterColumns = ['title', 'opted_marketing_updates', 'sign_up', 'last_login'];
  const countryFilterColumns = ['name'];
  const billingFilterColumns = ['invoice_status'];
  const emailFilterColumns = ['recipients', 'sender', 'status'];

  // CRM REPORTS CUSTOM COLUMNS FOR FILTERS
  const onBoardingPipelineFilterColumns = [
    "internal_status",
    "buyer_country",
    "seller_country",
  ];

  // User management custom columns for filters
  const userManagementFilterColumns = ['groups', 'departments', 'sign_up', 'last_login', 'active'];

  // CRM FUNCTION TABLES
  const isAccountDetails = filterColumnTableName && "account_details" in filterColumnTableName;
  const isTermSheetDetails = filterColumnTableName && "termsheet_details" in filterColumnTableName;
  const isCreditLimitDetails = filterColumnTableName && "creditlimit_details" in filterColumnTableName;
  const isLeadDetails = filterColumnTableName && "leads_details" in filterColumnTableName;
  const isMapDetails = filterColumnTableName && "maps_details" in filterColumnTableName;
  const isUserDetails = filterColumnTableName && "users_details" in filterColumnTableName;
  const isCountryDetails = filterColumnTableName && "countries_details" in filterColumnTableName;
  const isBillingDetails = filterColumnTableName && "billing_details" in filterColumnTableName;
  const isEmailDetails = filterColumnTableName && "email_details" in filterColumnTableName;

  // CRM REPORTS TABLE
  const isOnBoardingPipelineDetails = filterColumnTableName && "user_type_details" in filterColumnTableName;
  const isUserManagementDetails = filterColumnTableName && "user_management_details" in filterColumnTableName;

  type StatusOptions = {
    status?: Record<string, string>;
    termsheet_status?: Record<string, string>;
    internalStatus?: Record<string, string>;
    invoice_status?: Record<string, string>;
    internal_status?: Record<string, string>;
    onboarding_status?: Record<string, string>;
  };
  
  const getStatusOptions = (): StatusOptions => {
    if (isAccountDetails) {
      return {
        status: {
          Registered: "REGISTERED",
          Onboarded: "ONBOARDED",
          Internal: "INTERNAL",
          Created: "CREATED",
          Onboarding: "ONBOARDING",
          Presales: "PRESALES",
        },
      };
    }
    if (isTermSheetDetails) {
      return {
        termsheet_status: {
          Rejected: "REJECTED_INTERNAL",
          "Pending Approval": "PENDING_INTERNAL",
          "In Progress": "IN_PROGRESS",
          Approved: "APPROVED",
          "Customer Rejected": "REJECTED",
          "Invoice expired": "INVOICE_CANCELLED",
          "Termsheet expired": "TERMSHEET_CANCELLED",
        },
      };
    }
    if (isCreditLimitDetails) {
      return {
        internalStatus: {
          "Credit Limit Rejected": "CL_REJECTED",
          "Rejected by sales": "REJECTED_BY_SALES",
          "Rejected by insurer": "REJECTED_BY_INSURER",
          "Pending Insurer": "CL_REQUESTED",
          "Pending Credit Limit Approval": "CL_REQUESTED",
          "Pending Sales": "PENDING_SALES",
          "Credit Limit Approved": "CL_APPROVED",
        },
      };
    }
    if (isBillingDetails) {
      return {
        invoice_status: {
          Approved: "PAID",
          Pending: "PAYMENT_PENDING",
          Rejected: "EXPIRED",
        },
      };
    }
    if (isMapDetails) {
      return {
        status: {
          Approved: "true",
          Pending: "false",
        },
      };
    }
    if (isEmailDetails) {
      return {
        status: {
          Sent: "SENT",
          Delivered: "DELIVERED",
          Failed: "FAILED",
        },
      };
    }
    if (isOnBoardingPipelineDetails) {
      return {
        internal_status: {
          "Credit Limit Rejected": "CL_REJECTED",
          "Rejected by sales": "REJECTED_BY_SALES",
          "Rejected by insurer": "REJECTED_BY_INSURER",
          "Pending Insurer": "CL_REQUESTED",
          "Pending Credit Limit Approval": "CL_REQUESTED",
          "Pending Sales": "PENDING_SALES",
          "Credit Limit Approved": "CL_APPROVED",
        },
        // onboarding_status: {
        //   "Submitted": "true",
        //   "Pending": "false"
        // },
        // buyer_details_completed: {
        //   "Completed": "true",
        //   "Pending": "false"
        // },
        // seller_details_completed: {
        //   "Completed": "true",
        //   "Pending": "false"
        // },
        // seller_documents_completed: {
        //   "Completed": "true",
        //   "Pending": "false"
        // },
        // buyer_documents_completed: {
        //   "Completed": "true",
        //   "Pending": "false"
        // },
        // seller_has_bank_account: {
        //   "Yes": "true",
        //   "No": "false"
        // }
      };
    }
    if (isUserManagementDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          userManagementFilterColumns.includes(key)
        )
      );
    }
    return {};
  };
  
  const statusOptions = getStatusOptions();
  
  const getStatusDisplayValue = (statusKey: keyof StatusOptions, apiValue: string): string => {
    const statusMapping = statusOptions[statusKey];
    if (statusMapping) {
      const displayValue = Object.keys(statusMapping).find(
        (key) => statusMapping[key] === apiValue
      );
      return displayValue || apiValue;
    }
    return apiValue;
  };

  const getStatusApiValue = (statusKey: string, displayValue: string): string => {
    const statusMapping = statusOptions[statusKey as keyof typeof statusOptions];
    if (statusMapping && statusMapping[displayValue]) {
      return statusMapping[displayValue];
    }
    return displayValue;
  };

  const filteredCustomColumns: CustomColumn = (() => {
    if (isAccountDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          accountDetailsFilterColumns.includes(key)
        )
      );
    }
    if (isTermSheetDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          termsheetsFilterColumns.includes(key)
        )
      );
    }
    if (isCreditLimitDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          creditLimitFilterColumns.includes(key)
        )
      );
    }
    if (isLeadDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          leadFilterColumns.includes(key)
        )
      );
    }
    if (isMapDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          mapFilterColumns.includes(key)
        )
      );
    }
    if (isUserDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          usersFilterColumns.includes(key)
        )
      );
    }
    if (isCountryDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          countryFilterColumns.includes(key)
        )
      );
    }
    if (isBillingDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          billingFilterColumns.includes(key)
        )
      );
    }
    if (isEmailDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          emailFilterColumns.includes(key)
        )
      );
    }
    if (isOnBoardingPipelineDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          onBoardingPipelineFilterColumns.includes(key)
        )
      );
    }

    if (isUserManagementDetails) {
      return Object.fromEntries(
        Object.entries(customColumns || {}).filter(([key]) => 
          userManagementFilterColumns.includes(key)
        )
      );
    }
    
    return customColumns;
  })();

  const headers = getColumns(initialData).filter((item) => {
    if (columnsToHide?.includes(item)) return false;
    
    if (isAccountDetails) {
      return accountDetailsFilterColumns.includes(item);
    }
    
    if (isTermSheetDetails) {
      return termsheetsFilterColumns.includes(item);
    }

    if (isCreditLimitDetails) {
      return creditLimitFilterColumns.includes(item);
    }

    if (isLeadDetails) {
      return leadFilterColumns.includes(item);
    }

    if (isMapDetails) {
      return mapFilterColumns.includes(item);
    }

    if (isUserDetails) {
      return usersFilterColumns.includes(item);
    }

    if (isCountryDetails) {
      return countryFilterColumns.includes(item);
    }

    if (isBillingDetails) {
      return billingFilterColumns.includes(item);
    }

    if (isEmailDetails) {
      return emailFilterColumns.includes(item);
    }
    
    if (isOnBoardingPipelineDetails) {
      return onBoardingPipelineFilterColumns.includes(item);
    }

    if (isUserManagementDetails) {
      return userManagementFilterColumns.includes(item);
    }
    
    return true;
  });

  useEffect(() => {
    setFilteredData(filterAction(filters, initialData));
    onFilterChange(filters);
  }, [filters]);

  const customColumnsExist = filteredCustomColumns && !isObjectEmpty(filteredCustomColumns);

  let textHeaders = headers;
  let numberHeaders: Record<string, any>[] = [];
  let dateHeaders: Record<string, any>[] = [];
  let boolHeaders: string[] = [];

  if (customColumnsExist) {
    // Get explicitly defined text headers from customColumns
    textHeaders = Object.entries(filteredCustomColumns)
      .filter(
        ([key, value]) =>
          (value.type === undefined || value.type === "text") &&
          !value.noFilter
      )
      .map(([key]) => key);

    // Get untyped headers that aren't explicitly handled
    const unTyped = headers.filter((header) => {
      // Skip if header is already in textHeaders
      if (textHeaders.includes(header)) return false;
      
      // Skip if header is a status field that's already handled
      if ((header === 'status' || header.endsWith('_status') || header === 'internalStatus' || header === 'internal_status') &&
          Object.keys(statusOptions).includes(header)) {
        return false;
      }
      
      return (
        !Object.keys(filteredCustomColumns).includes(header) ||
        (filteredCustomColumns[header]?.type === undefined && 
         !filteredCustomColumns[header]?.noFilter)
      );
    });

    textHeaders = [...textHeaders, ...unTyped];

    boolHeaders = Object.entries(filteredCustomColumns)
      .filter(
        ([key, value]) =>
          value.type === "boolean" &&
          !value.noFilter
      )
      .map(([key]) => key);

    numberHeaders = Object.entries(filteredCustomColumns)
      .filter(
        ([key, value]) =>
          value.type === "number" &&
          !value.noFilter
      )
      .map(([key]) => ({ name: key }));

    dateHeaders = Object.entries(filteredCustomColumns)
      .filter(
        ([key, value]) =>
          value.type === "date" &&
          !value.noFilter
      )
      .map(([key]) => {
        const dateRange = filters[key]?.keywords;
        if (dateRange) {
          const formattedDateRange = dateRange;
          return { name: key, formattedDateRange };
        }
        return { name: key };
      });
  }

  const handleSubmit = () => {
    const formattedFilters = { ...filters };

    // Format date filters
    Object.entries(filteredCustomColumns).forEach(([key, columnDef]) => {
      if (columnDef.type === "date" && formattedFilters[key]?.keywords) {
        formattedFilters[key].keywords = formattedFilters[key].keywords.map((date: string | null) => 
          date ? moment(date, ['YYYY/MM/DD', 'DD/MM/YYYY']).format('YYYY/MM/DD') : null
        );
      }
    });

    // Convert status filter values to their CAPS versions
    Object.entries(statusOptions).forEach(([statusKey, statusValue]) => {
      if (formattedFilters[statusKey]?.keywords) {
        if (Array.isArray(formattedFilters[statusKey].keywords)) {
          // Handle array of values
          formattedFilters[statusKey].keywords = formattedFilters[statusKey].keywords.map(
            (val: string) => getStatusApiValue(statusKey, val)
          );
        } else {
          // Handle single value
          formattedFilters[statusKey].keywords = getStatusApiValue(
            statusKey,
            formattedFilters[statusKey].keywords
          );
        }
      }
    });

    if (typeof onSubmit === 'function') {
      onSubmit(JSON.stringify(formattedFilters));
    } else {
      setFilteredData(filterAction(formattedFilters, initialData));
    }

    handleClose();
  };

  const FilterBody = () => {
    return (
      <>
        {!isObjectEmpty(filters) && (
          <>
            <Grid item xs={12} lg={11}>
              <FilterChip
                filters={filters}
                clearFilters={clearFilters}
                customColumns={filteredCustomColumns}
                getStatusDisplayValue={getStatusDisplayValue}
              />
            </Grid>
            <Grid item xs={10}>
              <Divider />
            </Grid>
          </>
        )}

        <Grid item xs={12} lg={10} sx={{ mb: 0 }}>
          <Grid container spacing={1}>
            {textHeaders.map((header) => {
              if ((header === "country" || header === "name" || header === "buyerCountry" || header === "seller_country" || header === "buyer_country") && countriesList.length > 0) {
                return (
                  <Grid item lg={4} xs={12} key={header}>
                    <SingleTextAc
                      header={header}
                      data={countriesList.map((country) => country.name)}
                      filters={filters}
                      setFilters={setFilters}
                      customFieldName={
                        customColumnsExist
                          ? filteredCustomColumns?.[header]?.[header]
                          : ""
                      }
                    />
                  </Grid>
                );
              }

              if (header === "status" || header.endsWith("_status") || header === "internalStatus" || header === "internal_status") {
                const statusMapping = statusOptions[header as keyof typeof statusOptions];
                
                if (statusMapping) {
                  return (
                    <Grid item lg={4} xs={12} key={header}>
                      <SingleTextAc
                        header={header}
                        data={Object.keys(statusMapping)}
                        filters={filters}
                        setFilters={setFilters}
                        customFieldName={
                          customColumnsExist
                            ? filteredCustomColumns?.[header]?.[header]
                            : ""
                        }
                        getStatusApiValue={(value: string) => 
                          getStatusApiValue(header, value)
                        }
                      />
                    </Grid>
                  );
                }
              }

              return (
                <Grid item lg={4} xs={12} key={header}>
                  <SingleTextAc
                    header={header}
                    data={[
                      ...new Set(initialData.map((option) => option[header])),
                    ]}
                    filters={filters}
                    setFilters={setFilters}
                    customFieldName={
                      customColumnsExist
                        ? filteredCustomColumns?.[header]?.[header]
                        : ""
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {Boolean(boolHeaders.length) && (
          <>
            <Grid item xs={10}>
              <Divider id="bool-header-divider" />
            </Grid>
            <Grid item xs={12} lg={10}>
              <Grid container>
                {boolHeaders.map((header) => (
                  <Grid item lg={4} xs={12} key={header}>
                    <SingleRadio
                      header={header}
                      data={initialData}
                      filters={filters}
                      setFilters={setFilters}
                      boolTitles={
                        filteredCustomColumns?.[header]?.boolTitles ?? [
                          "True",
                          "False",
                        ]
                      }
                      customFieldName={
                        customColumnsExist
                          ? filteredCustomColumns?.[header]?.[header]
                          : ""
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}

        {Boolean(numberHeaders.length) && (
          <>
            <Grid item xs={10}>
              <Divider id="number-header-divider" />
            </Grid>
            <Grid item xs={12} lg={10}>
              <Grid container>
                {numberHeaders.map((header) => {
                  const numberArray = initialData.map((item) => {
                    if (typeof item[header.name] === "number")
                      return item[header.name];
                    return Number(item[header.name]?.replaceAll(",", ""));
                  });

                  return (
                    <Grid item xs={12} key={header.name}>
                      <SingleRangeFilter
                        label={header.name}
                        customColumns={filteredCustomColumns}
                        filters={filters}
                        setFilters={setFilters}
                        range={[
                          Math.min(...numberArray),
                          Math.max(...numberArray),
                        ]}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}

        {Boolean(dateHeaders.length) && (
          <>
            <Grid item xs={10}>
              <Divider id="date-header-divider" />
            </Grid>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={1}>
                {dateHeaders.map((header) => {
                  const standardize = (val: string) =>
                    moment(val).format("YYYY-MM-DD");

                  const dateSet = data
                    .filter((x) => x[header.name])
                    .map((item) => moment(standardize(item[header.name])))
                    .filter((item) => item.isValid());

                  const minima = moment.min(dateSet).toDate();
                  const maxima = moment.max(dateSet).toDate();

                  return (
                    <Fragment key={header.name}>
                      <Grid item xs={12}>
                        <SingleDateFilter
                          filters={filters}
                          customColumns={filteredCustomColumns}
                          range={[minima, maxima]}
                          label={header.name}
                          setFilters={setFilters}
                        />
                      </Grid>
                      {dateHeaders.length > 1 && (
                        <Grid item xs={10}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  const Header = (
    <Grid
      item
      xs={12}
      lg={12}
      sx={{
        margin: "1ch 2vh",
        display: "flex",
        justifyContent: "flex-end",
      }}
      id="filter-container"
    >
      <Typography variant="h3" fontWeight="bold">
        Filters
      </Typography>
    </Grid>
  );

  const Footer = (
    <Grid
      item
      xs={12}
      lg={12}
      sx={{ margin: "1ch 2vh", display: "flex", justifyContent: "flex-end" }}
    >
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Grid>
  );

  return (
    <Modal
      width={isMobile ? "95vw" : "80vw"}
      height={isMobile ? "100vh" : "80vh"}
      modalContentSx={modalContentSx}
      Header={Header}
      message={<FilterBody />}
      open={open}
      onClose={handleClose}
      messageContainerProps={messageContainerProps}
      closeButton
      modalOverlaySx={modalOverlaySx}
      sx={modalSx}
      footer={Footer}
    />
  );
};

export default FilterModal;

import { createApi } from "@reduxjs/toolkit/query/react";
import { CURRENCIES, VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { AddInvoice, AddInvoiceDetails, Invoice, SupportingDocument } from "types";

import type {
  ApproveListing,
  CompanyProfile,
  Ewallet,
  MapProduct,
  MarketplaceCurrency,
  PHASE,
  PickList,
  Stats,
  Transaction,
  WithdrawalDeposit,
  MarketplacePartnerGroupedByCurrency,
} from "./api-marketplace.d";

const formatCurrencies = (accountCurrencies: string[]) => {
  const currencies = Object.entries(CURRENCIES)
    ?.filter(
      ([key]) => !accountCurrencies || accountCurrencies.includes(key)
    )
    .map(([key, value]) => ({
      label: key,
      value: key,
      ...value,
    }));
  return currencies;
}

export const marketplace = createApi({
  reducerPath: "marketplace",
  tagTypes: [
    "INVOICES",
    "PICKLIST",
    "SUPPORTING_DOCS",
    "EWALLETS",
    "TRANSACTIONS",
    "MAP_DETAILS",
    "CURRENCIES",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/marketplace`,
  }),
  endpoints: (builder) => ({
    getMarketplacePartners: builder.query<
      MarketplacePartnerGroupedByCurrency,
      string
    >({
      query: (id) => ({ url: `/accounts/${id}/partners`, method: "get" }),
      transformResponse: (response) => response?.data,
    }),
    getInvoiceListByUser: builder.query<
      Invoice[],
      { account_id: string; partner_id: string; phase: PHASE }
    >({
      query: ({ account_id, partner_id, phase }) => ({
        url: `/accounts/${account_id}/partners/${partner_id}/invoices?phase=${phase}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["INVOICES"],
    }),
    getPartnerMarketplaceDetails: builder.query<
      CompanyProfile,
      { account_id: string; partner_id: string }
    >({
      query: ({ account_id, partner_id }) => ({
        url: `/accounts/${account_id}/partners/${partner_id}/map-details`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["INVOICES"],
    }),
    getPicklists: builder.query<PickList, null>({
      query: () => ({ url: `/picklists`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["PICKLIST"],
    }),
    getEwallets: builder.query<Ewallet[], string>({
      query: (id) => ({ url: `/accounts/${id}/ewallets`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["EWALLETS"],
    }),
    getCurrencies: builder.query<MarketplaceCurrency[], string>({
      query: (id) => ({ url: `/accounts/${id}/currencies`, method: "get" }),
      transformResponse: (response) => formatCurrencies(response?.data),
      providesTags: ["CURRENCIES"],
    }),
    getTransactions: builder.query<Transaction[], string>({
      query: (id) => ({ url: `/accounts/${id}/transactions`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["TRANSACTIONS"],
    }),
    getStats: builder.query<Stats, string>({
      query: (account_id) => ({
        url: `/accounts/${account_id}/invoices/stats`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getSupportingDocuments: builder.query<
      SupportingDocument[],
      { accountId: string; partnerId: string }
    >({
      query: (params) => ({
        url: `/accounts/${params.accountId}/partners/${params.partnerId}/supporting-documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUPPORTING_DOCS"],
    }),
    getUploadedSupportingDocuments: builder.query<
      {
        document_type: string;
        attachments: string[];
        document_id: string;
        file_name: string;
        upload_date: string;
      }[],
      string
    >({
      query: (invoice_id) => ({
        url: `/invoices/${invoice_id}/documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUPPORTING_DOCS"],
    }),
    getInvoiceById: builder.query<any, string>({
      query: (invoice_id) => ({
        url: `/invoices/${invoice_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    addInvoice: builder.mutation<string, AddInvoice>({
      query: (data) => {
        const { account_id, partner_id, invoice_details } = data;
        return {
          url: `/accounts/${account_id}/partners/${partner_id}/invoices`,
          method: "POST",
          data: invoice_details,
        };
      },
      invalidatesTags: ["INVOICES"],
      transformResponse: (response) => response?.data,
    }),
    addInvoiceBatch: builder.mutation<
    {invoice_ids: string[], "error_messages": string[]},
    { account_id: string; partner_id: string; invoices: AddInvoiceDetails[] }
    >({
      query: (data) => {
        const { account_id, partner_id, invoices } = data;
        return {
          url: `/accounts/${account_id}/partners/${partner_id}/batch-invoices`,
          method: "POST",
          data: invoices,
        };
      },
      invalidatesTags: ["INVOICES"],
      transformResponse: (response) => response?.data,
    }),
    submitWithdrawal: builder.mutation<
      any,
      { id: string; data: WithdrawalDeposit }
    >({
      query: ({ id, data }) => {
        return {
          url: `/accounts/${id}/withdrawal-deposit`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["EWALLETS"],
      transformResponse: (response) => response?.data,
    }),
    approveListing: builder.mutation<any, { id: string; data: ApproveListing }>(
      {
        query: ({ id, data }) => {
          return {
            url: `/accounts/${id}/approve-listing`,
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["INVOICES"],
        transformResponse: (response) => response?.data,
      }
    ),
    postSupportingDocuments: builder.mutation<
      { invoice_id: string; supporting_documents: [] },
      { file: File; supporting_document_id: string; invoice_id: string }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("required_document_id", data.supporting_document_id);
        return {
          url: `/invoices/${data.invoice_id}/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      invalidatesTags: ["SUPPORTING_DOCS"],
      transformResponse: (response) => response?.data,
    }),
    getMapProducts: builder.query<
      // not used
      MapProduct,
      { account_id: string; partner_id: string; currency: string }
    >({
      query: ({ account_id, partner_id, currency }) => ({
        url: `/${account_id}/products?partner_id=${partner_id}&currency=${currency}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getAccountBanks: builder.query<
      {
        bank_name: string;
        currency: string;
        bank_account_number: string;
        address: string;
        beneficiary_name: string;
        estimated_transfer_time: string;
        swift_code: string;
        sf_bank_account_id: string;
      }[],
      string
    >({
      // will be used in withdrawal
      query: (account_id) => ({
        url: `/accounts/${account_id}/banks`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    generateSupplierRequest: builder.mutation<
      string,
      {
        invoice_id: string;
        bank: {
          bank_name: string;
          bank_account_number: string;
          address: string;
          beneficiary_name: string;
          pre_payment: boolean;
        };
      }
    >({
      query: (data) => {
        return {
          url: `/invoices/${data.invoice_id}/supplier-request`,
          method: "POST",
          data: data.bank,
        };
      },
      transformResponse: (response) => response?.data,
    }),
    signSupplierRequest: builder.mutation<
      string,
      {
        invoice_id: string;
        bank: {
          bank_name: string;
          bank_account_number: string;
          address: string;
          beneficiary_name: string;
          pre_payment: boolean;
        };
      }
    >({
      query: (data) => {
        return {
          url: `/invoices/${data.invoice_id}/supplier-request/signature`,
          method: "POST",
          data: data.bank,
        };
      },
      transformResponse: (response) => response?.data,
    }),
    getBuyerRequest: builder.query<string, string>({
      query: (invoice_id) => ({
        url: `/invoices/${invoice_id}/buyer-request`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    signBuyerRequest: builder.mutation<string, string>({
      query: (invoice_id) => ({
        url: `/invoices/${invoice_id}/buyer-request/signature`,
        method: "POST",
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetMarketplacePartnersQuery,
  useGetInvoiceListByUserQuery,
  useGetInvoiceByIdQuery,
  useAddInvoiceMutation,
  useAddInvoiceBatchMutation,
  useGetPicklistsQuery,
  useGetSupportingDocumentsQuery,
  usePostSupportingDocumentsMutation,
  useGetStatsQuery,
  useGetEwalletsQuery,
  useGetCurrenciesQuery,
  useGetTransactionsQuery,
  useSubmitWithdrawalMutation,
  useApproveListingMutation,
  useGetUploadedSupportingDocumentsQuery,
  useGetMapProductsQuery,
  useGetPartnerMarketplaceDetailsQuery,
  useGetAccountBanksQuery,
  useGenerateSupplierRequestMutation,
  useSignSupplierRequestMutation,
  useGetBuyerRequestQuery,
  useSignBuyerRequestMutation,
  useLazyGetBuyerRequestQuery,
} = marketplace;

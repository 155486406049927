import { FormikProps } from "formik";
import { AddPartnerType } from "helpers/formFields/tradingRelationship";

export const nameHasSimilarRegNo = (
  data: any[],
  formikProps: FormikProps<AddPartnerType>
) => {
  const regNo = formikProps.values.registration_number?.trim().toLowerCase();
  if (!regNo) return undefined; // Ignore empty registration number

  return data?.find(
    (item) =>
      item?.reg_no?.toLowerCase().includes(regNo) ||
      item?.registration_number?.toLowerCase().includes(regNo)
  );
};

export const hasSimilarName = (
  data: any[],
  formikProps: FormikProps<AddPartnerType>
) => {
  const name = formikProps.values.name?.trim().toLowerCase();
  if (!name) return undefined; // Ignore empty name

  return data?.find((item) => item?.name?.toLowerCase().includes(name));
};

export const hasSimilarRegNo = (
  data: any[],
  formikProps: FormikProps<AddPartnerType>
) => {
  const regNo = formikProps.values.registration_number?.trim().toLowerCase();
  if (!regNo) return undefined; // Ignore empty registration number

  return data?.find(
    (item) =>
      item?.reg_no?.toLowerCase().includes(regNo) ||
      item?.registration_number?.toLowerCase().includes(regNo)
  );
};

export const hasSameRegNo = (
  data: any[],
  formikProps: FormikProps<AddPartnerType>
) => {
  const regNo = formikProps.values.registration_number?.trim().toLowerCase();
  if (!regNo) return undefined; // Ignore empty registration number

  return data?.find(
    (item) =>
      item?.reg_no?.toLowerCase().includes(regNo) ||
      item?.registration_number?.toLowerCase().includes(regNo)
  );
};

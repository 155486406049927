import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import { Email } from "pages/emails/types";

export const emailsrtk = createApi({
  reducerPath: "emailsrtk",
  tagTypes: ["EMAILS"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/emails`,
  }),
  endpoints: (builder) => ({
    // getEmails: builder.query<Email[], null>({
    //   query: () => ({
    //     url: ``,
    //     method: "get",
    //   }),
    //   transformResponse: (response) => response?.data,
    //   providesTags: ["EMAILS"],
    // }),

    getEmails: builder.query<any, { emailparams: any }>({
      query: ({emailparams}) => ({
        url: `${emailparams}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["EMAILS"],
    }),

    // getAccounts: builder.query<any, { accountparams: any }>({
    //   query: ({ accountparams }) => ({
    //     url: `${ACCOUNTS_V2}${accountparams}`,
    //     method: "get",
    //   }),
    //   transformResponse: (response) => response?.data,
    //   providesTags: ["ACCOUNTS"],
    // }),

    getEmail: builder.query<Email, string>({
      query: (email_id) => ({
        url: `/${email_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["EMAILS"],
    }),
  }),
});

export const { useGetEmailsQuery, useGetEmailQuery } = emailsrtk;

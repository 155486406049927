import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Grid, Stack, useTheme } from "@mui/material";
import {
  useGetDashboardTermsheetListQuery,
  useGetLeadsQuery,
} from "api/api-accounts";
import {
  useGetCreditLimitsQuery,
  useGetOnboardingStatusesQuery,
} from "api/api-origination";
import CreditLimitRequest from "assets/navigationIcons/creditLimitRequests.svg?react";
import LeadsIcon from "assets/navigationIcons/leads.svg?react";
import { CREDIT_LIMITS_LINK, INCOMLEND_INTERNAL_LINK, LEADS } from "codes";
import InfoCard, { type InfoCardProps } from "components/Common/InfoCard";
import SkeletonLoad from "components/Common/SkeletonLoad";
import TermsheetToDo from "components/Termsheets/TermsheetToDo";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
interface MemberInfoCard extends InfoCardProps {
  isFetching: boolean;
}
const creditlimitsparams = "";
const OriginationMember: React.FC = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { data: creditiLimits, isFetching: isFetchingCreditLimits } =
    useGetCreditLimitsQuery({ creditlimitsparams });

  const leadsparams = ``;
  const { data: leads, isFetching: isFetchingLeads } = useGetLeadsQuery({
    leadsparams,
  });
  const { data: onboardings, isFetching: isFetchingOnboardings } =
    useGetOnboardingStatusesQuery(null);
  const {
    data: termsheetTodos,
    isFetching: isFetchingTermsheetTodos,
    refetch: refetchTermsheetTodos,
  } = useGetDashboardTermsheetListQuery(null);
  let overviewData: MemberInfoCard[] = [
    {
      title: "Credit Limits",
      subtitle: `${creditiLimits?.pagination?.total ?? 0} limits registered`,
      onClick: () =>
        history.push(`${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <CreditLimitRequest />,
      bottomColorBand: `1ch solid ${theme.palette.primary.main}`,
      height: isMobile ? 70 : 120,
      isFetching: isFetchingCreditLimits,
    },
    {
      title: "Leads",
      subtitle: `${leads?.pagination?.total ?? 0} leads added`,
      onClick: () => history.push(`${INCOMLEND_INTERNAL_LINK}${LEADS}`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <LeadsIcon />,
      bottomColorBand: `1ch solid ${theme.palette.error.main}`,
      height: isMobile ? 70 : 120,
      isFetching: isFetchingLeads,
    },
    {
      title: "Onboardings",
      subtitle: `${onboardings?.filter((account) => account.onboarding_status)?.length ?? 0} of ${onboardings?.length ?? 0} completed`,
      onClick: () => history.push(`${INCOMLEND_INTERNAL_LINK}/onboardings`),
      backgroundColor: theme.palette.background.default,
      horizontal: true,
      headingProps: {
        variant: isMobile ? "h2" : "h3",
        fontWeight: "bolder",
        fontSize: isMobile ? "1em" : "1.5em",
      },

      borderRadius: "10px",
      titleContainerSpacing: isMobile ? 0 : 0.5,
      sidebarChildren: <DoneAllIcon />,
      bottomColorBand: `1ch solid ${theme.palette.secondary.main}`,
      height: isMobile ? 70 : 120,
      isFetching: isFetchingOnboardings,
    },
  ];

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1}>
        {overviewData &&
          overviewData.map((item, i) => {
            const { isFetching, ...rest } = item;
            if (isFetching)
              return <SkeletonLoad bars={3} key={item?.className} />;
            return (
              <Grid key={item?.className} item xs={12} lg={4}>
                <InfoCard {...rest} />
              </Grid>
            );
          })}
      </Stack>
      {isFetchingTermsheetTodos && <SkeletonLoad bars={5} />}
      {!isFetchingTermsheetTodos && termsheetTodos && (
        <TermsheetToDo
          termsheetTodos={termsheetTodos}
          syncAction={refetchTermsheetTodos}
        />
      )}
    </React.Fragment>
  );
};

export default OriginationMember;

import { InputAdornment, Stack, Typography, useTheme } from "@mui/material";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";

import {
  useGetCreditLimitQuery,
  usePostCofaceManualInputMutation,
} from "api/api-origination";
import Modal from "components/Common/Modal";
import React from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface ReviewModalProps {
  showManualInputModal: {
    visible: boolean;
  };
  setShowManualInputModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
    }>
  >;
}

const ManualInputModal: React.FC<ReviewModalProps> = ({
  showManualInputModal,
  setShowManualInputModal,
}) => {
  const theme = useTheme();
  const { id } = useParams();

  const [postCofaceManualInput] = usePostCofaceManualInputMutation();

  const { data: creditLimit, refetch } = useGetCreditLimitQuery(id ?? "", {
    skip: !Boolean(id),
  });

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  const confirmClick = ({
    credit_limit_id,
    rating,
    indication,
    description,
  }: {
    credit_limit_id: string;
    rating: number;
    indication: string;
    description: string;
  }) => {
    const indicationNumber = parseFloat(indication);
    if (isNaN(indicationNumber)) {
      toast("Indication must be a number", {
        type: "error",
      });
      return;
    }

    postCofaceManualInput({
      credit_limit_id,
      rating,
      indication: indicationNumber,
      description,
    })
      .unwrap()
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong : ${errorFile}`, {
          type: "error",
        });
      })
      .then(refetch);
    setShowManualInputModal({
      visible: false,
    });
  };

  const currency = "USD";

  return (
    <Formik
      onSubmit={console.log}
      initialValues={{
        rating: 0,
        indication: "",
        Description: "",
      }}
    >
      {(formikProps) => (
        <Form>
          <Modal
            Header={
              <Typography fontWeight="bold">Enter coface response:</Typography>
            }
            message={
              <Stack mt={1} px={1} spacing={1} width="100%">
                <Input
                  name="indication"
                  label="Indication"
                  placeholder="Indication"
                  textfieldProps={{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency ?? "USD"}
                        </InputAdornment>
                      ),
                    },
                  }}
                  type="money"
                  style={{ width: "100%" }}
                />
                <Input
                  name="rating"
                  label="Rating"
                  required
                  style={{ width: "100%" }}
                  type="number"
                  {...formikProps}
                />
                <Input
                  sx={{
                    ".MuiInputBase-root": {
                      height: "22vh",
                      backgroundClip: "border-box",
                      borderRadius: "10px",
                      textarea: {
                        height: "20vh !important",
                        overflow: "scroll !important",
                      },
                    },
                  }}
                  name="Description"
                  label="Description"
                  placeholder="Enter descrption"
                  {...formikProps}
                  multiline
                  style={{ width: "100%" }}
                />
              </Stack>
            }
            sx={modalSx}
            width={isMobile ? "90%" : "40%"}
            height="auto"
            modalContentSx={{ height: "auto" }}
            open={showManualInputModal.visible}
            modalOverlaySx={modalOverlaySx}
            modalFooterSx={{
              maxWidth: "100%",
              justifyContent: "space-between",
            }}
            onClose={() =>
              setShowManualInputModal({
                visible: false,
              })
            }
            closeButton
            primary={{
              variant: "contained",
              fullWidth: true,
              onClick: () => {
                confirmClick({
                  credit_limit_id: creditLimit?.id ?? "",
                  rating: formikProps.values.rating,
                  indication: formikProps.values.indication,
                  description: formikProps.values.Description,
                });
              },
              children: `Confirm`,
              sx: { minWidth: "12vw" },
            }}
            secondary={{
              variant: "outlined",
              fullWidth: true,
              onClick: () =>
                setShowManualInputModal({
                  visible: false,
                }),
              children: `Cancel`,
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ManualInputModal;

import { FormHelperText, Grid, type SxProps } from "@mui/material";
import { type FormikProps } from "formik";
import { Fragment } from "react";
import type { CompanyDoc } from "types";
import DocumentUploadField from "./DocumentUploadField";
import DocumentUploadSelect from "./DocumentUploadSelect";

interface Item {
  id: string;
  name: string;
  label: string;
  value: string;
  description: string;
  items: Item[];
  template?: string;
  yearly_set_count?: number;
  count_unique_uploaded: number;
}

interface DocumentUploadControllerProps {
  data: Item[];
  formikProps: FormikProps<any>;
  isPresales?: boolean;
  initialData: CompanyDoc[];
  onUpload: (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    idx: number
  ) => void;
  onView: (fileName: string, id: string, idx: number) => void;
  onDelete?: (fileName: string, id: string, idx: number) => void;
  enableCount?: boolean;
  deleteDisabled?: boolean;
  viewDisabled?: boolean;
  uploadDisabled?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  documentTypeDescription?: string;
  acceptFileTypes?: string;
  isLoadingSubmission?: boolean;
  sx?: SxProps;
  containerSx?: SxProps;
  isLocked?: boolean;
  allowTint?: boolean;
  disableRenderIfEmpty?: boolean;
}
const DocumentUploadController: React.FC<DocumentUploadControllerProps> = ({
  data,
  formikProps,
  isPresales,
  initialData,
  onUpload,
  onView,
  onDelete,
  enableCount = true,
  deleteDisabled = false,
  viewDisabled = false,
  uploadDisabled = false,
  disabled,
  isLoading = false,
  documentTypeDescription,
  acceptFileTypes,
  isLoadingSubmission,
  sx,
  containerSx,
  isLocked,
  allowTint = false,
  disableRenderIfEmpty = false,
}) => {
  return (
    <Grid item xs={12}>
      <Grid container sx={{ mt: 1, ...containerSx }}>
        {data &&
          data.map((item) => {
            const docs = initialData?.filter(
              (init) => init.document_type === item.id
            );
            const fieldError: string = formikProps.errors?.[
              `${item.id}-0`
            ] as string;
            const isErrorField = Boolean(fieldError);
            const DISABLE_RENDER_IF_EMPTY_CONDITION =
              disableRenderIfEmpty && docs?.length === 0;

            if (DISABLE_RENDER_IF_EMPTY_CONDITION)
              return <Fragment key={item.name}></Fragment>;
            return (
              <Fragment key={item.name}>
                <Grid item xs={12} margin="auto" sx={{ mt: 2 }}>
                  {item.items?.length ? (
                    <Grid item xs={12}>
                      <DocumentUploadSelect
                        item={item}
                        onUpload={onUpload}
                        formikProps={formikProps}
                        isPresales={isPresales}
                        documents={docs}
                        initialData={initialData}
                        onView={onView}
                        onDelete={onDelete}
                        enableCount={enableCount}
                        deleteDisabled={deleteDisabled}
                        viewDisabled={viewDisabled}
                        uploadDisabled={uploadDisabled}
                        disabled={disabled}
                        isLoading={isLoading}
                        isLoadingSubmission={isLoadingSubmission}
                        sx={sx}
                        allowTint={allowTint}
                      />
                    </Grid>
                  ) : (
                    <DocumentUploadField
                      item={item}
                      onUpload={onUpload}
                      formikProps={formikProps}
                      isPresales={isPresales}
                      documents={docs}
                      onView={onView}
                      onDelete={onDelete}
                      enableCount={enableCount}
                      deleteDisabled={deleteDisabled}
                      disabled={disabled}
                      isLoading={isLoading}
                      viewDisabled={viewDisabled}
                      uploadDisabled={uploadDisabled}
                      documentTypeDescription={documentTypeDescription}
                      acceptFileTypes={acceptFileTypes}
                      isLoadingSubmission={isLoadingSubmission}
                      sx={sx}
                      allowTint={allowTint}
                    />
                  )
                  }
                </Grid>
                {isErrorField && (
                  <Grid item xs={12}>
                    <FormHelperText error>{fieldError}</FormHelperText>
                  </Grid>
                )}
              </Fragment>
            );
          })}
      </Grid>
    </Grid>
  );
};
export default DocumentUploadController;

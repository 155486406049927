import { Box, Chip, Grid, Slide, Stack, Typography, useTheme } from "@mui/material";
import { useGetEmailQuery, useGetEmailsQuery } from "api/api-emails";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useRef, useState } from "react";
import styles from "./sales.module.scss";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";
import { Email } from "pages/emails/types";
import { Close } from "@mui/icons-material";

const Emails = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  
  const searchValue = useAppSelector((state) => state.search.value);
  const [queryParams, setQueryParams] = useState({
    per_page: 10,
    page: 1,
    search: searchValue || undefined,
  });
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch]);

  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    if (searchValue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setQueryParams((prev) => ({ ...prev, page: 1, search: searchValue || undefined }));
    }
  }, [searchValue]);

  const {
    data: emailsList,
    isFetching,
    isLoading,
    refetch,
  } = useGetEmailsQuery(
    { 
      emailparams: `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries({
            ...queryParams,
            ...activeFilters
          })
            .filter(([_, value]) => value !== undefined)
        ) as Record<string, string>
      )}` 
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleRefetch = async () => {
    await refetch();
    setQueryParams({
      per_page: 10,
      page: 1,
      search: undefined
    });
    setActiveFilters({});
    setFilters({});
    dispatch(clearAll());
  };

  const handleFilterSubmit = (filtersString: string) => {
    const filtersObj = JSON.parse(filtersString);
    setFilters(filtersObj);
    const newFilters: Record<string, string> = {};

    for (const key in filtersObj) {
      if (filtersObj[key]?.keywords) {
        if (key === "created_at" && typeof filtersObj[key].keywords === "string") {
          newFilters[key] = filtersObj[key].keywords;
        } else if (Array.isArray(filtersObj[key].keywords)) {
          newFilters[key] = filtersObj[key].keywords.join(",");
        }
      }
    }

    setActiveFilters(newFilters);
    setQueryParams((prev) => ({
      ...prev,
      ...newFilters,
      page: 1
    }));
  };

  const handleRemoveFilter = (filterKey: string) => {
    const newFilters = { ...activeFilters };
    delete newFilters[filterKey];
    setActiveFilters(newFilters);
    setQueryParams((prev) => ({
      ...prev,
      [filterKey]: undefined,
      page: 1
    }));
  };

  const handleClearAllFilters = () => {
    setActiveFilters({});
    setQueryParams((prev) => ({
      ...prev,
      ...Object.fromEntries(Object.keys(activeFilters).map((key) => [key, undefined])),
      page: 1
    }));
  };

  const handlePageChange = (newPage: number, newPerPage: number) => {
    setQueryParams((prev) => ({
      ...prev,
      page: newPage,
      per_page: newPerPage
    }));
  };

  const [selectedEmailId, setSelectedEmailId] = useState<string>("");
  const { data: email } = useGetEmailQuery(selectedEmailId, { skip: !selectedEmailId });

  const formatEmailsForTable = (data: Email[] = []) => {
    return data.map((item) => ({
      ...item,
      recipients: item.recipients?.join(", "),
      bcc: item.bcc?.join(", "),
      status: item?.status || "sent"
    }));
  };

  const formattedData = formatEmailsForTable(emailsList?.email_details);
  const headers = Object.keys(formattedData?.[0] ?? {});
  const headerLength = headers?.length;
  const defaultColumnWidth = width ? width / headerLength : 200;

  const customColumns = {
    id: {
      id: "ID",
      minWidth: 100,
      type: "text",
      renderCell: ({ value }: { value: string }) => (
        <Typography 
          sx={{ 
            fontWeight: 500,
            color: "primary.main",
            cursor: "pointer",
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
          onClick={() => setSelectedEmailId(value)}
        >
          #{value.slice(0, 8)}
        </Typography>
      ),
    },
    subject: {
      subject: "Subject",
      minWidth: 250,
      type: "text",
      renderCell: ({ value }: { value: string }) => (
        <Typography 
          sx={{ 
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer"
          }}
          onClick={() => setSelectedEmailId(value)}
        >
          {value || "No Subject"}
        </Typography>
      ),
    },
    sender: {
      sender: "From",
      minWidth: 200,
      type: "text",
      renderCell: ({ value }: { value: string }) => (
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {value}
        </Typography>
      ),
    },
    recipients: {
      recipients: "To",
      minWidth: 200,
      type: "text",
      renderCell: ({ value }: { value: string }) => (
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {value}
        </Typography>
      ),
    },
    bcc: {
      bcc: "BCC",
      minWidth: 150,
      type: "text",
      renderCell: ({ value }: { value: string }) => (
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: value ? "inherit" : "text.secondary",
            fontStyle: value ? "normal" : "italic"
          }}
        >
          {value || "None"}
        </Typography>
      ),
    },
    timestamp: {
      timestamp: "Date",
      minWidth: 180,
      type: "text",
      renderCell: ({ value }: { value: string }) => (
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: "0.875rem"
          }}
        >
          {new Date(value).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })}
        </Typography>
      ),
    },
    status: {
      status: "Status",
      minWidth: 120,
      type: "text",
      renderCell: ({ value }: { value: string }) => {
        const statusConfig = {
          sent: { color: 'success', text: 'SENT' },
          failed: { color: 'error', text: 'FAILED' },
          draft: { color: 'warning', text: 'DRAFT' },
          queued: { color: 'info', text: 'QUEUED' }
        }[value.toLowerCase()] || { color: 'default', text: value };

        return (
          <Box
            sx={{
              backgroundColor: `${statusConfig.color}.light`,
              color: `${statusConfig.color}.contrastText`,
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: "0.75rem",
              fontWeight: "bold",
              textAlign: "center",
              textTransform: "uppercase",
              width: "fit-content"
            }}
          >
            {statusConfig.text}
          </Box>
        );
      },
    }
  };

  const modalOverlaySx = {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1ch",
    padding: "1ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };

  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex", width: "100%" }} flexGrow={1} ref={ref}>
        <Layout
          title="Emails"
          backArrow={false}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.5em" : "1.5em",
          }}
          headerConfig={{
            syncAction: handleRefetch,
            left: 8,
            mid: 0,
            right: 2,
            xs: { left: 12, mid: 0, right: 0 },
            alignItems: "center",
          }}
          className={styles.layout}
          mode="default"
          headerChildren={
            Object.keys(activeFilters).length > 0 && (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                flexWrap: 'wrap',
                mt: 1,
                mb: 1,
                px: 2,
                width: '100%'
              }}>
                <Typography variant="body2" color="text.secondary">
                  Filters:
                </Typography>
                {Object.entries(activeFilters).map(([key, value]) => (
                  <Chip
                    key={key}
                    label={`${key}: ${value}`}
                    onDelete={() => handleRemoveFilter(key)}
                    deleteIcon={<Close />}
                    size="small"
                    sx={{ 
                      backgroundColor: 'primary.light',
                      color: 'primary.contrastText'
                    }}
                  />
                ))}
                <Chip
                  label="Clear all"
                  onClick={handleClearAllFilters}
                  size="small"
                  sx={{ 
                    backgroundColor: 'grey.300',
                    color: 'text.primary',
                    '&:hover': {
                      backgroundColor: 'grey.400'
                    }
                  }}
                />
              </Box>
            )
          }
        >
          {(isFetching || isLoading) && <SkeletonLoad bars={10} />}

          {!isFetching && !isLoading && (
            <Grid item xs={12} mt={2} px={3}>
              {formattedData && formattedData.length > 0 ? (
                <DataTablev2
                  filterColumnTableName={emailsList}
                  onFilterSubmit={handleFilterSubmit}
                  pagination={emailsList?.pagination}
                  defaultColumnWidth={defaultColumnWidth}
                  onPaginationChange={handlePageChange}
                  data={formattedData}
                  rowsPerPage={rowsPerPage}
                  customColumns={customColumns}
                  onRowClicked={(row) => setSelectedEmailId(row?.id)}
                  initialFilters={filters}
                  setFilters={setFilters}
                  sx={{
                    table: {
                      minWidth: "100% !important",
                      overflowX: "auto",
                    },
                  }}
                />
              ) : (
                <NoData
                  text="No emails found"
                  style={{ margin: "1em auto", width: "100%" }}
                />
              )}
            </Grid>
          )}

          <Modal
            message={
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography fontWeight="bold">From:</Typography>
                    <Typography>{email?.sender}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography fontWeight="bold">To:</Typography>
                    <Typography>{email?.recipients?.join(", ")}</Typography>
                  </Stack>
                  {email?.bcc && email.bcc.length > 0 && (
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography fontWeight="bold">BCC:</Typography>
                      <Typography>{email.bcc.join(", ")}</Typography>
                    </Stack>
                  )}
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography fontWeight="bold">Date:</Typography>
                    <Typography>
                      {new Date(email?.timestamp || "").toLocaleString()}
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      borderTop: 1,
                      borderColor: "divider",
                      pt: 2,
                      mt: 1,
                    }}
                  />
                  <Stack
                    dangerouslySetInnerHTML={{ __html: email?.body ?? "" }}
                    margin="auto"
                    sx={{
                      overflowWrap: "break-word",
                      width: "100%",
                      "& p": { margin: "0.5em 0" },
                      "& a": { color: "primary.main" },
                    }}
                  />
                </Stack>
              </Grid>
            }
            sx={modalSx}
            Header={
              <Typography fontSize="1.5em" fontWeight="bold">
                {email?.subject}
              </Typography>
            }
            width={isMobile ? "90%" : "70%"}
            height="80vh"
            open={selectedEmailId !== ""}
            modalOverlaySx={modalOverlaySx}
            modalFooterSx={{ maxWidth: "100%" }}
            onClose={() => setSelectedEmailId("")}
            closeButton
            modalHeaderSx={{ backgroundColor: "transparent" }}
          />
        </Layout>
      </Box>
    </Slide>
  );
};

export default Emails;

import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  useAssociateDocsMutation,
  useDeleteDocMutation,
  useGetDocumentUrlQuery,
} from "api/api-accounts";
import {
  useGetComplianceTemplatesQuery,
  useGetEDDDocumentsQuery,
} from "api/api-compliance";
import {
  useEditEvaluationMutation,
  useGetEvaluationValuesQuery,
  useGetMapSummaryQuery,
} from "api/api-maps";
import { SUPPLIER } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import DocView from "components/Common/DocView";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { Formik } from "formik";
import { MapContext } from "pages/maps/map";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { Item } from "../CreditReport/types";

const ComplianceEvaluation = ({
  accountType,
  isLocked,
}: {
  accountType: string;
  isLocked: boolean;
}) => {
  const mapName = React.useContext(MapContext);
  const { data: evalutationTemplateValues } =
    useGetComplianceTemplatesQuery("evaluation");

  const labels = useMemo(
    () => Object.values(evalutationTemplateValues ?? {}),
    [evalutationTemplateValues]
  );
  const [addDocument, { isLoading: isLoadingSubmission }] =
    useAssociateDocsMutation();
  const [deleteDocument] = useDeleteDocMutation();
  const [patchValues] = useEditEvaluationMutation();
  const { data: evaluationValues, isLoading } = useGetEvaluationValuesQuery(
    { map_name: mapName ?? "", account_type: accountType },
    { skip: !Boolean(mapName) }
  );

  const [answers, setAnswers] = useState<string[]>([]);
  const [yesAnswers, setYesAnswers] = useState<number[]>([]);

  useEffect(() => {
    if (evaluationValues) {
      const initialAnswers = labels.map((label) => {
        const evaluation = evaluationValues.find((e) => e.id === label.id);
        return evaluation ? evaluation.value : "";
      });
      setAnswers(initialAnswers);

      const initialYesAnswers = initialAnswers
        .map((value, index) => (value === "yes" ? index + 1 : null))
        .filter((index) => index !== null) as number[];
      setYesAnswers(initialYesAnswers);
    }
  }, [evaluationValues, labels]);

  const handleRadioChange = useCallback(
    (index: number, value: string, id: string) => {
      if (mapName) {
        setAnswers((prevAnswers) => {
          const newAnswers = [...prevAnswers];
          newAnswers[index] = value;

          setYesAnswers((prevYesAnswers) => {
            let newYesAnswers;
            if (value === "yes") {
              newYesAnswers = [...prevYesAnswers, index + 1];
            } else {
              newYesAnswers = prevYesAnswers.filter((i) => i !== index + 1);
            }
            return newYesAnswers.sort((a, b) => a - b);
          });

          const payload = {
            map_metadata_id: id,
            value: value,
            source: "",
          };
          patchValues({
            map_name: mapName,
            account_type: accountType as "supplier" | "buyer",
            data: payload,
          });

          return newAnswers;
        });
      }
    },
    [mapName, accountType, patchValues]
  );

  const { data: mapData } = useGetMapSummaryQuery(mapName ?? "", {
    skip: !mapName,
  });

  const accountId =
    mapData &&
    (accountType?.toLowerCase() === SUPPLIER?.toLowerCase()
      ? mapData?.seller_id
      : mapData?.buyer_id);

  const {
    data: eddDocuments,
    isLoading: isEddLoading,
    refetch: refetchDocuments,
  } = useGetEDDDocumentsQuery(accountId ?? "", {
    skip: !Boolean(accountId),
  });

  const to_upload: Item[] =
    eddDocuments && eddDocuments.length > 0
      ? eddDocuments
          ?.map((doc) => {
            return {
              id: doc.id,
              name: doc.name,
              label: "Enhanced Due Diligence",
              value: "",
              description: `Enhanced Due Diligence`,
              items: [],
              yearly_set_count: 1,
              count_unique_uploaded:
                eddDocuments?.filter((d) => d.document_type === doc.id)
                  .length ?? 0,
            } as Item;
          })
          .filter((item): item is Item => item !== null)
      : [
          {
            name: "Enhanced Due Diligence",
            label: "Enhanced Due Diligence",
            value: "Enhanced Due Diligence",
            items: [],
            id: "",
            description: "",
            yearly_set_count: 1,
            count_unique_uploaded: 0,
          },
        ];
  const [document_id, setId] = useState<string>();

  const { data: documentUrl, refetch: refetchDocUrl } = useGetDocumentUrlQuery(
    {
      account_id: accountId ?? "",
      document_id: document_id ?? "",
    },
    { skip: !Boolean(document_id) }
  );
  const [show, setShow] = useState<boolean>(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);
  const fetchDocument = (id: string) => {
    setId(id);
    refetchDocUrl();
    onOpen();
  };

  const handleSubmitEddDocument = async (data: {
    file: File;
    year: number | null;
  }) => {
    addDocument({
      id: accountId ?? "",
      data: { file: data.file, document_type: "edd", document_category: "edd" },
    })
      .unwrap()
      .then((response) => {
        refetchDocuments();
        toast(`Uploaded ${response.data.name} successfully!`, {
          type: "success",
        });
      })
      .catch((error: any) => {
        toast(`Failed to upload document: ${error.message}`, { type: "error" });
      });
  };

  const handleDeleteDocument = async (id: string) => {
    deleteDocument({
      account_id: accountId ?? "",
      id: id,
    })
      .unwrap()
      .then(() => {
        refetchDocuments();
        toast("Document deleted successfully!", { type: "success" });
      })
      .catch((error: any) => {
        toast(`Failed to delete document: ${error.message}`, { type: "error" });
      });
  };

  if (isLoading || !evaluationValues) {
    return (
      <Box
        flexGrow={1}
        p={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box flexGrow={1} p={2}>
      <Stack spacing={2}>
        {yesAnswers.length > 0 && (
          <Alert severity="error">
            The following questions were answered with "Yes" :{" "}
            {yesAnswers.join(", ")}
          </Alert>
        )}
        <MainCard
          title={
            <Typography variant="h3" gutterBottom textAlign="center">
              Risk Evaluation
            </Typography>
          }
          subheader={
            <Typography variant="subtitle1" gutterBottom textAlign="center">
              If you have answered YES to any of the below, the client may be
              considered high risk. For Enhanced Due Diligence requirements,
              refer to note below.
            </Typography>
          }
        >
          <>
            {labels.map((item, index) => (
              <Fragment key={item.id}>
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  sx={{ marginTop: 2 }}
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={10}>
                    <Typography>
                      {index + 1}
                      {". "}
                      {item.name}
                      {answers[index] === "yes" && (
                        <Typography component="span" color="error">
                          {" "}
                          (Answered Yes)
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2} mr="auto">
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        value={answers[index] ?? ""}
                        onChange={(e) =>
                          handleRadioChange(index, e.target.value, item.id)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          disabled={isLocked}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          disabled={isLocked}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Stack>
                {index !== labels.length - 1 && (
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                )}
              </Fragment>
            ))}
          </>
          <Stack spacing={1} p={1}>
            <FormHelperText>
              ^ Where the Company does not assess a country with a corruption
              score of below 30 as High Risk, please note.
            </FormHelperText>
            <FormHelperText>
              + Sanctions mean countries that are the subject of the United
              Nations Security Council Resolutions or any countries or persons
              which are the subject of specific sanctions (such as those imposed
              by the governments of the USA or Singapore), The full list of US
              sanctioned countries is available at the OFAC website.
            </FormHelperText>
          </Stack>
        </MainCard>
        <MainCard
          title={
            <Typography variant="h3" gutterBottom textAlign="center">
              Enhanced Due Diligence (“EDD”)
            </Typography>
          }
        >
          <>
            <Typography>
              Group senior management must sign off on any high risk individual.
              An EDD file note (please use template) must be submitted for
              approval with the following information, where applicable:
            </Typography>
            <ul>
              <li>Reason for Client to be classified as High Risk</li>
              <li>
                Rationale as to why client relationship is particularly
                important
              </li>
              <li>
                For PEPs, the EDD file note must also include the following
                information:
                <ul>
                  <li>Identity of the PEP linked to the client relationship</li>
                  <li>Reason for being classified as politically-exposed</li>
                </ul>
              </li>
              <li>
                Has source of wealth and source of funds been established?
              </li>
              <li>
                What are the relationships the Client has with other Financial
                Institutions?
              </li>
              <li>
                If Client is a Corporation, in addition to the questions above,
                the Firm also has to identify the relationships between the
                client and the ultimate beneficial owners.
              </li>
            </ul>
          </>
          <Stack spacing={1} p={1}>
            <FormHelperText>
              ^ Where the Company does not assess a country with a corruption
              score of below 30 as High Risk, please note.
            </FormHelperText>
            <FormHelperText>
              + Sanctions mean countries that are the subject of the United
              Nations Security Council Resolutions or any countries or persons
              which are the subject of specific sanctions (such as those imposed
              by the governments of the USA or Singapore), The full list of US
              sanctioned countries is available at the OFAC website.
            </FormHelperText>
          </Stack>
          <Stack spacing={1} p={1}>
            {isEddLoading && <SkeletonLoad bars={4} />}
            <Formik initialValues={{}} enableReinitialize onSubmit={() => {}}>
              {(formikProps) => {
                return (
                  <>
                    <DocumentUploadController
                      data={to_upload}
                      formikProps={formikProps}
                      initialData={eddDocuments ?? []}
                      onUpload={(file) => handleSubmitEddDocument(file)}
                      onView={(fileName, id) => fetchDocument(id)}
                      onDelete={(fileName, id) => handleDeleteDocument(id)}
                      isLoadingSubmission={isLoadingSubmission}
                      isLoading={isLoading}
                      enableCount={false}
                    />
                    <Modal
                      modalOverlaySx={{ borderRadius: "2ch" }}
                      width="30%"
                      height="100%"
                      closeButton
                      message={
                        <Grid item xs={12}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            {documentUrl?.url?.includes(".pdf?") ? (
                              <Grid item xs={12} id="modal-docview">
                                <DocView uri={documentUrl?.url} staticView />
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <img
                                  src={documentUrl?.url}
                                  alt={documentUrl?.url}
                                  width="100%"
                                  height="auto"
                                  style={{
                                    borderRadius: "2ch",
                                    margin: "auto",
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      }
                      open={show}
                      onClose={onClose}
                    />
                  </>
                );
              }}
            </Formik>
          </Stack>
        </MainCard>
      </Stack>
    </Box>
  );
};

export default ComplianceEvaluation;

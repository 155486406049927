import { Box, Grid, Slide, Typography, useTheme } from "@mui/material";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, LEADS } from "codes";
import CLDisplay from "components/CLRequest/CLDisplay";
import SourceDisplay from "components/CLRequest/SourceDisplay";
// import DataTablev2 from "components/Common/DataTablev2";
import { currencyFormatter } from "helpers/currencyFormatter";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useRef } from "react";
import type { IOpportunity } from "../types";
import DataTableOldv2 from "components/Common/DataTablev2/DataTableOldv2";

interface OpportunitiesListProps {
  creditLimitsOpportunities: IOpportunity[];
}

const OpportunitiesList: React.FC<OpportunitiesListProps> = ({
  creditLimitsOpportunities,
}) => {
  const isMobile = useIsMobile();
  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = history.location;

  const theme = useTheme();

  const toBeHidden = [
    "importer_id",
    "exporter_id",
    "credit_limit_id",
    "exporter_lead_completed",
    "importer_lead_completed",
    "coface_amount",
    "exporter_internal_name",
    "importer_internal_name",
  ];

  const renderDateCell = (params: any, isMobile: boolean) => {
    const dateString = params.value;
    const formattedDate = moment(dateString).format("DD/MM/YYYY");

    const currentDate = moment();
    const isWithinLast7Days = currentDate.diff(moment(dateString), "days") < 7;

    return (
      <Grid container justifyContent={isMobile ? "flex-start" : "center"}>
        <Grid item xs={12}>
          {isWithinLast7Days ? (
            <Typography
              style={{
                background: "green",
                padding: "6px 16px",
                borderRadius: theme.shape.borderRadius * 2.5,
                color: "white",
                fontWeight: "600",
                textAlign: "center",
                width: "100%",
                cursor: "not-allowed",
                fontSize: "0.8em",
              }}
            >
              {formattedDate}
            </Typography>
          ) : (
            <Typography>{formattedDate}</Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const width = ref ? useWidth(ref) : 1000;
  const headers = Object.keys(creditLimitsOpportunities?.[0] ?? {});
  const headerLength = headers?.length - toBeHidden.length - 1;
  const defaultColumnWidth = width ? width / headerLength : 200;

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1} ref={ref}>
        <DataTableOldv2
          defaultColumnWidth={defaultColumnWidth}
          headerSx={{
            h4: {
              fontSize: isMobile ? "0.7em!important" : "1em!important",
              height: "auto",
            },
          }}
          tableSx={{
            minWidth: isMobile ? "90vw" : "50vw",
          }}
          toBeHidden={toBeHidden}
          customColumns={{
            credit_limit_requested_date: {
              credit_limit_requested_date: "Requested Date",
              renderCell: (params: any) => renderDateCell(params, isMobile),
            },
            credit_limit_name: {
              credit_limit_name: "Credit Limit Name",
              type: "text",
              link: {
                href: (params: IOpportunity) =>
                  `${INCOMLEND_INTERNAL_LINK}/credit-limits/${params.credit_limit_name}`,
                target: "_self",
              },
            },
            importer_name: {
              minWidth: 230,
              importer_name: "Buyer Name",
              type: "text",
              link: {
                href: (params: IOpportunity) =>
                  `${INCOMLEND_INTERNAL_LINK}${LEADS}/${params.importer_internal_name}`,
                target: "_self",
              },
            },
            exporter_name: {
              minWidth: 230,
              exporter_name: "Supplier Name",
              type: "text",
              link: {
                href: (params: IOpportunity) =>
                  `${INCOMLEND_INTERNAL_LINK}${LEADS}/${params.exporter_internal_name}`,
                target: "_self",
              },
            },
            importer_country: {
              importer_country: "Buyer Country",
              type: "text",
              renderCell: ({ value }: { value: string }) => (
                <Typography>{value}</Typography>
              ),
            },
            exporter_country: {
              exporter_country: "Supplier Country",
              type: "text",
              renderCell: ({ value }: { value: string }) => (
                <Typography>{value}</Typography>
              ),
            },
            sales_owner: {
              sales_owner: "Sales Owner",
              type: "text",
              renderCell: ({ value }: { value: string }) => {
                return (
                  <Typography
                    sx={{
                      color: value === "" ? "info.main" : "primary.main",
                    }}
                  >{`${value === "" ? "None" : value}`}</Typography>
                );
              },
            },
            credit_limit_internal_status: {
              credit_limit_internal_status: "Status",
              renderCell: (params: any) => (
                <Grid container justifyContent={isMobile ? "left" : "center"}>
                  <Grid item xs={12}>
                    <CLDisplay status={params.value} />
                  </Grid>
                </Grid>
              ),
            },
            credit_limit_requested_amount: {
              credit_limit_requested_amount: "Requested Amount",
              type: "number",
              renderCell: (params: { value: number }) => {
                return (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: "1em!important",
                          lineHeight: "2em",
                          height: "1.4em!important",
                          width: isMobile ? "100%" : "9vw",
                        }}
                        color={params?.value ? "primary" : "grey"}
                      >
                        {params?.value
                          ? currencyFormatter({
                              amount: Number(params.value),
                            })
                          : "None"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              },
            },
            credit_limit_approved_amount: {
              credit_limit_approved_amount: "Approved Amount",
              type: "number",
              renderCell: (params: { value: number }) => {
                return (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: "1em!important",
                          lineHeight: "2em",
                          height: "1.4em!important",
                          width: isMobile ? "100%" : "9vw",
                        }}
                        color={params?.value ? "primary" : "grey"}
                      >
                        {params?.value
                          ? currencyFormatter({
                              amount: Number(params.value),
                            })
                          : "None"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              },
            },
            supplierSource: {
              supplierSource: "Supplier Source",
              type: "text",

              renderCell: (params: any) => (
                <Grid container justifyContent={isMobile ? "left" : "center"}>
                  <Grid item xs={12}>
                    <SourceDisplay status={params.value} />
                  </Grid>
                </Grid>
              ),
            },
            buyerSource: {
              buyerSource: "Buyer Source",
              type: "text",
              renderCell: ({ value }: { value: string }) => (
                <Typography>{value}</Typography>
              ),
            },
            coface_rating: {
              coface_rating: "Coface Rating",
              renderCell: (params: { value: any }) => (
                <Typography color={params?.value ? "primary" : "grey"}>
                  {params?.value ?? "None"}
                </Typography>
              ),
            },
            coface_rating_description: {
              coface_rating_description: "Coface Rating Description",
              renderCell: (params: { value: any }) => (
                <Typography color={params?.value ? "primary" : "grey"}>
                  {params?.value
                    ? currencyFormatter({
                        amount: Number(params.value),
                      })
                    : "None"}
                </Typography>
              ),
            },
            coface_amount: {
              coface_amount: "Coface Amount",
              renderCell: (params: { value: any }) => (
                <Typography color={params?.value ? "primary" : "grey"}>
                  {params?.value ?? "None"}
                </Typography>
              ),
            },
            coface_found: {
              coface_found: "Coface Found",
              renderCell: (params: { value: any }) => (
                <Typography color={params?.value ? "primary" : "grey"}>
                  {params?.value ?? "None"}
                </Typography>
              ),
            },
          }}
          onRowClicked={() => {}}
          data={creditLimitsOpportunities}
          rowsPerPage={rowsPerPage}
        />
      </Box>
    </Slide>
  );
};

export default OpportunitiesList;

import { styled } from "@mui/material";
import InputField, { type InputFieldProps } from "../FormFields/InputField";
import styles from "./input.module.scss";

const StyledInput = styled(InputField)(({ theme, multiline, disabled }) => ({
  [theme.breakpoints.down("xs")]: {
    height: "auto",
    "> div": {
      height: "auto",
      "> input": {
        height: "0.8em",
        fontSize: "0.8em",
      },
    },
  },
  [theme.breakpoints.up("md")]: {
    height: "auto",
    "> div": {
      height: multiline ? "auto" : "2.3em",
      "> input": {
        height: "0.8em",
        fontSize: "0.8em",
      },
    },
  },
  ".Mui-disabled": {
    borderRadius: "10px !important",
    backgroundClip: "content-box",
    background: disabled ? "rgb(191 191 191 / 50%)" : "transparent",
    height: "auto",
  },
  ".MuiInputBase-root": {
    height: multiline ? "11vh" : "2.2em",
    backgroundClip: "border-box",
    borderRadius: "10px",
    padding: 0,
    ...(multiline
      ? {
          "> textarea": {
            height: "100% !important",
            overflow: "scroll !important",
          },
        }
      : {}),
  },
  // Chrome, Safari, Edge, Opera
  "input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": 'none',
      margin: 0,
  },
  // firefox
  "input[type=number]": {
      '-moz-appearance': 'textfield',
  }
}));

const Input = (props: InputFieldProps) => {
  return (
    <StyledInput
      {...props}
      labelClassName={styles.labelClass}
      style={{
        ...props.style,
      }}
    />
  );
};
export default Input;

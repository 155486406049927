import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

export interface ISignUp {
  firstName: string;
  lastName: string;
  email: string;
  ISignUp: string;
  role_name: string;
  phoneNumber: string;
  password: string;
  title?: string;
  confirmPassword: string;
  recaptchaCheck: boolean;
  agreementCheck: boolean;
  marketingCheck?: boolean;
}
export const defaultValues: ISignUp = {
  firstName: "",
  lastName: "",
  email: "",
  ISignUp: "",
  role_name: "",
  phoneNumber: "",
  password: "",
  title: "",
  confirmPassword: "",
  recaptchaCheck: false,
  agreementCheck: false,
  marketingCheck: true,
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your First Name"),
  lastName: Yup.string().required("Please enter your Surname"),
  email: Yup.string()
    .required("Please enter your email")
    .min(6, "Email should have atleast 6 characters")
    .max(120, "Email can only have maximum 120 characters")
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email format, please check."
    ),
  phoneNumber: Yup.string()
    .required("Please enter your Phone Number")
    .test({
      name: "phoneNumber",
      exclusive: false,
      params: {},
      message: "Invalid Phone Number",
      test: (value: any) => Boolean(value) && isValidPhoneNumber(value),
    } as any),

  title: Yup.string(),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), ""], "Passwords don't match."),
  recaptchaCheck: Yup.boolean()
    .required("Please verify by checking this box")
    .oneOf([true], "Verification failed, please try again"),
  agreementCheck: Yup.boolean()
    .required("To proceed, you must agree to the Terms and Conditions.")
    .oneOf([true], "To proceed, you must agree to the Terms and Conditions."),
  marketingCheck: Yup.boolean(),
});

export const adminAddedValidationSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string()
    .required("Please enter your email")
    .min(6, "Email should have atleast 6 characters")
    .max(120, "Email can only have maximum 120 characters")
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email format, please check."
    ),
  phoneNumber: Yup.string()
    .required("Please enter your Phone Number")
    .test({
      name: "phoneNumber",
      exclusive: false,
      params: {},
      message: "Invalid Phone Number",
      test: (value: any) => Boolean(value) && isValidPhoneNumber(value),
    } as any),

  title: Yup.string(),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), ""], "Passwords don't match."),
  agreementCheck: Yup.boolean()
    .required("To proceed, you must agree to the Terms and Conditions.")
    .oneOf([true], "To proceed, you must agree to the Terms and Conditions."),
  marketingCheck: Yup.boolean(),
});

import { Sync } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Container, Grid, Typography } from "@mui/material";
import {
  useGetInvitedContactsQuery,
  useResendInviteMutation,
} from "api/api-it";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import NoData from "components/Common/NoData";
import useWidth from "helpers/useWidth";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import InviteStatusIndication from "./InviteStatusIndication";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";
import DataTablev2 from "components/Common/DataTablev2";

interface InvitedContact {
  contact_id: string;
  contact_name: string;
  invite_token: string;
  status: string;
  invited_by: string;
  invited_at: string;
  invite_accepted_at: string;
  contact_type: "internal" | "external" | "Invistor" | "Sales_Partner";
}
const InvitedUsers: React.FC = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearAll());
  }, []);
  const searchvalue = useAppSelector((state) => state.search.value);
  const inviteduserparams = `?per_page=${perPage}&page=${page}${searchvalue ? `&search=${searchvalue}` : ""}`;

  const hasSearchedOnce = useRef(false);
  useEffect(() => {
    if (searchvalue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setPage(1);
    }
  }, [searchvalue]);

  const {
    data: inviteduser,
    isFetching,
    isLoading,
    refetch,
  } = useGetInvitedContactsQuery({ inviteduserparams });
  const TO_BE_HIDDEN = ["invite_token", "contact_id"];

  const handleRefetch = async () => {
    await refetch();
    setPage(1);
    setPerPage(10);
    dispatch(clearAll());
  };

  const handleFilterSubmit = (filtersString: string) => {};
  const formatinvitedUsersForTable = (inviteduser: InvitedContact[]) => {
    return inviteduser.map((item) => ({
      contact_name: item.contact_name,
      status: item.status,
      contact_id: item?.contact_id,
      invited_by: item.invited_by,
      invited_at: item.invited_at,
      invite_accepted_at: item.invite_accepted_at,
      contact_type: item.contact_type,
    }));
  };

  const formattedData = formatinvitedUsersForTable(
    inviteduser?.invited_contacts || []
  );

  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;

  const defaultColumnWidth = width ? (width / 7) * 1.6 : 200;
  const [resendInvite] = useResendInviteMutation();
  useEffect(() => {}, [isFetching]);
  const handlePageChange = (newPage: number, newPerPage: number) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };
  return (
    <Container
      ref={ref}
      sx={{
        p: 0,
      }}
      maxWidth="xl"
    >
      {!isFetching && !isLoading && (
        <Grid item xs={12} margin="5px">
          {formattedData && formattedData.length > 0 ? (
            <DataTablev2
              filterColumnTableName={inviteduser}
              data={formattedData?.map((item: any) => ({
                ...item,
                action: {
                  contact_id: item.contact_id,
                  status: item.status,
                  type: item.contact_type,
                },
              }))}
              pagination={inviteduser?.pagination}
              onPaginationChange={handlePageChange}
              toBeHidden={TO_BE_HIDDEN}
              onFilterSubmit={handleFilterSubmit}
              defaultColumnWidth={defaultColumnWidth}
              rowsPerPage={rowsPerPage}
              customColumns={{
                contact_name: {
                  contact_name: "Contact Name",
                  minWidth: 160,
                },
                contact_type: {
                  contact_type: "Type",
                  minWidth: 80,
                  type: "text",
                },
                status: {
                  status: "Status",
                  minWidth: 80,
                  type: "text",
                  renderCell: ({ value }: { value: string }) => {
                    return (
                      <InviteStatusIndication
                        status={value}
                        sx={{
                          textAlign: "center",
                        }}
                      />
                    );
                  },
                },
                invited_by: {
                  invited_by: "Invited By",
                  minWidth: 160,
                  type: "text",
                },
                invited_at: {
                  invited_at: "Invited At",
                  minWidth: 160,
                  type: "date",
                  renderCell: ({ value }: { value: string }) => {
                    return (
                      <Typography
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {moment(value).format("DD/MM/YYYY HH:mm")}
                      </Typography>
                    );
                  },
                },
                invite_accepted_at: {
                  invite_accepted_at: "Invite Accepted At",
                  minWidth: 160,
                  type: "date",

                  renderCell: ({ value }: { value: string }) => {
                    return (
                      <Typography
                        sx={{
                          color: value ? "green" : "grey",
                          textAlign: "center",
                        }}
                      >
                        {value
                          ? moment(value).format("DD/MM/YYYY HH:mm")
                          : "None"}
                      </Typography>
                    );
                  },
                },
                action: {
                  action: "",
                  type: "action",
                  renderCell: ({
                    value,
                  }: {
                    value: {
                      contact_id: string;
                      status: string;
                      type:
                        | "internal"
                        | "external"
                        | "Invistor"
                        | "Sales_Partner";
                    };
                  }) => {
                    return (
                      <LoadingButton
                        variant="contained"
                        disabled={value?.status === "ACCEPTED"}
                        endIcon={<Sync />}
                        onClick={() =>
                          resendInvite({
                            contact_id: value?.contact_id,
                            type: value?.type,
                          })
                            .unwrap()
                            .then(() =>
                              toast("Invite Resent", { type: "success" })
                            )
                            .catch((err) =>
                              toast(`An error occured: ${err?.message}`, {
                                type: "error",
                              })
                            )
                        }
                      >
                        Resend
                      </LoadingButton>
                    );
                  },
                },
              }}
            />
          ) : (
            <NoData text="No User found" />
          )}
        </Grid>
      )}
    </Container>
  );
};

export default InvitedUsers;

import { Box, Grid, Slide, Typography } from "@mui/material";
import { useGetAccountsQuery } from "api/api-accounts";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import UsersIcon from "assets/navigationIcons/users.svg?react";
import { CL_APPROVED, DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import toProperCase from "helpers/propercase";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import styles from "./account.module.scss";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";
import NoData from "components/Common/NoData";

interface AccountList {
  id: string;
  profile: string;
  name: string;
  internal_name: string;
  reg_no: string;
  country: string;
  users: number;
  created_at: string;
  status: string;
}

const Accounts = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const searchValue = useAppSelector((state) => state.search.value);
  const hasSearchedOnce = useRef(false);
  const [queryParams, setQueryParams] = useState({
    per_page: 10,
    page: 1,
    search: searchValue || undefined,
  });
  const [filters, setFilters] = useState<Record<string, any>>({});

  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch]);

  useEffect(() => {
    if (searchValue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setQueryParams((prev) => ({ ...prev, page: 1, search: searchValue || undefined }));
    }
  }, [searchValue]);

  const { data: accounts, isFetching, isLoading, refetch } = useGetAccountsQuery(
    { 
      accountparams: `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(queryParams)
            .filter(([_, value]) => value !== undefined)
        ) as Record<string, string>
      )}` 
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleFilterSubmit = (filtersString: string) => {
    const filtersObj = JSON.parse(filtersString);
    setFilters(filtersObj); // Persist filters
    const newFilters: Record<string, string> = {};
    for (const key in filtersObj) {
      if (filtersObj[key]?.keywords) {
        if (key === "created_at" && typeof filtersObj[key].keywords === "string") {
          newFilters[key] = filtersObj[key].keywords;
        } else if (Array.isArray(filtersObj[key].keywords)) {
          newFilters[key] = filtersObj[key].keywords.join(",");
        }
      }
    }
    setQueryParams((prev) => ({ ...prev, ...newFilters, page: 1 }));
  };

  const handlePageChange = (newPage: number, newPerPage: number) => {
    setQueryParams((prev) => ({
      ...prev,
      page: newPage,
      per_page: newPerPage
    }));
  };

  const handleRefetch = async () => {
    await refetch();
    setQueryParams({
      per_page: 10,
      page: 1,
      search: undefined
    });
    dispatch(clearAll());
  };

  const formatAccountsForTable = (data: AccountList[]) => {
    return data?.map((item) => ({
      id: item.id,
      internal_name: item.internal_name,
      name: item.name,
      status: item.status,
      profile: item.profile,
      reg_no: item.reg_no,
      country: item.country,
      users: item.users,
      created_at: item.created_at,
    })) || [];
  };

  const formattedData = formatAccountsForTable(accounts?.account_details);
  const { data: currUser } = useGetCurrentUserQuery();
  const rowsPerPage = currUser?.settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const width = useWidth(ref);
  const headerLength = Object.keys(formattedData[0] || {}).length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title="Accounts"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          className={styles.layout}
          headerConfig={{
            left: 4,
            mid: 0,
            right: 4,
            syncAction: handleRefetch,
            xs: {
              left: 6,
              mid: 0,
              right: 6,
            },
            alignItems: "center",
          }}
          mode="default"
        >
          <Grid item xs={12}>
            <Grid item xs={12} ref={ref}>
              {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
              {!isFetching && formattedData.length > 0 ? (
                <DataTablev2
                  filterColumnTableName={accounts}
                  pagination={accounts?.pagination}
                  data={formattedData}
                  defaultColumnWidth={defaultColumnWidth}
                  rowsPerPage={rowsPerPage}
                  onPaginationChange={handlePageChange}
                  useKey="internal_name"
                  onFilterSubmit={handleFilterSubmit}
                  initialFilters={{}}
                  filters={filters} // Pass persistent filters
                  setFilters={setFilters} // Pass setFilters
                  customColumns={{
                    country: {
                      country: "Country",
                      type: "text",
                      minWidth: isMobile ? "100%" : 140,
                      fontSize: "1em",
                    },
                    internal_name: {
                      internal_name: "Account ID",
                      type: "text",
                      fontSize: "1em",
                      minWidth: isMobile ? "100%" : 130,
                      link: {
                        href: (params: AccountList) =>
                          `${INCOMLEND_INTERNAL_LINK}/accounts/${params.internal_name}`,
                        target: "_self",
                      },
                    },
                    profile: { profile: "Profile", type: "text" },
                    name: {
                      name: "Account",
                      type: "text",
                      fontSize: "1em",
                      minWidth: isMobile ? "100%" : 180,
                    },
                    reg_no: {
                      reg_no: "Registration Number",
                      type: "text",
                      fontSize: "1em",
                      minWidth: isMobile ? "100%" : 120,
                    },
                    created_at: {
                      created_at: "Created At",
                      type: "date",
                      fontSize: "1em",
                      minWidth: isMobile ? "100%" : 150,
                      renderCell: ({ value }: { value: string }) => (
                        <Typography>
                          {value
                            ? moment(value).utc().format("DD-MM-YYYY HH:mm:ss")
                            : "-"}
                        </Typography>
                      ),
                    },
                    users: {
                      users: "Users",
                      minWidth: isMobile ? "100%" : 80,
                      fontSize: "1em",
                      type: "number",
                      renderCell: ({ value }: { value: number }) => (
                        <Grid container spacing={1}>
                          <Grid item>
                            <UsersIcon />
                          </Grid>
                          <Grid item>
                            <Typography sx={{ fontSize: "1em!important" }}>
                              {value ?? 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      ),
                    },
                    status: {
                      status: "Status",
                      type: "text",
                      minWidth: isMobile ? "100%" : 100,
                      renderCell: ({ value }: { value: string }) => (
                        <Typography
                          sx={{
                            fontSize: "1em",
                            bgcolor: value
                              ? value === CL_APPROVED
                                ? "success.main"
                                : "info.main"
                              : "transparent",
                            color: value ? "white.main" : "info.main",
                            fontWeight: value ? "bold" : 600,
                            borderRadius: 1,
                            padding: 0.5,
                            textAlign: "center",
                            width: "100%",
                            fontStyle: value ? "normal" : "italic",
                          }}
                        >
                          {value
                            ?.split("_")
                            ?.map((item) => toProperCase(item))
                            ?.join(" ") ?? "None"}
                        </Typography>
                      ),
                    },
                  }}
                  toBeHidden={["profile"]}
                  sx={{
                    table: {
                      minWidth: "100% !important",
                    },
                    "& .MuiTableCell-root": {
                      fontSize: "0.75rem",
                    },
                  }}
                />
              ) : (
                <NoData text="No Accounts found" />
              )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};

export default Accounts;

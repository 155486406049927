import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Grid } from "@mui/material";
import useWidth from "helpers/useWidth";
import React, { useState, WheelEvent } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styles from "./docview.module.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export interface DocViewProps {
  /**Document link */
  uri: string;
  // /**Page width */
  // width?: number;
  /**Toggle Navigation display */
  staticView?: boolean;
  hideNavigation?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
  pageClassName?: string;
  docClassName?: string;
}

const DocView = (props: DocViewProps) => {
  const { uri, hideNavigation = false, ref, staticView = false } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const width = ref ? (useWidth(ref) ?? 400) : 400;
  const height = ref?.current?.clientHeight ?? 400;
  const { docClassName = "", pageClassName = "" } = props;
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handleOnWheel = (e: WheelEvent) => {
    if (scale >= 1) {
      setScale(scale - 0.01 * e.deltaY);
    } else {
      setScale(1);
    }
  };

  const goLeft = () =>
    setPageNumber((page) => {
      if (page !== 1) return page - 1;
      return 1;
    });
  const goRight = () =>
    setPageNumber((page) => {
      if (page !== numPages) return page + 1;
      return numPages;
    });
    const Navigation = () => (
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item>
          <ChevronLeftIcon onClick={goLeft} fontSize="large" />
        </Grid>
        <Grid item>
          Page {pageNumber} of {numPages}
        </Grid>
        <Grid item>
          <ChevronRightIcon onClick={goRight} fontSize="large" />
        </Grid>
        <Grid item>
          <ZoomInIcon 
            onClick={() => setScale((prevScale) => Math.min(prevScale + 0.1, 3))} 
            fontSize="large"
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid item>
          <ZoomOutIcon 
            onClick={() => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5))} 
            fontSize="large"
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
    );
  return (
    <Grid container ref={ref}>
      <Grid item xs={12}>
        <div onWheel={staticView ? () => {} : handleOnWheel}>
          <Document
            file={uri}
            className={`${styles.doc} ${docClassName}`}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"
          >
            <Page
              width={width}
              pageNumber={pageNumber}
              height={height}
              renderMode="canvas"
              scale={scale}
              className={`${styles.page} ${pageClassName}`}
            />
          </Document>
        </div>
      </Grid>
      <Grid item xs={12}>
        {!hideNavigation && <Navigation />}
      </Grid>
    </Grid>
  );
};

export default DocView;

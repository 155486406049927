import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Link,
  Stack,
} from "@mui/material";
import {
  useGetAccountQuery,
  useGetAccountRelationshipQuery,
  useGetLeadPartnerTermsheetQuery,
} from "api/api-accounts";
import { INCOMLEND_INTERNAL_LINK } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import StatusDisplay from "components/Termsheets/StatusDisplay";
import { useFormikContext } from "formik";
import type { PartnerData } from "types";
import LeadPartnerTabs from "./LeadPartnerTabs";
import { type LeadSections } from "./LeadSectionTabs";
import React from "react";

export default function LeadPartnerAccordion({
  sync,
  partner,
  partnerInternalName,
}: {
  sync: () => void;
  partner: PartnerData;
  partnerInternalName?: string;
}) {
  const defaultExpanded = partnerInternalName === ""
          ? false
          : partnerInternalName === partner.internal_name;
  const [skip, setSkip] = React.useState(!defaultExpanded);

  const formikProps = useFormikContext<LeadSections>();
  const { partners, ...lead } = formikProps.values;
  const {
    data: account,
    isFetching: isFetchingPartner,
    refetch: refetchPartner,
  } = useGetAccountQuery(partner?.id ?? "", {
    skip: !Boolean(partner?.id) || skip,
    refetchOnMountOrArgChange: true,
  });

  const {
      data: relation,
      isFetching: isFetchingRelation,
      refetch: refetchRelation
  } = useGetAccountRelationshipQuery({
      companyId: lead.id,
      partnerId: partner.id ?? ""
  }, { skip });

  const {
    data: termsheet,
    isFetching: isFetchingTermsheet,
    refetch: refetchTermsheet,
  } = useGetLeadPartnerTermsheetQuery({
    lead_id: lead.id,
    partner_id: partner.id ?? "",
  }, { skip });

  const isFetching = isFetchingPartner || isFetchingRelation || isFetchingTermsheet;
  const refetch = async () => {
    refetchPartner();
    refetchRelation();
    refetchTermsheet();
  }

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      onChange={(_, expanded) => {
        if (skip) {
          setSkip(!expanded);
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Link
            fontWeight="bold"
            variant="h4"
            href={`${INCOMLEND_INTERNAL_LINK}/leads/${partner?.internal_name}`}
            onClick={(e) => e.stopPropagation()}
          >
            {partner?.name}
          </Link>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              refetch();
            }}
          >
            <SyncIcon />
          </IconButton>
          {(!!termsheet?.termsheet_status || !!partner?.termsheet_status) && (
            <StatusDisplay
              status={termsheet?.termsheet_status ?? partner?.termsheet_status}
              style={{ width: "fit-content" }}
            />
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {isFetching && <SkeletonLoad bars={10} />}
        {!isFetching && account && (
          <LeadPartnerTabs
            lead={lead}
            partner={account}
            relation={relation}
            termsheet={termsheet}
            onEnd={() => {
              sync();
              refetch();
            }}
            isCurrentPartner={partnerInternalName === partner.internal_name}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

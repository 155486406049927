import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton, Pagination, useTheme } from "@mui/material";
import React, { useState } from "react";
import { v4 as randomUUID } from "uuid";
import MainCard from "../Cards/MainCard";
interface CarouselProps {
  items: React.ReactNode[];
}
const Carousel: React.FC<CarouselProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentIndex(page - 1);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "220mm",
        margin: "auto",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <MainCard
        contentSX={{
          p: 0,
          ":last-child": {
            pb: 1,
          },
        }}
        sx={{ mb: 2 }}
      >
        <Pagination
          count={items.length}
          page={currentIndex + 1}
          onChange={handlePageChange}
          sx={{
            mt: 2,
            "& .MuiPaginationItem-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              color: theme.palette.white.main,
              backgroundColor: theme.palette.error.main,
            },
          }}
        />
      </MainCard>
      <Box
        sx={{
          display: "flex",
          transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.standard,
          }),
          transform: `translateX(-${currentIndex * 100}%)`,
          width: "100%",
        }}
      >
        {items.map((item, index) => (
          <Box
            key={randomUUID()}
            sx={{
              flex: "0 0 100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {item}
          </Box>
        ))}
      </Box>
      <IconButton
        onClick={handlePrev}
        sx={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          color: theme.palette.text.primary,
          background: "#bcbcbc",
          boxShadow: "4px 4px 30px 0px #bdbdbd, -15px -17px 30px 0px #ffffffb3",
          "&:hover": { background: theme.palette.background.default },
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          color: theme.palette.text.primary,
          background: "#bcbcbc",
          boxShadow: "4px 4px 30px 0px #bdbdbd, -15px -17px 30px 0px #ffffffb3",
          "&:hover": { background: theme.palette.background.default },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default Carousel;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { VITE_API_V2_URL } from "codes";
import { Form, Formik } from "formik";

import {
  useGetCategoriesQuery,
  useGetMapSummaryQuery,
  useGetRiskMapApprovalsQuery,
} from "api/api-maps";
import SkeletonLoad from "components/Common/SkeletonLoad";
import {
  defaultRiskScoring,
  getFinalScore,
  getScore,
  getScoringtableInitialValues,
  getWeightedAverage,
} from "components/Risk/defaults";

import { useTour } from "@reactour/tour";
import Layout from "components/Common/Layout";
import useRiskScoring from "hooks/useRiskScoring";
import { MapContext } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import React from "react";
import { useAppSelector } from "redux/hooks";
import RiskScoringApproval from "../Approval";
import Assessment from "./Assessment";
import Category from "./Category";
import RiskResult from "./RiskResult";
import ScoreCardFooter from "./ScorecardFooter";
import ScorecardHeader from "./ScorecardHeader";

interface CategoryObject {
  [category: string]: { description: string; score: number };
}
const RiskScoring = () => {
  const theme = useTheme();
  const id = React.useContext(MapContext);
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const READ_ONLY = USER_PERMISSIONS?.includes(PERMISSIONS.map_view_risk);
  const { setIsOpen } = useTour();
  const { data: riskApproval } = useGetRiskMapApprovalsQuery(id ?? "", {
    skip: id === undefined,
  });

  const { data: mapData } = useGetMapSummaryQuery(id ?? "", { skip: !id });

  const isMapApproved = riskApproval?.approval_status === "Approved";
  const isMapRejected = riskApproval?.approval_status === "Rejected";

  const isFieldDisabled = READ_ONLY || isMapApproved || isMapRejected;
  const {
    data: categories,
    isLoading,
    refetch,
    status,
  } = useGetCategoriesQuery(id ?? "", {
    skip: !Boolean(id),
  });

  const {
    score,
    RESULT: result,
    FINAL_SCORE: finalScore,
  } = useRiskScoring({
    id,
  });

  const generatePdf = () => {
    const getToken = () => JSON.parse(localStorage.getItem("token") as string);
    const url = `${VITE_API_V2_URL}/maps/${id}/score-card/export`;
    const link = document.createElement("a");
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()?.value}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error("Access Denied");
        }
      })
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob);
        link.href = objectURL;
        link.download = `${id}_Risk_Score_Card.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };

  return (
    <Layout
      title="IPL RISK RATING SCORECARD"
      textHeadingOptions={{ fontWeight: "bold", level: 4 }}
      headerConfig={{ syncAction: refetch, left: 10, mid: 0, right: 2 }}
      primary={{
        children: "export",
        onClick: () => generatePdf(),
        isLoading: status !== "fulfilled",
        fullWidth: true,
        disabled: isFieldDisabled,
      }}
    >
      <Grid item xs={12} margin="auto">
        <Grid container spacing={1}>
          <Grid item xs={12} margin="auto">
            <ScorecardHeader disabled={isFieldDisabled} />
          </Grid>
          {!mapData?.ipl_product_id ? (
            <Grid item xs={12} margin="auto">
              <Alert severity="error">
                <AlertTitle>Action Required</AlertTitle>
                Please select a product category to view Risk Scoring parameters
                <br />
                <br />
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setIsOpen(true)}
                >
                  Take me there
                </Button>
              </Alert>
            </Grid>
          ) : (
            <Grid item xs={12} margin="auto">
              {isLoading && <SkeletonLoad bars={10} />}
              {!isLoading && categories && (
                <Formik
                  enableReinitialize
                  initialValues={{
                    ...defaultRiskScoring,
                    ...getScoringtableInitialValues(categories),
                  }}
                  onSubmit={() => {}}
                >
                  {(formikProps) => {
                    const {
                      buyer = {},
                      supplier = {},
                      tradingRelationship = {},
                      adjustment = {},
                      buyers_assessment_weight,
                      suppliers_assessment_weight,
                      trading_relationship_weight,
                    } = formikProps?.values;
                    const weightages = {
                      buyers_assessment_weight,
                      suppliers_assessment_weight,
                      trading_relationship_weight,
                    };
                    const score = getFinalScore({
                      buyer,
                      supplier,
                      tradingRelationship,
                      adjustment,
                      weightages,
                    });

                    return (
                      <Form>
                        <Grid container spacing={1}>
                          <Grid item xs={12} margin="auto">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Assessment
                                  title="Buyer's Assessment"
                                  party="buyer"
                                />
                              </AccordionSummary>
                              <AccordionDetails>
                                {isLoading && !categories && (
                                  <SkeletonLoad bars={10} />
                                )}
                                {!isLoading &&
                                  categories &&
                                  (buyers_assessment_weight !== 0 ? (
                                    <Table component={Paper}>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>
                                            <Typography fontWeight="bold">
                                              RISK CATEGORY
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography fontWeight="bold">
                                              DESCRIPTION
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography fontWeight="bold">
                                              SCORE
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      {id &&
                                        categories.buyers_assessment.map(
                                          (category) => (
                                            <Category
                                              category={category}
                                              type="buyer"
                                              key={category.id}
                                              mapId={id}
                                              disabled={isFieldDisabled}
                                            />
                                          )
                                        )}
                                    </Table>
                                  ) : (
                                    <Typography>
                                      Not available for the product type{" "}
                                      {mapData?.ipl_product}
                                    </Typography>
                                  ))}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          {/* Supplier Assessment */}
                          <Grid item xs={12} margin="auto">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="supplier-assessment-content"
                                id="supplier-assessment"
                              >
                                <Assessment
                                  title="Supplier's Assessment"
                                  party="supplier"
                                />
                              </AccordionSummary>
                              <AccordionDetails>
                                {isLoading && !categories && (
                                  <SkeletonLoad bars={10} />
                                )}
                                {!isLoading && categories && (
                                  <Table component={Paper}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <Typography fontWeight="bold">
                                            RISK CATEGORY
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography fontWeight="bold">
                                            DESCRIPTION
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography fontWeight="bold">
                                            SCORE
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    {id &&
                                      categories.suppliers_assessment.map(
                                        (category) => (
                                          <Category
                                            category={category}
                                            type="supplier"
                                            key={category.id}
                                            mapId={id}
                                            disabled={isFieldDisabled}
                                          />
                                        )
                                      )}
                                  </Table>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          {/* TRADING RELATIONSHIP */}
                          <Grid item xs={12} margin="auto">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Assessment
                                  title="Trading Relationship"
                                  party="tradingRelationship"
                                />
                              </AccordionSummary>
                              <AccordionDetails>
                                {isLoading && !categories && (
                                  <SkeletonLoad bars={10} />
                                )}
                                {!isLoading && categories && (
                                  <Table component={Paper}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>
                                          <Typography fontWeight="bold">
                                            RISK CATEGORY
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography fontWeight="bold">
                                            DESCRIPTION
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography fontWeight="bold">
                                            SCORE
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    {id &&
                                      categories?.trading_relationship.map(
                                        (category) => (
                                          <Category
                                            category={category}
                                            type="tradingRelationship"
                                            key={category.id}
                                            mapId={id}
                                            disabled={isFieldDisabled}
                                          />
                                        )
                                      )}
                                  </Table>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          <Grid item xs={12} margin="auto">
                            <Divider />
                          </Grid>
                          <Grid item xs={12} margin="auto">
                            <Table component={Paper}>
                              <TableRow>
                                <TableCell>
                                  <Typography fontWeight="bold">
                                    Weighted-average Score:
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontWeight="bold">
                                    Reason for override
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontWeight="bold">
                                    Score
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography></Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {getWeightedAverage(
                                      [
                                        getScore(
                                          formikProps?.values
                                            ?.buyer as CategoryObject
                                        ),
                                        getScore(
                                          formikProps?.values
                                            ?.supplier as CategoryObject
                                        ),
                                        getScore(
                                          formikProps?.values
                                            ?.tradingRelationship as CategoryObject
                                        ),
                                      ] as number[][],
                                      weightages
                                    )}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              {/*overrides*/}
                              {id &&
                                categories?.adjustment.map((category) => (
                                  <Category
                                    category={category}
                                    type="adjustment"
                                    key={category.id}
                                    mapId={id}
                                    disabled={isFieldDisabled}
                                  />
                                ))}
                            </Table>
                          </Grid>
                          <Grid item xs={12} margin="auto">
                            <Table component={Paper}>
                              <TableRow>
                                <TableCell>
                                  <Typography fontWeight="bold">
                                    TOTAL IPL SCORE
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography></Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography></Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography></Typography>
                                </TableCell>
                                <TableCell>
                                  <Stack direction="row" spacing={1}>
                                    <Typography fontWeight="bold">
                                      {Math.trunc(score)}
                                    </Typography>
                                    <Tooltip
                                      title={
                                        <Typography>
                                          {`The total IPL score is the sum of the
                                  weighted-average score and any overrides, truncated from ${score}`}
                                        </Typography>
                                      }
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.8em",
                                          color: theme.palette.text.secondary,
                                        }}
                                      >
                                        <HelpOutlineIcon />
                                      </Typography>
                                    </Tooltip>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography fontWeight="bold">
                                    IPL RISK CATEGORY
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography></Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography></Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography></Typography>
                                </TableCell>
                                <TableCell>
                                  <RiskResult
                                    finalScore={finalScore}
                                    result={result}
                                  />
                                </TableCell>
                              </TableRow>
                              {id &&
                                categories?.overrides_to_high_risk.map(
                                  (category) => (
                                    <Category
                                      category={category}
                                      type="override"
                                      key={category.id}
                                      mapId={id}
                                      disabled={isFieldDisabled}
                                    />
                                  )
                                )}
                            </Table>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Grid>
          )}

          <Grid item xs={12} margin="auto">
            <ScoreCardFooter parentName={RiskScoring.name} disabled={isFieldDisabled} />
          </Grid>
          <Grid item xs={12} margin="auto">
            <RiskScoringApproval />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};
export default RiskScoring;

import React from "react";
import { Pagination, Select, MenuItem, Stack } from "@mui/material";

interface PaginationProps {
  totalCount: number;
  perPage: number;
  currentPage: number;
  lastPage: number;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
}

const CustomPagination: React.FC<PaginationProps> = ({
  perPage,
  currentPage,
  lastPage,
  onPageChange,
  onPerPageChange,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ mt: 2, px: 2 }}
      justifyContent="flex-end"
      flex={1}
    >
      {/* Dropdown to select number of rows per page */}
      <Stack direction="row" spacing={1} alignItems="center">
        <Select
          value={perPage}
          onChange={(e) => onPerPageChange(Number(e.target.value))}
          size="small"
          sx={{ width: 80 }}
        >
          {[10, 25, 50].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      {/* Pagination controls aligned to the right */}
      <Stack>
        <Pagination
          count={lastPage}
          page={currentPage}
          onChange={(_, page) => onPageChange(page)}
          color="primary"
          shape="rounded"
        />
      </Stack>
    </Stack>
  );
};

export default CustomPagination;

import InfoIcon from "@mui/icons-material/Info";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { type CSSProperties } from "react";
import DatePicker, { type DatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styles from "./dateinput.module.scss";
export interface DateInputProps {
  /**Write JSDoc */
  style?: CSSProperties;
  className?: string;
  labelClassName?: string;
  labelRequiredClassName?: string;
  label?: string;
  wrapperClassName?: string;
  popperClassName?: string;
  calendarClassName?: string;
  dateFormat?: string;
  value?: Date;
  name?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: any) => void;
  setValue?: React.Dispatch<React.SetStateAction<Date | undefined>>;
  disabled?: boolean;
  error?: string;
  datepickerProps?: DatePickerProps;
  onChangeCommitted?: (value: Date) => void;
  required?: boolean;
  helpText?: string;
}

const DateInput = (props: DateInputProps) => {
  const {
    style,
    className,
    labelClassName,
    label,
    value,
    dateFormat = "dd/MM/yyyy",
    wrapperClassName = "",
    popperClassName = "",
    calendarClassName = "",
    setFieldValue = () => {},
    setValue,
    name = "",
    disabled,
    error,
    datepickerProps,
    onChangeCommitted = () => {},
    required,
    labelRequiredClassName,
    helpText,
  } = props;
  // disabling this state, as change in value outside this component
  // is not reflecting to this component without remounting or refetching
  // const [startDate, setStartDate] = useState(value);
  const theme = useTheme();

  const Label = () => (
    <Stack
      alignItems="center"
      justifyContent="start"
      spacing={0.5}
      direction="row"
      mb={0.5}
      height="2.3em"
    >
      <Typography fontWeight="bold">{label && `${label}`}</Typography>
      {required && <Typography color="error">*</Typography>}
      {Boolean(helpText) && (
        <Tooltip title={helpText} enterTouchDelay={0} leaveDelay={200}>
          <InfoIcon color="primary" sx={{ marginTop: "6px" }} />
        </Tooltip>
      )}
      <InputLabel />
    </Stack>
  );

  //something is wrong with the datepicker package, the type definitions are not correct, hence using 'any'
  const handleChangeEvent = (date: any, event: any) => {
    if (date) {
      const calcDate = moment(date, "DD/MM/YYYY");
      // setStartDate(calcDate.toDate());
      setValue
        ? setValue(moment(calcDate).toDate())
        : setFieldValue(name, moment(calcDate).toDate(), true);
      onChangeCommitted(date);
    } else {
      // setStartDate(undefined);
      setValue ? setValue(undefined) : setFieldValue(name, undefined, true);
    }
  };
  const dpProps: DatePickerProps = {
    wrapperClassName: `${wrapperClassName ?? ""} ${styles.datepickerWrapper}`,
    popperClassName: `${popperClassName ?? ""} ${styles.datepickerPopper}`,
    calendarClassName: `${calendarClassName ?? ""} ${styles.datepickerCalendar}`,
    customInput: <TextField fullWidth />,
    withPortal: true,
    placeholderText: "DD/MM/YYYY",
    selected: value,
    dateFormat: "dd/MM/yyyy",
    disabled: disabled,
    onChange: handleChangeEvent,
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: "select",
    ...datepickerProps,
  };
  return (
    <FormControl
      error={Boolean(error)}
      data-testid="date-field"
      style={style}
      className={className}
      fullWidth
      sx={{
        "> div[class^='react-datepicker-wrapper']": {
          ">div[class='react-datepicker__input-container']": {
            backgroundColor: theme.palette.field.main,
            height: "2.2em",
            display: "flex",
            ">input": {
              borderColor: "primary.main",
              width: "100%",
              backgroundColor: theme.palette.field.main,
              height: "auto",
              p: "4.5px 15px",
            },
          },
        },
      }}
    >
      {label && <Label />}
      <DatePicker {...dpProps} />
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default DateInput;

import React, { useContext, useMemo, useCallback } from "react";
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from "@mui/material";
import { useGetTemplatesV2Query } from "api/api-maps";
import { MapCurrentVersion } from "pages/maps/map";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import { currencyFormatter, percentageFormatter } from "helpers/currencyFormatter";

interface SellerFinancialTableProps {
  mapData?: any;
  formattedYears: string[];
  metricMapping: Record<string, string>;
  periods: string[];
  financialsData?: Record<string, Record<string, string | number>>;
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const SellerFinancialTable: React.FC<SellerFinancialTableProps> = ({
  mapData,
  formattedYears,
  metricMapping,
  periods,
  financialsData = {},
}) => {
  const currentMapVersion = useContext(MapCurrentVersion);
  const theme = useTheme();

  const { data: templateData, isLoading, isError } = useGetTemplatesV2Query(
    {
      party: "supplier",
      use_case: "financial",
      ipl_product: mapData?.ipl_product_id ?? "",
      map_version: currentMapVersion,
    },
    {
      skip: !mapData?.ipl_product_id,
    }
  );

  const template = templateData?.template || {};

  const years = useMemo(() => Object.keys(financialsData).sort((a, b) => b.localeCompare(a)), [financialsData]);

  const periodMapping = useMemo(() => {
    const mapping: Record<string, string> = {};
    years.forEach((year) => {
      const [month, yearPart] = year.split("/");
      const formattedMonth = monthNames[parseInt(month, 10) - 1];
      const transformedPeriod = `${formattedMonth}-${yearPart?.split("-")[0]}`;
      mapping[transformedPeriod] = year;
    });
    return mapping;
  }, [years]);

  const sortedFormattedYears = useMemo(() => {
    return [...formattedYears].sort((a, b) => {
      const [monthA, yearA] = a.split("-");
      const [monthB, yearB] = b.split("-");
      const monthIndexA = monthNames.indexOf(monthA);
      const monthIndexB = monthNames.indexOf(monthB);
      return yearB !== yearA ? parseInt(yearB, 10) - parseInt(yearA, 10) : monthIndexB - monthIndexA;
    });
  }, [formattedYears]);

  const calculateGrowthPercentage = useCallback((currentYearData: number, previousYearData: number): number => {
    if (previousYearData === 0) return 0;
    return ((currentYearData - previousYearData) / previousYearData) * 100;
  }, []);

  const getGrowthIndication = useCallback((growth: number): [string, string, number] => {
    if (growth > 0) return [`▲`, theme.palette.success.main, growth];
    if (growth < 0) return [`▼`, theme.palette.error.main, growth];
    return [``, theme.palette.info.main, growth];
  }, [theme]);

  const formatValue = useCallback((metricKey: string, value: number | string): string => {
    if (value === "N/A" || value === null || value === undefined || value === "NaN" || value === "NaN%") return "0";
    const isMarginKey = metricKey.toLowerCase().includes("margin");
    return isMarginKey ? percentageFormatter(Number(value)) : currencyFormatter({ amount: Number(value) });
  }, []);

  const getFinancialData = useCallback((period: string, metricName: string): number | string => {
    const originalPeriod = periodMapping[period];
    const value = financialsData?.[originalPeriod]?.[metricName];
    return isNaN(Number(value)) ? 0 : value;
  }, [financialsData, periodMapping]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data.</div>;
  if (!mapData?.ipl_product_id) return <Typography variant="body1" color="error" sx={{ mt: 2 }}>No IPL product data available.</Typography>;

  return (
    <>
      <Typography variant="h3" color="primary">
        Financial {mapData?.seller_name}:
      </Typography>
      <TableContainer component={Paper} sx={{ mt: 2, overflowX: "auto" }}>
        <Table size="small" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "auto", whiteSpace: "nowrap" }}><b>In USD</b></TableCell>
              {sortedFormattedYears.length > 0 ? (
                sortedFormattedYears.map((year) => (
                  <TableCell key={year} align="right" sx={{ width: "auto", whiteSpace: "nowrap" }}>
                    <b>{year}</b>
                  </TableCell>
                ))
              ) : (
                <TableCell align="right" sx={{ width: "auto", whiteSpace: "nowrap" }}>
                  <b>Mar-25</b>
                </TableCell>
              )}
              <TableCell align="right" sx={{ width: "auto", whiteSpace: "nowrap" }}>
                <b>Growth (%)</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(metricMapping).map((metricKey) => {
              const metricName = metricMapping[metricKey];
              const metricTemplate = template[metricKey];
              const needGrowthRate = metricTemplate?.need_growth_rate ?? false;

              let growthDisplay = null;
              if (needGrowthRate && sortedFormattedYears.length > 1) {
                const currentYearData = Number(getFinancialData(sortedFormattedYears[0], metricName));
                const previousYearData = Number(getFinancialData(sortedFormattedYears[1], metricName));
                const growth = calculateGrowthPercentage(currentYearData, previousYearData);
                const [symbol, color, growthValue] = getGrowthIndication(growth);
                growthDisplay = (
                  <TableCell align="right" sx={{ width: "auto", whiteSpace: "nowrap" }}>
                    <LabelViewOnly
                      label={`${symbol} ${growthValue.toFixed(2)}%`}
                      sx={{ color, width: "100%", textAlign: "right", display: "block" }}
                    />
                  </TableCell>
                );
              } else {
                const [symbol, color] = getGrowthIndication(0);
                growthDisplay = (
                  <TableCell align="right" sx={{ width: "auto", whiteSpace: "nowrap" }}>
                    <LabelViewOnly
                      label={`${symbol} 0.00%`}
                      sx={{ color, width: "100%", textAlign: "right", display: "block" }}
                    />
                  </TableCell>
                );
              }

              return (
                <TableRow key={metricKey}>
                  <TableCell sx={{ width: "auto", whiteSpace: "nowrap" }}>{metricName}</TableCell>
                  {sortedFormattedYears.map((year) => (
                    <TableCell key={year} align="right" sx={{ width: "auto", whiteSpace: "nowrap" }}>
                      {formatValue(metricKey, getFinancialData(year, metricName))}
                    </TableCell>
                  ))}
                  {growthDisplay}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SellerFinancialTable;

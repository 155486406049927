import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { setSearchData } from "redux/reducers/searchReducer";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export interface SearchProps {
  value?: string;
  curved?: boolean;
  sx?: SxProps;
  className?: string;
  processSearch?: (searchkey: string) => void;
  textFieldProps?: TextFieldProps;
  onChange: (e: string) => void;
}

const Search = () => {
  const searchvalue = useAppSelector((state) => state.search.value);
  const [search, setSearch] = useState(searchvalue);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      dispatch(setSearchData(search));
    }, 1200);

    return () => clearTimeout(delayDebounce);
  }, [search, dispatch]);

  return (
    <TextField
      placeholder={t("search")}
      inputProps={{ "aria-label": "search" }}
      value={search}
      sx={{
        color: "inherit",
        "& .MuiInputBase-root": {
          borderRadius: "4ch",
          backgroundColor: theme.palette.field.main,
          "& .MuiInputBase-input": {
            padding: `${theme.spacing(1, 1, 1, 0)}`,
            paddingLeft: `calc(1em + ${theme.spacing(1)})`,
            transition: theme.transitions.create("width"),
            backgroundColor: theme.palette.field.main,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
              width: "90%",
              "&:focus": {
                width: "100%",
              },
            },
          },
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="search icon" edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        setSearch(e.target.value);
      }}
    />
  );
};

export default Search;

import { Search } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGetTaxInvoicesQuery } from "api/api-operations";
// import DataTablev2 from "components/Common/DataTablev2";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";

import React, { useRef, useState } from "react";
import useDefaultTableDimensions from "redux/useDefaultTableDimensions";
import CreateTaxInvoiceStageDetails from "./StageDetails";
import DataTableOldv2 from "components/Common/DataTablev2/DataTableOldv2";

const CreateTaxInvoice: React.FC<{ onEnd: () => void }> = ({ onEnd }) => {
  const [invoiceId, setInvoiceId] = useState<string>("");
  const [sfInvoiceId, setSfInvoiceId] = useState<string>("");
  const [invoiceName, setInvoiceName] = useState<string>("");
  const [searchClicked, setSearchClicked] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;

  const { data: taxInvoices = [], isLoading } = useGetTaxInvoicesQuery(
    invoiceId,
    {
      skip: !searchClicked || invoiceId === "",
      refetchOnFocus: true,
    }
  );

  const { defaultColumnWidth: defaultColumnWidth, defaultRows: rowsPerPage } =
    useDefaultTableDimensions(taxInvoices, width);

  return (
    <Stack spacing={2} ref={ref}>
      {!searchClicked && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Invoice ID</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={invoiceId}
              onChange={(e) => setInvoiceId(e.target.value)}
              placeholder="Click on the icon or press enter to search"
              variant="standard"
              fullWidth
              disabled={isLoading}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchClicked(true);
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setSearchClicked(true)}>
                    <Search />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}

      {isLoading && <SkeletonLoad bars={10} />}
      {!isLoading && taxInvoices && taxInvoices.length > 0 && (
        <Typography fontStyle="italic">
          {`Found ${taxInvoices.length} tax invoices for Invoice ID: `}
          <strong>{invoiceId}</strong>
        </Typography>
      )}

      {!isLoading && (
        <DataTableOldv2
          rowsPerPage={rowsPerPage}
          defaultColumnWidth={defaultColumnWidth}
          data={taxInvoices}
          onRowClicked={(row) => {
            setSfInvoiceId(row.sf_tax_invoice_id);
            setInvoiceName(row.sf_tax_invoice_name);
          }}
          applyCellStyling={(column, item) => {
            if (item.sf_tax_invoice_id === sfInvoiceId) {
              return {
                backgroundColor: "primary.main",
                color: "#ffffff",
                ".MuiTypography-root": {
                  fontWeight: "bold",
                  fontSize: "1em",
                },
              };
            }
            return {};
          }}
          customColumns={{
            sf_invoice_name: {
              sf_invoice_name: "Invoice Name",
            },
            sf_tax_invoice_id: {
              sf_tax_invoice_id: "Tax Invoice ID",
              minWidth: 220,
            },
            sf_tax_invoice_name: {
              sf_tax_invoice_name: "Tax Invoice Name",
              minWidth: 220,
            },
            invoice_reference_number: {
              invoice_reference_number: "Invoice Ref. No",
              minWidth: 220,
            },
            invoice_date: {
              invoice_date: "Invoice Date",
            },
            transaction_type: {
              transaction_type: "Transaction Type",
            },
            currency: {
              currency: "Currency",
            },
            tax_rate_percentage: {
              tax_rate_percentage: "Tax Rate (%)",
            },
            exchange_rate: {
              exchange_rate: "Exchange Rate",
            },
            invoice_amount: {
              invoice_amount: "Invoice Amount",
            },
            invoice_amount_sgd_equiv: {
              invoice_amount_sgd_equiv: "Invoice Amount (SGD)",
            },
            total_amount: {
              total_amount: "Total Amount",
            },
            total_amount_sgd_equiv: {
              total_amount_sgd_equiv: "Total Amount (SGD)",
            },
            gst_amount: {
              gst_amount: "GST Amount",
            },
            gst_amount_sgd_equiv: {
              gst_amount_sgd_equiv: "GST Amount (SGD)",
            },
            tax_invoice_type: {
              tax_invoice_type: "Tax Invoice Type",
            },
          }}
        />
      )}

      {!isLoading && taxInvoices && taxInvoices.length === 0 && (
        <NoData text="No tax invoices found" sx={{ margin: "auto" }} />
      )}
      {invoiceName !== "" && sfInvoiceId !== "" && (
        <Stack spacing={0.5}>
          <Divider />
          <Divider />
        </Stack>
      )}
      {invoiceName !== "" && sfInvoiceId !== "" && (
        <CreateTaxInvoiceStageDetails
          sf_invoice_name={invoiceName}
          sf_tax_invoice_id={sfInvoiceId}
          invoiceId={invoiceId}
          onEnd={onEnd}
        />
      )}
    </Stack>
  );
};

export default CreateTaxInvoice;

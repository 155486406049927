import { Box, Slide, Stack, Typography } from "@mui/material";
import { useGetPlatformUsageQuery } from "api/api-marketing";
// import DataTablev2 from "components/Common/DataTablev2";
import DataTableOldv2 from "components/Common/DataTablev2/DataTableOldv2";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import { useRef } from "react";

const MonthlyTable = () => {
  const {
    data: platformUsage,
    isFetching,
    isLoading,
  } = useGetPlatformUsageQuery(null);

  const rowsPerPage = 6;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref, 1000) : 1000;
  const headerLength = Object.keys(platformUsage?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.2 : 200;

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography variant="h3">
            Platform usage since July 2023 - Monthly
          </Typography>
          {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
          {!isFetching &&
            platformUsage !== undefined &&
            Boolean(platformUsage?.length) && (
              <DataTableOldv2
                data={platformUsage}
                defaultColumnWidth={defaultColumnWidth}
                rowsPerPage={rowsPerPage}
                customColumns={{
                  source: { source: "Source" },
                  label: { label: "Period", minWidth: 200 },
                  signups: { signups: "Signups" },
                  submitted_companies: {
                    submitted_companies: "Submitted Companies",
                    type: "number",
                  },
                  tracked_shipments: {
                    tracked_shipments: "Tracked Shipments",
                    type: "number",
                  },
                  submitted_credit_limits: {
                    submitted_credit_limits: "Submitted Credit Limits",
                    type: "number",
                  },
                  approved_credit_limits: {
                    approved_credit_limits: "Approved Credit Limits",
                    type: "number",
                  },
                  rejected_credit_limits: {
                    rejected_credit_limits: "Rejected Credit Limits",
                    type: "number",
                  },
                  started_onboardings: {
                    started_onboardings: "Started Onboardings",
                    type: "number",
                  },
                  submitted_onboardings: {
                    submitted_onboardings: "Submitted Onboardings",
                    type: "number",
                  },
                }}
                sx={{
                  table: {
                    minWidth: "100% !important",
                  },
                }}
              />
            )}
        </Stack>
      </Box>
    </Slide>
  );
};
export default MonthlyTable;

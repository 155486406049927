import { Box, Grid, Slide, Typography } from "@mui/material";
import { useGetMapsQuery } from "api/api-maps";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, MAPS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useRef, useState } from "react";
import styles from "./map.module.scss";
import CLDisplay from "components/CLRequest/CLDisplay";
import { Map } from "api/api-maps.d";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";

const Maps = () => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const searchValue = useAppSelector((state) => state.search.value);
  const dispatch = useAppDispatch();
  const [queryParams, setQueryParams] = useState({
    per_page: 10,
    page: 1,
    search: searchValue || undefined,
  });
  const [filters, setFilters] = useState<Record<string, any>>({});
  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch]);

  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  useEffect(() => {
    if (searchValue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setQueryParams((prev) => ({ ...prev, page: 1, search: searchValue || undefined }));
    }
  }, [searchValue]);

  const {
    data: maps,
    isFetching,
    isLoading,
    refetch,
  } = useGetMapsQuery(
    { 
      mapparams: `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(queryParams)
            .filter(([_, value]) => value !== undefined)
        ) as Record<string, string>
      )}` 
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleRefetch = async () => {
    await refetch();
    setQueryParams({
      per_page: 10,
      page: 1,
      search: undefined
    });
    setFilters({});
    dispatch(clearAll());
  };

  const handleFilterSubmit = (filtersString: string) => {
    const filtersObj = JSON.parse(filtersString);
    setFilters(filtersObj); // Persist filters
    const newFilters: Record<string, string> = {};
    for (const key in filtersObj) {
      if (filtersObj[key]?.keywords) {
        if (key === "created_at" && typeof filtersObj[key].keywords === "string") {
          newFilters[key] = filtersObj[key].keywords;
        } else if (Array.isArray(filtersObj[key].keywords)) {
          newFilters[key] = filtersObj[key].keywords.join(",");
        }
      }
    }
    setQueryParams((prev) => ({ ...prev, ...newFilters, page: 1 }));
  };

  const handlePageChange = (newPage: number, newPerPage: number) => {
    setQueryParams((prev) => ({
      ...prev,
      page: newPage,
      per_page: newPerPage
    }));
  };

  const formatMapsForTable = (data: Map[] = []) => {
    return data.map((item) => ({
      ...item,
      buyer: item.buyer?.name || '',
      seller: item.seller?.name || '',
    }));
  };

  const formattedData = formatMapsForTable(maps?.maps_details);
  const headerLength = Object.keys(formattedData?.[0] ?? {}).length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;

  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex" }} flexGrow={1} ref={ref}>
        <Layout
          title="Supplier Buyer Maps"
          backArrow={false}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.5em" : "1.5em",
          }}
          className={styles.layout}
          headerConfig={{
            syncAction: handleRefetch,
            left: 8,
            mid: 0,
            right: 2,
            xs: { left: 12, mid: 0, right: 0 },
            alignItems: "center",
          }}
          mode="default"
        >
          {(isFetching || isLoading) && <SkeletonLoad bars={10} />}

          {!isFetching && !isLoading && (
            <Grid item xs={12} margin="5px">
              {formattedData && formattedData.length > 0 ? (
                <DataTablev2
                  filterColumnTableName={maps}
                  pagination={maps?.pagination}
                  data={formattedData}
                  defaultColumnWidth={defaultColumnWidth}
                  rowsPerPage={rowsPerPage}
                  onPaginationChange={handlePageChange}
                  useKey="map_id"
                  onFilterSubmit={handleFilterSubmit}
                  initialFilters={{}}
                  filters={filters}
                  setFilters={setFilters}
                  headerSx={{
                    h4: {
                      fontSize: isMobile ? "0.7em!important" : "1em!important",
                      height: "auto",
                    },
                  }}
                  toBeHidden={[
                    "relationship_id",
                    "next_monitoring_date",
                    "score",
                  ]}
                  sx={{ table: { minWidth: "100% !important" } }}
                  customColumns={{
                    map_id: {
                      map_id: "Map ID",
                      minWidth: 120,
                      link: {
                        href: (item: any) =>
                          `${INCOMLEND_INTERNAL_LINK}${MAPS}/${item.map_id}`,
                        target: "_self",
                      },
                    },
                    product: {
                      product: "Product",
                      minWidth: 140,
                      renderCell: ({ value }: { value: string }) => (
                        <Typography>{value}</Typography>
                      ),
                    },
                    buyer: {
                      buyer: "Buyer",
                      minWidth: (width - 180 * 2) / 2.7,
                      type: "text",
                    },
                    seller: {
                      seller: "Supplier",
                      minWidth: (width - 180 * 2) / 2.7,
                      type: "text",
                    },
                    status: {
                      status: "Status",
                      minWidth: 120,
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Grid
                          container
                          justifyContent={isMobile ? "left" : "center"}
                        >
                          <Grid item xs={12}>
                            <CLDisplay
                              status={value ? "Approved" : "Pending"}
                            />
                          </Grid>
                        </Grid>
                      ),
                    },
                  }}
                />
              ) : (
                <NoData text="No Maps found" />
              )}
            </Grid>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};

export default Maps;

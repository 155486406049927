import { Warning } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Chip,
  type ChipProps,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetAccountByNameQuery } from "api/api-accounts";
import { useGetComplianceScoringQuery } from "api/api-compliance";
import {
  useApproveComplianceMapMutation,
  useEditApproverMutation,
  useGetComplianceMapApprovalsQuery,
  useGetMapSummaryQuery,
} from "api/api-maps";
import { useGetApproversQuery, useGetCurrentUserQuery } from "api/api-users-v2";
import { SUPPLIER } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import toProperCase from "helpers/propercase";
import { uniqueObject } from "helpers/unique";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import type { MapSummary } from "types";
import type { ScreeningStatus } from "../types";
import { KEY_PHASE, initialValues } from "./formvalues";

const ComplianceScoringApproval = ({
  accountType,
  screeningStatusData,
}: {
  accountType: string;
  screeningStatusData: ScreeningStatus;
}) => {
  const id = useContext(MapContext);
  const { data: mapData } = useGetMapSummaryQuery(id ?? "", {
    skip: !id,
  });
  const getAccountId = (mapData: MapSummary) => {
    if (accountType?.toLowerCase() === SUPPLIER?.toLowerCase()) {
      return mapData?.seller_internal_name;
    } else {
      return mapData?.buyer_internal_name;
    }
  };
  const accountId = mapData && getAccountId(mapData);
  const {
    data: account,
    isFetching: isFetchingLead,
    isLoading: isLoadingLead,
    refetch: refetchLead,
  } = useGetAccountByNameQuery(accountId ?? "", { skip: !accountId });

  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: complianceApprovals, refetch: refetchApprovals } =
    useGetComplianceMapApprovalsQuery(
      { map_name: id ?? "", account_type: accountType },
      { skip: !id }
    );
  const [approveMap] = useApproveComplianceMapMutation();
  const [editApprover] = useEditApproverMutation();
  const { data: approvers, isFetching } = useGetApproversQuery("COMPLIANCE");
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const members = uniqueObject(
    approvers?.map((item) => ({
      id: item.contact_id,
      approver_name: `${item.first_name} ${item.last_name}`,
      approver_title: item.title,
      approver_status: "Rejected",
      approval_date: undefined,
      comments: "",
    })) ?? [],
    "approver_name"
  );
  const shouldDisableDelegation = (currentDecision?: string): boolean => {
    if (currentDecision === "Approved" || currentDecision === "Rejected")
      return true;
    if (USER_PERMISSIONS.includes(PERMISSIONS.map_access_compliance))
      return false;
    return true;
  };

  const isMapApproved =
    complianceApprovals?.head_compliance_decision?.approval_status ===
    "Approved";
  const isMapRejected =
    complianceApprovals?.head_compliance_decision?.approval_status ===
    "Rejected";

  const shouldDisableButton = (
    key: string,
    assign_contact_id?: string
  ): boolean => {
    const isUserNotAssigned =
      assign_contact_id === undefined || currentUser?.id !== assign_contact_id;
    const isDecisionFinalized = isMapRejected || isMapApproved;
    switch (key) {
      case "onboarding_officer_decision":
        return isUserNotAssigned || isDecisionFinalized;
      case "compliance_officer_decision":
        return isUserNotAssigned || isDecisionFinalized;

      case "head_compliance_decision":
        return !USER_PERMISSIONS.includes(
          PERMISSIONS.approval_level_head_compliance
        );

      default:
        return true;
    }
  };
  const theme = useTheme();
  const isMobile = useIsMobile();
  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  const { data: scoring } = useGetComplianceScoringQuery(
    {
      map_name: id ?? "",
      account_type: accountType as "supplier" | "buyer",
    },
    { skip: !id }
  );

  const items: {
    label: (type?: string) => string;
    chipLabel: (type?: string, extraKey?: string) => ChipProps["label"];
  }[] = [
    {
      label: (type) => `Client Risk : ${type}`,
      chipLabel: (type) => scoring?.["client_risk"]?.["label"] ?? "N/A",
    },
    {
      label: (type) => `Country Risk : ${type}`,
      chipLabel: (type) => scoring?.["country_risk"]?.["label"] ?? "N/A",
    },
    {
      label: (type) => `Product Risk : ${type}`,
      chipLabel: (type) => scoring?.["product_risk"]?.["label"] ?? "N/A",
    },
    {
      label: (type) => `Industry Risk : ${type}`,
      chipLabel: (type) => scoring?.["industry_risk"]?.["label"] ?? "N/A",
    },
    {
      label: (type) => `Triggers Events: ${type ?? "N/A"}`,
      chipLabel: (type) => scoring?.["trigger_risk"]?.["label"] ?? "N/A",
    },
  ];

  // Define the items with their labels and chip colors
  const getColor = (risk: string): ChipProps["color"] => {
    switch (risk) {
      case "Low":
        return "success";
      case "Medium":
        return "warning";
      case "High":
        return "error";
      default:
        return "info";
    }
  };

  const scoringKeys = [
    { type: `${accountType}`, extraKey: undefined },
    { type: `${account?.country}`, extraKey: undefined },
    { type: `${mapData?.product}`, extraKey: undefined },
    { type: `${account?.goods_category}`, extraKey: `${account?.industry}` },
    { type: `None`, extraKey: undefined },
  ];

  const shouldDisableSection = (
    currentKey: string,
    formikValues: any
  ): boolean => {
    const keys = Object.keys(initialValues);
    const currentIndex = keys.indexOf(currentKey);

    if (currentIndex === 0) {
      return false;
    }

    const previousKey = keys[currentIndex - 1];
    const previousSectionApproved =
      formikValues[previousKey]?.approval_status === "Approved";

    return !previousSectionApproved;
  };

  const isScreeningStatusEmpty = Object.keys(screeningStatusData).length === 0;

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues, ...complianceApprovals }}
      onSubmit={() => {}}
    >
      {(formikProps) => (
        <Form>
          <MainCard
            title={`Approval for ${toProperCase(accountType)}`}
            headerSx={{ p: 2 }}
          >
            {isFetching && !complianceApprovals && <SkeletonLoad bars={2} />}
            {!isFetching && complianceApprovals && (
              <Grid
                container
                spacing={1}
                sx={{ height: "218px", overflow: "scroll" }}
              >
                {Object.keys(initialValues).map((key, index) => (
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    key={key}
                    style={{
                      pointerEvents: shouldDisableSection(
                        key,
                        formikProps.values
                      )
                        ? "none"
                        : "auto",
                      opacity: shouldDisableSection(key, formikProps.values)
                        ? 0.5
                        : 1,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Grid container>
                          <Grid item md={6}>
                            <LabelViewOnly label={KEY_PHASE[key]} />
                          </Grid>
                          <Grid item md={6}>
                            {key === "onboarding_officer_decision" ||
                            key === "compliance_officer_decision" ? (
                              <VariableDropdown
                                name={`${key}.approver_name`}
                                keyValue="approver_name"
                                data={members}
                                noHover={
                                  shouldDisableDelegation(
                                    formikProps.values[key]?.approval_status
                                  ) ||
                                  isMapRejected ||
                                  isMapApproved
                                }
                                disabled={
                                  shouldDisableDelegation(
                                    formikProps.values[key]?.approval_status
                                  ) ||
                                  isMapRejected ||
                                  isMapApproved
                                }
                                placeholder="Select Officer"
                                value={formikProps.values[key]?.approver_name}
                                setValue={(value) => {
                                  formikProps.setFieldValue(
                                    `${key}.approver_name`,
                                    value
                                  );
                                  const contact_id = members.find(
                                    (item) => item.approver_name === value
                                  )?.id;
                                  if (contact_id)
                                    formikProps.setFieldValue(
                                      `${key}.contact_id`,
                                      contact_id
                                    );
                                }}
                                handleSave={(value) => {
                                  id &&
                                    value &&
                                    editApprover({
                                      map_name: id,
                                      account_type: accountType,
                                      role: key,
                                      data: {
                                        approver:
                                          formikProps.values[key].contact_id,
                                      },
                                    })
                                      .unwrap()
                                      .then(() =>
                                        toast.success(
                                          `Successfully designated ${value} as an approver`
                                        )
                                      )
                                      .catch((err) =>
                                        toast.error(
                                          `Error designating approver: ${err?.message}`
                                        )
                                      );
                                }}
                              />
                            ) : (
                              <Grid item md={6}>
                                <LabelViewOnly
                                  label={
                                    formikProps.values[key]?.approver_name ??
                                    "--Pending Approver--"
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <VariableInput
                          type="text"
                          name={`${key}.comments`}
                          label="Comments"
                          placeholder="Enter Comment.."
                          noHover={
                            shouldDisableButton(
                              key,
                              formikProps.values[key]?.contact_id
                            ) ||
                            isMapRejected ||
                            isMapApproved
                          }
                          disabled={
                            shouldDisableButton(
                              key,
                              formikProps.values[key]?.contact_id
                            ) ||
                            isMapRejected ||
                            isMapApproved
                          }
                          value={formikProps.values[key]?.comments}
                          multiline
                          fullWidth
                          handleSave={(value) => {
                            id &&
                              !shouldDisableButton(
                                key,
                                formikProps.values[key]?.contact_id
                              ) &&
                              value &&
                              editApprover({
                                map_name: id,
                                account_type: accountType,
                                role: key,
                                data: {
                                  comments: value as string,
                                },
                              })
                                .unwrap()
                                .then(() =>
                                  toast.success(`Successfully put comments`)
                                )
                                .catch(() =>
                                  toast.error("Error putting comments")
                                );
                          }}
                          {...formikProps}
                        />
                      </Grid>
                      {formikProps.values[key]?.approval_date && (
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} md={6}>
                              <LabelViewOnly label="Decision" />
                              <LabelViewOnly
                                label={
                                  formikProps.values[key]?.approval_status ??
                                  "Pending"
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <LabelViewOnly label="Decision Date" />
                              <LabelViewOnly
                                label={moment(
                                  formikProps.values[key]?.approval_date
                                ).format("DD/MM/YYYY")}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {/* Buttons Section */}
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          {/* Reject Button */}
                          <Grid item xs={12} md={6}>
                            <LoadingButton
                              variant="outlined"
                              disabled={
                                shouldDisableButton(
                                  key,
                                  formikProps.values[key]?.contact_id
                                ) ||
                                formikProps.values[key]?.approval_status ===
                                  "Rejected" ||
                                formikProps.values[key]?.approval_status ===
                                  "Approved"
                              }
                              color="error"
                              onClick={() =>
                                id &&
                                approveMap({
                                  map_name: id,
                                  account_type: accountType,
                                  role: key,
                                  data: {
                                    approver:
                                      formikProps.values[key]?.contact_id,
                                    approval_status: "Rejected",
                                    comments: formikProps.values[key]?.comments,
                                  },
                                })
                                  .unwrap()
                                  .then(() => toast.success("Rejected"))
                                  .catch(() => toast.error("Error Rejecting"))
                              }
                            >
                              Reject
                            </LoadingButton>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <LoadingButton
                              variant="contained"
                              color={
                                formikProps.values[key]?.approval_status ===
                                  "Approved" ||
                                formikProps.values[key]?.approval_status ===
                                  "Rejected"
                                  ? "warning"
                                  : "success"
                              }
                              disabled={
                                shouldDisableButton(
                                  key,
                                  formikProps.values[key]?.contact_id
                                ) || !isScreeningStatusEmpty
                              }
                              onClick={() => {
                                key === "head_compliance_decision"
                                  ? setShowApprovalModal(true)
                                  : id &&
                                    approveMap({
                                      map_name: id,
                                      account_type: accountType,
                                      role: key,
                                      data: {
                                        approver:
                                          formikProps.values[key]?.contact_id,
                                        approval_status:
                                          formikProps.values[key]
                                            ?.approval_status === "Approved" ||
                                          formikProps.values[key]
                                            ?.approval_status === "Rejected"
                                            ? "Revoked"
                                            : "Approved",
                                        comments:
                                          formikProps.values[key]?.comments,
                                      },
                                    })
                                      .unwrap()
                                      .then(() =>
                                        toast.success(
                                          formikProps.values[key]
                                            ?.approval_status !== "Revoked"
                                            ? "Revoked"
                                            : "Approved"
                                        )
                                      )
                                      .catch(() =>
                                        toast.error("Error Approving")
                                      );
                              }}
                            >
                              {formikProps.values[key]?.approval_status ===
                                "Approved" ||
                              formikProps.values[key]?.approval_status ===
                                "Rejected"
                                ? "Revoke"
                                : "Approve"}
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Modal
                        Header={
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={1}
                          >
                            <Warning color="warning" />
                            <Typography fontWeight="bold">{`Approve Compliance Review on ${toProperCase(
                              accountType
                            )} Account`}</Typography>
                          </Stack>
                        }
                        message={
                          <Grid item xs={12}>
                            <Stack spacing={2}>
                              <Stack
                                spacing={0.5}
                                direction="row"
                                flexWrap="wrap"
                              >
                                <Typography>
                                  You are about to approve this compliance
                                  review on
                                </Typography>
                                <Typography
                                  fontWeight="bold"
                                  color="error"
                                >{`${id}`}</Typography>
                                <Typography>-</Typography>
                                <Typography
                                  fontWeight="bold"
                                  color="error"
                                >{`${toProperCase(
                                  accountType
                                )} account.`}</Typography>
                                <Typography>
                                  This action is irreversible. An email will be
                                  sent out to all the members of the Credit
                                  Committee, following this action.
                                </Typography>
                                <Typography>Do you want to proceed?</Typography>
                              </Stack>
                              <MainCard content sx={{ maxWidth: "md" }}>
                                <Stack spacing={1}>
                                  {items.map((item, index) => {
                                    const rating = item.chipLabel(
                                      toProperCase(
                                        scoringKeys[index]?.type ?? ""
                                      ),
                                      toProperCase(
                                        scoringKeys[index]?.extraKey ?? ""
                                      )
                                    ) as string;
                                    return (
                                      <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        spacing={1}
                                      >
                                        <LabelViewOnly
                                          label={item.label(
                                            toProperCase(
                                              scoringKeys[index]?.type ?? ""
                                            )
                                          )}
                                        />
                                        <Chip
                                          label={rating}
                                          color={getColor(rating)}
                                          variant="outlined"
                                          sx={{
                                            fontSize: "0.875em",
                                            height: "2.5em",
                                            fontWeight: "bold",
                                          }}
                                        />
                                      </Stack>
                                    );
                                  })}
                                </Stack>
                              </MainCard>
                            </Stack>
                          </Grid>
                        }
                        sx={modalSx}
                        width={isMobile ? "90%" : "40%"}
                        height="auto"
                        modalContentSx={{ height: "auto" }}
                        open={showApprovalModal}
                        modalOverlaySx={modalOverlaySx}
                        modalFooterSx={{
                          maxWidth: "100%",
                          justifyContent: "space-between",
                        }}
                        onClose={() => setShowApprovalModal(false)}
                        closeButton
                        primary={{
                          variant: "contained",
                          fullWidth: true,
                          children: "Confirm",
                          onClick: () => {
                            id &&
                              approveMap({
                                map_name: id,
                                account_type: accountType,
                                role: key,
                                data: {
                                  approver: formikProps.values[key]?.contact_id,
                                  approval_status: isMapApproved
                                    ? "Revoked"
                                    : "Approved",
                                  comments: formikProps.values[key]?.comments,
                                },
                              })
                                .unwrap()
                                .then(() => {
                                  toast.success(
                                    isMapApproved ? "Revoked" : "Approved"
                                  );
                                  setShowApprovalModal(false);
                                })
                                .catch(() =>
                                  toast.error(
                                    isMapApproved
                                      ? "Error Revoking"
                                      : "Error Approving"
                                  )
                                );
                            setShowApprovalModal(false);
                          },
                        }}
                        secondary={{
                          variant: "outlined",
                          fullWidth: true,
                          color: "error",
                          children: `Cancel`,
                          onClick: () => {
                            setShowApprovalModal(false);
                          },
                        }}
                      />
                      <Grid item xs={12} lg={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </MainCard>
        </Form>
      )}
    </Formik>
  );
};
export default ComplianceScoringApproval;

import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import { StatusResponse } from "./api-status.d";

export const statuses = createApi({
  reducerPath: "statuses",
  tagTypes: ["STATUS"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/common-keys`,
  }),
  endpoints: (builder) => ({
    getCommonKeys: builder.query<StatusResponse, void>({
      query: () => ({
        url: "",
        method: "get",
      }),
    }),
  }),
});

export const { useGetCommonKeysQuery } = statuses;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { accountrtk } from "api/api-accounts";
import { aiForm } from "api/api-aiform";
import { auth } from "api/api-auth";
import { banks } from "api/api-banks";
import { comments } from "api/api-comments";
import { companiesrtk } from "api/api-company";
import { compliance } from "api/api-compliance";
import { creditCommittee } from "api/api-credit-committee";
import { emailsrtk } from "api/api-emails";
import { configuration } from "api/api-configuration";
import { finance } from "api/api-finance";
import { guideline } from "api/api-guidelines";
import { human_resources } from "api/api-human-resources";
import { it } from "api/api-it";
import { legal } from "api/api-legal";
import { maps } from "api/api-maps";
import { marketing } from "api/api-marketing";
import { marketplace } from "api/api-marketplace";
import { misc } from "api/api-misc";
import { notifications } from "api/api-notifications";
import { operations } from "api/api-operations";
import { origination } from "api/api-origination";
import { risk } from "api/api-risk";
import { sales } from "api/api-sales";
import { support } from "api/api-support";
import { users } from "api/api-users";
import { users_v2 } from "api/api-users-v2";
import { vendors } from "api/api-vendors";
import { vendorsv2 } from "api/api-vendorsv2";
import { countries } from "api/api-countries"; 
import {statuses} from "api/api-status"
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { unauthenticatedMiddleware } from "./middleware/unauthenticatedMiddleware";
import appStatesReducer from "./reducers/appStatesReducer";
import cargoReducer from "./reducers/cargoReducer";
import companyReducer from "./reducers/companyReducer";
import countriesReducer from "./reducers/countriesReducer"; // Keep existing countries reducer
import creditLimitsReducer from "./reducers/creditLimitsReducer";
import experimentalFeaturesReducer from "./reducers/experimentalFeaturesReducer";
import sofrRatesReducer from "./reducers/sofrReducer";
import stepsReducer from "./reducers/stepsReducer";
import searchReducer from './reducers/searchReducer';

// Combine all reducers
const rootReducer = combineReducers({
  // Existing reducers
  companies: companyReducer,
  creditLimits: creditLimitsReducer,
  countries: countriesReducer, // Existing countries reducer (keep this)
  steps: stepsReducer,
  appState: appStatesReducer,
  sofrRates: sofrRatesReducer,
  cargo: cargoReducer,
  experimentalFeatures: experimentalFeaturesReducer,
  search: searchReducer,
  
  // RTK Query API reducers
  [countries.reducerPath]: countries.reducer, 
  [statuses.reducerPath]: statuses.reducer, 
  [aiForm.reducerPath]: aiForm.reducer,
  [sales.reducerPath]: sales.reducer,
  [misc.reducerPath]: misc.reducer,
  [users.reducerPath]: users.reducer,
  [users_v2.reducerPath]: users_v2.reducer,
  [companiesrtk.reducerPath]: companiesrtk.reducer,
  [accountrtk.reducerPath]: accountrtk.reducer,
  [banks.reducerPath]: banks.reducer,
  [vendors.reducerPath]: vendors.reducer,
  [origination.reducerPath]: origination.reducer,
  [vendorsv2.reducerPath]: vendorsv2.reducer,
  [marketplace.reducerPath]: marketplace.reducer,
  [creditCommittee.reducerPath]: creditCommittee.reducer,
  [it.reducerPath]: it.reducer,
  [finance.reducerPath]: finance.reducer,
  [marketing.reducerPath]: marketing.reducer,
  [legal.reducerPath]: legal.reducer,
  [risk.reducerPath]: risk.reducer,
  [operations.reducerPath]: operations.reducer,
  [support.reducerPath]: support.reducer,
  [auth.reducerPath]: auth.reducer,
  [maps.reducerPath]: maps.reducer,
  [compliance.reducerPath]: compliance.reducer,
  [emailsrtk.reducerPath]: emailsrtk.reducer,
  [comments.reducerPath]: comments.reducer,
  [human_resources.reducerPath]: human_resources.reducer,
  [notifications.reducerPath]: notifications.reducer,
  [configuration.reducerPath]: configuration.reducer,
  [guideline.reducerPath]: guideline.reducer,
});

// Persist configuration with blacklist for API slices
const persistedReducer = persistReducer({
  key: "root",
  storage,
  // Remove apiSlices from redux-persist
  // This should make refreshing pages refetch APIs
  // and stop redux persist from caching pending APIs during refresh
  blacklist: [
    countries.reducerPath, 
    statuses.reducerPath, 
    aiForm.reducerPath,
    sales.reducerPath,
    users.reducerPath,
    users_v2.reducerPath,
    companiesrtk.reducerPath,
    accountrtk.reducerPath,
    banks.reducerPath,
    vendors.reducerPath,
    origination.reducerPath,
    vendorsv2.reducerPath,
    marketplace.reducerPath,
    creditCommittee.reducerPath,
    it.reducerPath,
    finance.reducerPath,
    marketing.reducerPath,
    legal.reducerPath,
    risk.reducerPath,
    operations.reducerPath,
    support.reducerPath,
    auth.reducerPath,
    maps.reducerPath,
    compliance.reducerPath,
    emailsrtk.reducerPath,
    comments.reducerPath,
    human_resources.reducerPath,
    notifications.reducerPath,
    // no need to refetch the following on every refresh
    // misc.reducerPath,
    // configuration.reducerPath,
    // guideline.reducerPath,
  ],
}, rootReducer);

// Configure store with middleware
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      actionCreatorCheck: false,
    }).concat([
      unauthenticatedMiddleware,
      countries.middleware,
      statuses.middleware, 
      aiForm.middleware,
      sales.middleware,
      origination.middleware,
      misc.middleware,
      users.middleware,
      users_v2.middleware,
      companiesrtk.middleware,
      accountrtk.middleware,
      configuration.middleware,
      banks.middleware,
      notifications.middleware,
      vendors.middleware,
      vendorsv2.middleware,
      marketplace.middleware,
      creditCommittee.middleware,
      it.middleware,
      finance.middleware,
      marketing.middleware,
      legal.middleware,
      risk.middleware,
      operations.middleware,
      support.middleware,
      auth.middleware,
      maps.middleware,
      compliance.middleware,
      emailsrtk.middleware,
      comments.middleware,
      human_resources.middleware,
      guideline.middleware,
    ]),
  devTools: import.meta.env.VITE_ENV !== "main",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);

import React, { useEffect, useRef, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useGetInternalUsersQuery } from "api/api-it";
import { Grid, Typography, useTheme } from "@mui/material";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, IT_LINK } from "codes";
import { Cancel, CheckCircle } from "@mui/icons-material";
import useWidth from "helpers/useWidth";
import history from "helpers/history";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import ShowUserGroups from "./ShowUserGroups";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearAll } from "redux/reducers/searchReducer";
import DataTablev2 from "components/Common/DataTablev2";
import NoData from "components/Common/NoData";

interface IUserIT {
  user_id: string;
  contact_id: string;
  first_name: string;
  last_name: string;
  username: string;
  last_login: Date;
  contact_created_at: string;
  is_active: boolean;
  departments: string[];
  groups: string[];
}

export default function AllUsers() {
  const [userType, setUserType] = React.useState("Internal");
  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const handleChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value);
  };
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const searchvalue = useAppSelector((state) => state.search.value);
  const usermanagparams = `${userType}?per_page=${perPage}&page=${page}${searchvalue ? `&search=${searchvalue}` : ""}`;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch, userType]);
  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    if (searchvalue && !hasSearchedOnce.current) {
      hasSearchedOnce.current = true;
    }
    if (hasSearchedOnce.current) {
      setPage(1);
    }
  }, [searchvalue, userType]);
  const {
    data: internalUsers,
    refetch,
    isLoading,
    isFetching,
  } = useGetInternalUsersQuery({ usermanagparams });
  const theme = useTheme();

  const initialFilters = {
    is_active: {
      comparator: "is",
      keywords: ["True"],
    },
  };
  const handleRefetch = async () => {
    await refetch();
    setPage(1);
    setPerPage(10);
    dispatch(clearAll());
  };
  const formatUserForTable = (data: IUserIT[] = []) => {
    return data?.map((item) => ({
      first_name: item.first_name,
      last_name: item.last_name,
      username: item.username,
      last_login: item.last_login,
      departments: item.departments,
      groups: item.groups,
      id: item.user_id,
      is_active: item.is_active ? "True" : "False",
      contact_id: item.contact_id,
    }));
  };
  const handleFilterSubmit = (filters: string) => {
    const filtersObj = JSON.parse(filters);
    const newFilters: Record<string, string> = {};

    for (const key in filtersObj) {
      if (filtersObj[key]?.keywords) {
        if (
          key === "created_at" &&
          typeof filtersObj[key].keywords === "string"
        ) {
          newFilters[key] = filtersObj[key].keywords;
        } else if (Array.isArray(filtersObj[key].keywords)) {
          newFilters[key] = filtersObj[key].keywords.join(",");
        }
      }
    }
  };

  const handlePageChange = (newPage: number, newPerPage: number) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };
  const formattedData = formatUserForTable(internalUsers?.user_type_details);
  const headerLength = Object.keys(formattedData?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
        <InputLabel id="demo-select-small-label"> User</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={userType}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value="Internal">Internal</MenuItem>
          <MenuItem value="Client">Client</MenuItem>
          <MenuItem value="Sales_Partner">Sales Partner</MenuItem>
          <MenuItem value="Investor">Investor</MenuItem>
        </Select>
      </FormControl>
      <Grid item xs={12} ref={ref}>
        {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
        {!isFetching && !isLoading && (
          <Grid item xs={12} margin="5px">
            {formattedData && formattedData.length > 0 ? (
              <DataTablev2
                filterColumnTableName={internalUsers}
                pagination={internalUsers?.pagination}
                onFilterSubmit={handleFilterSubmit}
                data={formattedData}
                initialFilters={initialFilters}
                onPaginationChange={handlePageChange}
                defaultColumnWidth={defaultColumnWidth}
                rowsPerPage={rowsPerPage}
                customColumns={{
                  first_name: {
                    first_name: "First name",
                    minWidth: 160,
                    type: "text",
                  },
                  last_name: { last_name: "Last name", type: "text" },
                  username: {
                    username: "Username",
                    minWidth: 330,
                    type: "text",
                  },
                  groups: {
                    groups: "User Groups",
                    minWidth: 220,
                    type: "text",
                    renderCell: ({ value }: { value: string[] }) => {
                      if (value?.length === 1)
                        return (
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1em",
                              p: 1,
                              borderRadius: 1,
                              border: `1px solid ${theme.palette.primary.main}`,
                            }}
                            key={`${0}-u-group`}
                          >
                            {value[0]}
                          </Typography>
                        );
                      return (
                        <ShowUserGroups
                          items={value}
                          title="Multiple Groups"
                          aria="group"
                        />
                      );
                    },
                  },
                  departments: {
                    departments: "Departments",
                    minWidth: 250,
                    type: "text",
                    renderCell: ({ value }: { value: string[] }) => {
                      if (value?.length === 1)
                        return (
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1em",
                              p: 1,
                              borderRadius: 1,
                              border: `1px solid ${theme.palette.primary.main}`,
                            }}
                            key={`${0}-u-department`}
                          >
                            {value[0]}
                          </Typography>
                        );
                      return (
                        <ShowUserGroups
                          items={value}
                          title="Multiple Departments"
                          aria="department"
                        />
                      );
                    },
                  },
                  mobile: { mobile: "Phone", type: "text" },
                  title: {
                    title: "Job position",
                    type: "text",
                  },

                  opted_marketing_updates: {
                    opted_marketing_updates: "Marketing consent",
                    type: "boolean",
                    renderCell: (params: any) => {
                      const Icon = params.value ? CheckCircle : Cancel;
                      const iconColor = params.value ? "green" : "red";

                      return (
                        <Grid container justifyContent="center">
                          <Grid item>
                            <Icon
                              style={{
                                textAlign: "center",
                                color: iconColor,
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    },
                  },
                  sign_up: {
                    sign_up: "Sign up",
                    type: "date",
                    renderCell: ({ value }: { value: string }) => (
                      <Typography
                        sx={{
                          fontSize: "1em!important",
                          width: "10vw",
                        }}
                      >
                        {value}
                      </Typography>
                    ),
                  },
                  last_login: {
                    last_login: "Last login",
                    type: "date",
                    minWidth: 300,
                  },
                  company_id: {
                    company_id: "Company name",
                    type: "text",
                  },
                  reg_no: { reg_no: "Registration Number", type: "text" },
                  is_active: { is_active: "Active" },
                }}
                toBeHidden={["contact_id"]}
                onRowClicked={(field, columnName) => {
                  if (columnName !== "groups" && columnName !== "departments")
                    history.push(
                      `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/user-management/${field?.id}/manage`
                    );
                }}
                sx={{
                  table: {
                    minWidth: "100% !important",
                  },
                }}
              />
            ) : (
              <NoData text="No User found" />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

import { Grid } from "@mui/material";
import {
  useGetAccountDocumentsQuery,
  useGetDocumentContentQuery,
  useGetDocumentUrlQuery,
} from "api/api-accounts";
import Modal from "components/Common/Modal";

import DocView from "components/Common/DocView";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import { getFileTypeFromMagicNumber } from "helpers/getFileTypeFromMagicNumber";
import { Fragment, useState } from "react";
import type { RequiredDoc } from "types";

import DocumentUploadController from "components/Documents/DocumentUploadController";
import styles from "./upldocuments.module.scss";
import { skipToken } from "@reduxjs/toolkit/query";
interface DocList {
  docName: string;
  id: string;
  description: string;
  items: RequiredDoc[];
  count_unique_uploaded?: number;
  template?: string;
  yearly_set_count?: number;
}
const InternalDocumentList = ({
  initialValues,
  docs,
  entity_id,
  optional = false,
}: {
  initialValues: Record<string, any>;
  docs: RequiredDoc[];
  entity_id: string;
  optional?: boolean;
}) => {
  const [document_id, setId] = useState<string>();

  const { data: accountDocs, isFetching: isFetchingAccountDocs } =
    useGetAccountDocumentsQuery(entity_id ?? "", {
      skip: !Boolean(entity_id),
    });

  // const { data: documentUrl, refetch: refetchDocUrl } = useGetDocumentUrlQuery(
  //   {
  //     account_id: entity_id,
  //     document_id: document_id ?? "",
  //   },
  //   { skip: !Boolean(document_id) }
  // );

  // const { data: documentContent } = useGetDocumentContentQuery(
  //   {
  //     account_id: entity_id,
  //     document_id: document_id ?? "",
  //   },
  //   { skip: !Boolean(document_id) }
  // );

  const { data: documentUrl, refetch: refetchDocUrl } = useGetDocumentUrlQuery(
    document_id ? { account_id: entity_id, document_id } : skipToken
  );
  
  const { data: documentContent } = useGetDocumentContentQuery(
    document_id ? { account_id: entity_id, document_id } : skipToken
  );

  //DUC start
  const docDictionary: any =
    docs?.map((doc) => ({
      ...doc,
      name: toCamelCase(doc.display_name),
      label: doc.display_name,
      value: doc.display_name,
      items: doc.items.map((sub) => ({
        ...sub,
        name: toCamelCase(sub.display_name),
        label: sub.display_name,
        value: sub.display_name,
      })),
    })) ?? [];

  function base64ToBlob(base64String: string, mimeType: string) {
    const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, "");
    const decodedData = atob(base64WithoutPrefix);

    const arrayBuffer = new ArrayBuffer(decodedData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < decodedData.length; i += 1) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeType });
  }
  const downloadDocument = () => {
    const binaryData = documentContent ?? "";
    const filetype = getFileTypeFromMagicNumber(binaryData);
    const blob = base64ToBlob(binaryData, filetype ?? "");
    const extension = (): string => {
      if (filetype === "image/jpeg") {
        return "jpg";
      } else if (filetype === "image/png") {
        return "png";
      } else if (filetype === "application/pdf") {
        return "pdf";
      }
      return "jpg";
    };
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `download.${extension()}`);
    document.body.appendChild(link);
    link.click();
  };

  const [show, setShow] = useState<boolean>(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  const fetchDocument = (id: string) => {
    setId(id);
    refetchDocUrl();
    onOpen();
  };

  //DUC end

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={() => {}}
      >
        {(muFormikProps) => {
          return (
            <Fragment>
              {isFetchingAccountDocs && !accountDocs && (
                <SkeletonLoad bars={1} />
              )}
              {/* All mandatory documents are listed, regardless of if they have been provided or not.
              The point is to highlight what would be missing. */}
              {!isFetchingAccountDocs && accountDocs && (
                <DocumentUploadController
                  data={docDictionary}
                  formikProps={muFormikProps}
                  initialData={accountDocs}
                  onUpload={() => {}}
                  onView={(fileName, id, idx) => fetchDocument(id)}
                  enableCount={false}
                  uploadDisabled
                  deleteDisabled
                  allowTint={!optional}
                  //Only provided optional documents are displayed here.
                  disableRenderIfEmpty={optional}
                />
              )}
              <Modal
                modalOverlaySx={{ borderRadius: "2ch"}}
                width={documentUrl?.url?.includes(".pdf?") ? "60%" : "30%"}
                height={documentUrl?.url?.includes(".pdf?") ? "80vh" : "auto"}
                closeButton
                message={
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                      {documentUrl?.url?.includes(".pdf?") ? (
                        <Grid
                          item
                          xs={12}
                          id="modal-docview"
                          style={{ height: "100%" }}
                        >
                          <DocView
                            uri={documentUrl?.url}
                            staticView
                            pageClassName={styles.pageClass}
                          />
                          
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <img
                            src={documentUrl?.url}
                            alt={documentUrl?.url}
                            width="100%"
                            height="auto"
                            style={{
                              borderRadius: "2ch",
                              margin: "auto",
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                }
                open={show}
                primary={{
                  children: "Download",
                  onClick: () => documentUrl?.url && downloadDocument(),
                }}
                onClose={onClose}
              />
            </Fragment>
          );
        }}
      </Formik>
    </Grid>
  );
};
export default InternalDocumentList;

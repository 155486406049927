import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { MapCurrentVersion } from "pages/maps/map";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import { currencyFormatter, percentageFormatter } from "helpers/currencyFormatter";

interface BuyerFinancialTableProps {
  buyerFinancialData?: Record<string, Record<string, string | number>>;
  error?: boolean;
}

const BuyerFinancialTable: React.FC<BuyerFinancialTableProps> = ({
  buyerFinancialData = {},
  error,
}) => {
  if (error) {
    return (
      <Typography variant="body1" color="error" sx={{ mt: 2 }}>
        No IPL product data available.
      </Typography>
    );
  }

  const theme = useTheme();
  const currentMapVersion = useContext(MapCurrentVersion);

  if (!buyerFinancialData || Object.keys(buyerFinancialData).length === 0) {
    return <Typography variant="body1">No Data Available</Typography>;
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const years = buyerFinancialData
    ? Object.keys(buyerFinancialData).sort((a, b) => b.localeCompare(a))
    : [];

  const formattedYears = years?.map((year) => {
    const [month, yearPart] = year?.split("/"); // Extract month and year
    const formattedMonth = monthNames[parseInt(month, 10) - 1]; // Convert to month name
    return `${formattedMonth}-${yearPart?.split("-")[0]}`; // Format as 'Feb-2024'
  });

  const allMetrics = new Set<string>();

  // Collect all unique financial metrics
  years?.forEach((year) => {
    Object.keys(buyerFinancialData[year]).forEach((metric) => {
      allMetrics.add(metric);
    });
  });

  const metricsArray = Array.from(allMetrics); // Convert to array for iteration

  const calculateGrowthPercentage = (
    currentYearData: number,
    previousYearData: number
  ): number => {
    if (previousYearData === 0) return 0; // Avoid division by zero
    const growth = ((currentYearData - previousYearData) / previousYearData) * 100;
    return Number.isNaN(growth) ? 0 : growth; // Ensure NaN is replaced with 0
  };  

  const getGrowthIndication = (
    growth: number
  ): [string, string, number] => {
    switch (true) {
      case growth > 0:
        return [`▲`, theme.palette.success.main, growth];
      case growth === 0:
        return [``, theme.palette.info.main, growth];
      case growth < 0:
        return [`▼`, theme.palette.error.main, growth];
      default:
        return [``, theme.palette.info.main, growth];
    }
  };

  // Function to format value based on metric type
  const formatValue = (metricKey: string, value: number | string): string => {
    // Ensure NaN and other invalid values are replaced with 0
    if (
      value === "N/A" ||
      value === null ||
      value === undefined ||
      value === "NaN" ||
      value === "NaN%" ||
      Number.isNaN(Number(value))
    ) {
      return "0"; // Replace invalid values with "0"
    }
  
    const isMarginKey = metricKey.toLowerCase().includes("margin"); // Check if the key contains "margin"
    if (isMarginKey) {
      return percentageFormatter(Number(value)); // Format as percentage
    } else {
      return currencyFormatter({ amount: Number(value) }); // Format as currency
    }
  };  

  return (
    <TableContainer component={Paper} sx={{ mt: 2, overflowX: "auto" }}>
      <Table size="small" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "auto", whiteSpace: "nowrap" }}>
              <b>In USD</b>
            </TableCell>
            {formattedYears.map((formattedYear, index) => (
              <TableCell
                key={years[index]}
                align="right"
                sx={{ width: "auto", whiteSpace: "nowrap" }}
              >
                <b>{formattedYear}</b>
              </TableCell>
            ))}
            <TableCell
              align="right"
              sx={{ width: "auto", whiteSpace: "nowrap" }}
            >
              <b>Growth (%)</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metricsArray.map((metric) => {
            const growthDisplay = (() => {
              if (years.length > 1) {
                const currentYearData = Number(buyerFinancialData[years[0]]?.[metric] ?? 0);
                const previousYearData = Number(buyerFinancialData[years[1]]?.[metric] ?? 0);
                let growth = calculateGrowthPercentage(currentYearData, previousYearData);
            
                // Ensure growth is always a valid number (No NaN)
                if (Number.isNaN(growth)) {
                  growth = 0;
                }
            
                const [symbol, color, growthValue] = getGrowthIndication(growth);
            
                return (
                  <TableCell
                    key={`growth-${metric}`}
                    align="right"
                    sx={{ width: "auto", whiteSpace: "nowrap" }}
                  >
                    <LabelViewOnly
                      label={`${symbol} ${growthValue.toFixed(2)}%`}
                      sx={{
                        color,
                        width: "100%",
                        textAlign: "right",
                        display: "block",
                      }}
                    />
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={`growth-${metric}`}
                    align="right"
                    sx={{ width: "auto", whiteSpace: "nowrap" }}
                  >
                    <LabelViewOnly
                      label={`0.00%`}
                      sx={{
                        color: theme.palette.info.main,
                        width: "100%",
                        textAlign: "right",
                        display: "block",
                      }}
                    />
                  </TableCell>
                );
              }
            })();            

            return (
              <TableRow key={metric}>
                <TableCell sx={{ width: "auto", whiteSpace: "nowrap" }}>
                  {metric}
                </TableCell>
                {years.map((year) => (
                  <TableCell
                    key={year}
                    align="right"
                    sx={{ width: "auto", whiteSpace: "nowrap" }}
                  >
                    {formatValue(metric, buyerFinancialData[year]?.[metric] ?? "0")}
                  </TableCell>
                ))}
                {growthDisplay}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BuyerFinancialTable;
